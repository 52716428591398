import { useEffect, useState, type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import QRCode from '../QRCode/QRCode'

interface QRCodeModalProps {
  closeModal: () => void
  isParentClosing: boolean
  code: string
}

export default function QRCodeModal({
  closeModal,
  isParentClosing,
  code,
}: QRCodeModalProps): ReactElement {
  const [renderOverlay, setRenderOverlay] = useState(true)
  const [renderModal, setRenderModal] = useState(true)

  function handleModalClose(): void {
    document.body.style.overflow = ''
    setRenderModal(false)
    setRenderOverlay(false)
    if (closeModal !== undefined) setTimeout(closeModal, 200)
  }

  useEffect(() => {
    document.body.style.overflow = ''
    if (isParentClosing) handleModalClose()
  }, [isParentClosing])

  return (
    <AnimatePresence>
      {renderOverlay && (
        <motion.div
          key="overlay"
          className="fixed inset-0 z-40 h-max min-h-full w-full backdrop-blur-sm backdrop-brightness-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          onClick={() => {
            handleModalClose()
          }}
        />
      )}

      {renderModal && (
        <motion.div
          key="modal"
          className="fixed left-0 top-0 z-50 flex size-full min-h-screen items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          onClick={() => {
            handleModalClose()
          }}
        >
          <div className="flex h-fit w-[90%] flex-col items-center justify-center">
            <QRCode value={code} variant="light" />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
