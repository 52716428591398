import { type ReactElement, useEffect, useState } from 'react'

import Modal from '@/components/Global/Modal/Modal'
import TicketSummary from '../../Global/TicketSummary/TicketSummary'
import List from '@/components/Global/List/List'
import EventThumbnail from '@/components/Event/EventThumbnail/EventThumbnail'

import sellStore from '@/store/sell'
import transferStore from '@/store/transfer'

import {
  type IWalletTicketGroup,
  type IWalletTickets,
} from '@/types/hooks/api/useWallet'

import { useAuthNavigate } from '@/hooks/useAuthNavigate'

import { numberToReais } from '@/helpers/formatNumber'
import { datesToFormat } from '@/helpers/formatDate'
import { isTruthy } from '@/helpers/validation'

import { NO_TICKETS_TO_SELL } from '@/errors'

interface EventTicketsModalProps {
  ticketGroup: IWalletTicketGroup
  closeModal: () => void
  handleOpenOrder: (orderCode: string) => void
}

function EventTicketsModal({
  ticketGroup,
  closeModal,
  handleOpenOrder,
}: EventTicketsModalProps): ReactElement {
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { checkAuthNavigate } = useAuthNavigate()

  const { alias, imageKey, title, startDate, location, endDate } = ticketGroup
  const { setTransferTicketsEvent, setTransferTickets } = transferStore()
  const { setSellTicketsEvent, setSellTickets } = sellStore()

  // Show tickets that are announced first on list
  const orderedTickets = [...ticketGroup.tickets].sort(
    (a: IWalletTickets, b: IWalletTickets) => {
      if (a.orderCode !== '' && b.orderCode === '') return -1
      if (a.orderCode === '' && b.orderCode !== '') return 1
      return 0
    },
  )

  useEffect(() => {
    orderedTickets.filter((ticket) => ticket.orderCode !== '')
  }, [])

  const canSell =
    orderedTickets.some(
      (ticket) => ticket.orderCode === '' && !ticket.validated,
    ) && orderedTickets.some((ticket) => !ticket.validated)

  const canTransfer =
    orderedTickets.some((ticket) => ticket.isTransferable) &&
    orderedTickets
      .filter((ticket) => ticket.isTransferable)
      .some((ticket) => !ticket.validated)

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      showSnackBar={isError}
      snackBarMessage={errorMessage}
    >
      <div className="flex size-full flex-col gap-4 p-4">
        <div className="mb-4 flex h-2 w-full justify-center">
          <div className="h-full w-1/3 rounded-full bg-[#D9D9D9]" />
        </div>
        <EventThumbnail
          imageKey={imageKey}
          title={title}
          date={datesToFormat(startDate, endDate)}
          location={location}
          highlighted={false}
          imageVariant="large"
          briefVariant="dark"
          eventAlias={alias}
        />
        <List hasFade fromFade="from-white" toFade="to-white">
          {orderedTickets.map((ticket, index) => {
            const title = isTruthy(ticket.batchDescription)
              ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
              : ticket.ticketSpecDescription
            return (
              <TicketSummary
                key={index}
                title={title}
                quantity={1}
                subtitle={numberToReais(ticket.price, 2)}
                orderCode={ticket.orderCode ?? undefined}
                variant="dark"
                counterVariant="dark"
                onOpenAnnouncement={() => {
                  setIsParentClosing(true)
                  setTimeout(handleOpenOrder, 400, ticket.orderCode)
                }}
                validated={ticket.validated}
              />
            )
          })}
        </List>

        <div className="flex w-full flex-col gap-2">
          {canTransfer && (
            <button
              className="h-12 w-full rounded-full bg-dark-light-gray font-bold text-text-dark"
              onClick={() => {
                // if (!canTransfer) {
                //   setIsError(true)
                //   setErrorMessage(NO_TICKETS_TO_TRANSFER)
                //   setTimeout(setIsError, 3000, false)
                //   return
                // }
                setTransferTicketsEvent(ticketGroup)
                setTransferTickets(
                  ticketGroup.tickets.map((ticket) => ({
                    ...ticket,
                    isSelected: false,
                  })),
                )
                checkAuthNavigate('transfer')
              }}
            >
              Transferir
            </button>
          )}
          <button
            className="h-12 w-full rounded-full bg-dark-light-gray font-bold text-text-dark"
            onClick={() => {
              if (!canSell) {
                setIsError(true)
                setErrorMessage(NO_TICKETS_TO_SELL)
                setTimeout(setIsError, 3000, false)
                return
              }
              setSellTicketsEvent(ticketGroup)
              setSellTickets(
                ticketGroup.tickets.map((ticket) => ({
                  ...ticket,
                  isSelected: false,
                })),
              )
              checkAuthNavigate('sell')
            }}
          >
            Criar link para venda
          </button>
          <button
            className="h-12 w-full rounded-full bg-dark-light-gray font-bold text-text-dark"
            onClick={() => {
              checkAuthNavigate(`payments/${alias}`)
            }}
          >
            Cancelar compra
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default EventTicketsModal
