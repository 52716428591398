import { type ReactElement } from 'react'
import { Link } from 'react-router-dom'

import Button from '@/components/Global/Button/Button'

import notificationStore from '@/store/notification'

import { type INotification } from '@/types/hooks/api/useNotifications'

export function AcceptedFollowRequestNotification({
  notification,
  hideNotification,
}: {
  notification: INotification
  hideNotification: (id: number) => void
}): ReactElement {
  const { setOnUnfollow, setShowUnfollowConfirmationModal, setUser } =
    notificationStore()

  function handleFollowRequestAction(): void {
    setOnUnfollow(() => {
      hideNotification(notification.payload.id)
    })
    setUser({
      id: notification.payload.userId,
      username: notification.payload.username,
    })
    setShowUnfollowConfirmationModal(true)
  }

  return (
    <div className="flex w-full items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        <Link
          className="w-full max-w-12 rounded-full"
          to={`/memories/${notification.payload.username}`}
        >
          <img
            src={`${import.meta.env.VITE_S3 as string}/${
              notification.payload.imageKey
            }`}
            alt="Foto de perfil"
            className="block size-12 rounded-full"
          />
        </Link>
        <div className="flex flex-col gap-1.5">
          <p className="text-sm leading-tight text-white">
            Agora você está seguindo{' '}
            <Link
              to={`/memories/${notification.payload.username}`}
              className="font-bold"
            >
              @{notification.payload.username}
            </Link>
          </p>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Button
          enabled
          onClick={handleFollowRequestAction}
          className="h-min w-[106px] rounded-md bg-[#a7a7a7] px-4 py-1.5 text-xs font-bold leading-3 text-dark-black"
          customSpinnerHeight="w-[18px]"
          customSpinnerWidth="h-[18px]"
          text="Seguindo"
        />
      </div>
    </div>
  )
}
