import { type ReactElement } from 'react'

interface CarouselProps {
  title: string
  childrens: ReactElement[]
}

function Carousel({ title, childrens }: CarouselProps): ReactElement {
  return (
    <div>
      <h1 className="pl-4 text-2xl text-white">{title}</h1>
      <div className="scrollbar-hide mt-2 w-full overflow-x-scroll scroll-smooth">
        <div className="flex w-max flex-row gap-4 px-4">{childrens}</div>
      </div>
    </div>
  )
}

export default Carousel
