import { useState, type ReactElement, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import LoginSignupModal from '@/components/LoginSignup/LoginSignupModal/LoginSignupModal'

import NavBar from '../components/NavBar/NavBar'

import userStore from '@/store/user'
import routingStore from '@/store/routing'

function Layout(): ReactElement {
  const { userIsLogged } = userStore()
  const { showLogin, setShowLogin } = routingStore()

  const [showLoginModal, setShowLoginModal] = useState(showLogin)
  const [loginModalIsClosing, setLoginModalIsClosing] = useState(false)

  useEffect(() => {
    if (showLogin) {
      setShowLoginModal(true)
    } else {
      if (userIsLogged) setLoginModalIsClosing(true)
      // setTimeout(setShowLoginModal, 200, false)
    }
  }, [showLogin, userIsLogged])

  return (
    <>
      <NavBar />
      <Outlet />
      {showLoginModal && (
        <LoginSignupModal
          closeModal={() => {
            setLoginModalIsClosing(true)
            setTimeout(setShowLogin, 400, false)
            setTimeout(setShowLoginModal, 400, false)
            setTimeout(setLoginModalIsClosing, 400, false)
          }}
          isParentClosing={loginModalIsClosing}
        />
      )}
    </>
  )
}

export default Layout
