import { type ReactElement, useState } from 'react'

import { twMerge } from 'tailwind-merge'

import TicketSummary from '@/components/Global/TicketSummary/TicketSummary'
import EventThumbnail from '@/components/Event/EventThumbnail/EventThumbnail'
import IntransferableTicketModal from '../IntransferableTicketModal/IntransferableTicketModal'

import transferStore from '@/store/transfer'

import { BsX } from 'react-icons/bs'
import Button from '@/components/Global/Button/Button'
import { numberToReais } from '@/helpers/formatNumber'
import { datesToFormat } from '@/helpers/formatDate'

import { isTruthy } from '@/helpers/validation'

import { HiOutlineInformationCircle } from 'react-icons/hi2'

function SelectTransferTickets(): ReactElement {
  const {
    transferTickets,
    transferTicketsEvent,
    receiver,
    switchTicketSelectState,
    resetReceiver,
    setTransferState,
  } = transferStore()
  const { imageKey, title, startDate, location, endDate } = transferTicketsEvent

  const [showIntransferableTicketModal, setShowIntransferableTicketModal] =
    useState(false)

  return (
    <>
      <div className="flex items-center gap-4 rounded-lg bg-dark-black p-3">
        <img
          src={`${import.meta.env.VITE_S3 as string}/${receiver?.imageKey}`}
          alt="Foto perfil"
          className="size-12 rounded-full"
        />
        <div className="flex grow flex-col">
          <span className="font-bold leading-4 text-white">
            {receiver?.firstName} {receiver?.lastName}
          </span>
          <span className="text-sm text-white">@{receiver?.username}</span>
        </div>
        <BsX
          size={32}
          color="white"
          onClick={() => {
            resetReceiver()
            setTransferState('selectUser')
          }}
          className="hover:cursor-pointer"
        />
      </div>
      <div className="mt-6">
        <EventThumbnail
          imageKey={imageKey}
          title={title}
          date={datesToFormat(startDate, endDate)}
          location={location}
          briefVariant="white"
          highlighted={false}
          imageVariant="medium"
        />
      </div>
      <h1 className="my-4 text-white">Quais ingressos vai transferir?</h1>
      <div className="flex flex-col gap-2">
        {transferTickets?.map((ticket, index) => {
          const title = isTruthy(ticket.batchDescription)
            ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
            : ticket.ticketSpecDescription
          return (
            <div key={index} className="flex items-center justify-between">
              <div
                className={
                  isTruthy(ticket.orderCode)
                    ? 'w-[calc(100%-140px)]'
                    : 'w-[calc(100%-40px)]'
                }
              >
                <TicketSummary
                  quantity={1}
                  title={title}
                  subtitle={'Valor: ' + numberToReais(ticket.price, 2)}
                  variant="mix"
                  counterVariant="dark"
                />
              </div>
              <div className="flex items-center gap-2">
                {isTruthy(ticket.orderCode) && (
                  <div className="h-fit rounded-md bg-primary-main px-2 py-0.5">
                    <span className="line-clamp-none w-max text-2xs font-black">
                      ANUNCIADO
                    </span>
                  </div>
                )}
                {ticket.validated ? (
                  <div className="flex items-center justify-center gap-4 rounded-lg border border-background-main bg-white px-2 py-0.5">
                    <span className="line-clamp-none w-max text-2xs font-black">
                      VALIDADO
                    </span>
                  </div>
                ) : !ticket.isTransferable ? (
                  <div
                    className="flex items-center justify-center gap-1 rounded-lg border border-background-main bg-white px-2 py-1 hover:cursor-pointer"
                    onClick={() => {
                      setShowIntransferableTicketModal(true)
                    }}
                  >
                    <span className="line-clamp-none w-max text-xs font-bold">
                      Intransferível
                    </span>
                    <HiOutlineInformationCircle />
                  </div>
                ) : (
                  <div
                    className="transition-color flex size-6 items-center justify-center  rounded-lg border-2 border-dark-light-gray duration-100 ease-in-out hover:cursor-pointer"
                    onClick={() => {
                      switchTicketSelectState(ticket.id)
                    }}
                  >
                    <div
                      className={twMerge(
                        'size-4 rounded-[4px] bg-red-200',
                        ticket.isSelected
                          ? 'bg-primary-main'
                          : 'bg-transparent',
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
      <div className="fixed bottom-0 left-0 w-full bg-dark-black p-4">
        <div className="h-12 w-full">
          <Button
            text={
              'Transferir ingresso' +
              (transferTickets.filter((ticket) => ticket.isSelected).length > 1
                ? 's'
                : '')
            }
            enabled={transferTickets?.some((ticket) => ticket.isSelected)}
            onClick={() => {
              if (
                transferTickets?.some(
                  (ticket) =>
                    ticket.orderCode !== '' &&
                    ticket.orderCode !== undefined &&
                    ticket.isSelected,
                )
              ) {
                setTransferState('warningTicketIsAnnounced')
              } else {
                setTransferState('confirmTransfer')
              }
            }}
          />
        </div>
      </div>
      {showIntransferableTicketModal && (
        <IntransferableTicketModal
          closeModal={() => {
            setShowIntransferableTicketModal(false)
          }}
        />
      )}
    </>
  )
}

export default SelectTransferTickets
