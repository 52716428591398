import { useEffect, useState, type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

import { AnimatedNumber } from '@/components/Global/AnimatedNumber/AnimatedNumber'

import userStore from '@/store/user'

import type { IEventInterestData } from '@/types/Event'

import { LuChevronRight } from 'react-icons/lu'

const PeopleCounterVariants = tv({
  slots: {
    button: 'flex items-center rounded-3xl',
    imageContainer: '',
    image: 'size-full rounded-full',
    animatedNumber: 'flex justify-center',
    plusSpan: '',
  },
  variants: {
    variant: {
      light: {
        button: 'bg-white',
        image: 'border-white',
        animatedNumber: 'text-dark-black',
      },
      dark: {
        button: 'bg-dark-light',
        image: 'border-dark-black',
        animatedNumber: 'text-white',
      },
    },
    size: {
      sm: {
        button: 'pl-4 py-1 pr-2 gap-1',
        image: 'border',
        imageContainer: '-ml-3 size-6',
        animatedNumber: 'font-medium text-2xs',
      },
      md: {
        button: 'pl-5 py-1 pr-2 gap-1',
        image: 'border-2',
        imageContainer: '-ml-4 size-8',
        animatedNumber: 'font-semibold text-2xs',
      },
      lg: {
        button: 'pl-4 gap-3',
        image: 'border-2',
        imageContainer: '-ml-4 size-12',
        animatedNumber: 'font-semibold text-xs',
        plusSpan: 'mr-0.5',
      },
    },
  },
})

interface PeopleCounterProps {
  data: IEventInterestData
  openEventInterestModal?: () => void
  isClickable?: boolean
  size?: 'sm' | 'md' | 'lg'
  showChevron?: boolean
  avatars?: number
  variant?: 'light' | 'dark'
  className?: string
}

export function PeopleCounter({
  data,
  openEventInterestModal,
  isClickable = true,
  size = 'md',
  showChevron = true,
  avatars = 3,
  variant = 'light',
  className,
}: PeopleCounterProps): ReactElement {
  const [animatedNumberValue, setAnimatedNumberValue] = useState(0)
  const [animatedNumberWidth, setAnimatedNumberWidth] = useState(10)

  const { id } = userStore()

  const displayedUsers = Math.min(avatars, data.users.length)
  const variants = PeopleCounterVariants({ size, variant })

  useEffect(() => {
    const extraUsers = data.total - displayedUsers
    setAnimatedNumberValue(extraUsers)
    setAnimatedNumberWidth(extraUsers.toString().split('.')[0].length * 8)
  }, [data.total, displayedUsers])

  if (data.total === 0) return <></>

  return (
    <div>
      <button
        className={twMerge(
          variants.button(),
          data.total > displayedUsers ? 'pr-2' : 'pr-1',
          isClickable ? 'cursor-pointer' : 'cursor-default',
          className,
        )}
        onClick={() => {
          if (isClickable && openEventInterestModal !== undefined)
            openEventInterestModal()
        }}
      >
        <div className="flex gap-[4px]">
          {data.users
            .sort((user) => (user.id === id ? -1 : 1))
            .slice(0, displayedUsers)
            .map((user, index) => {
              return (
                <div
                  className={variants.imageContainer()}
                  key={user.id}
                  style={{
                    zIndex: displayedUsers - index,
                  }}
                >
                  <img
                    src={`${import.meta.env.VITE_S3 as string}/${
                      user.profileImageKey
                    }`}
                    alt="Usuário"
                    className={variants.image()}
                  />
                </div>
              )
            })}
        </div>
        {data.total > displayedUsers && (
          <div
            className={variants.animatedNumber()}
            style={{
              width: `${animatedNumberWidth}px`,
            }}
          >
            <span className={variants.plusSpan()}>+</span>
            <AnimatedNumber
              val={animatedNumberValue}
              isMoney={false}
              animationDuration={1}
            />
          </div>
        )}
        {showChevron && (
          <div>
            <LuChevronRight color="#181818" />
          </div>
        )}
      </button>
    </div>
  )
}

export default PeopleCounter
