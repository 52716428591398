import { useRef, type ReactElement, useEffect, useState } from 'react'

import AreaChart from '@/components/Chart/AreaChart'

import { numberToReais } from '@/helpers/formatNumber'

import { type ILinkAnalytics } from '@/types/hooks/api/useLinks'

interface LinkAnalyticsProps {
  linkAnalytics: ILinkAnalytics
}

export default function LinkAnalytics({
  linkAnalytics,
}: LinkAnalyticsProps): ReactElement {
  const [maxChartHeight, setMaxChartHeight] = useState(0)

  const dataArea = linkAnalytics?.salesByDate.map((value) => {
    return {
      pv: value,
    }
  })

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (linkAnalytics !== undefined) {
      setMaxChartHeight(containerRef.current?.clientHeight ?? 0)
    }
  }, [linkAnalytics])

  return (
    <div className="size-full max-h-full" ref={containerRef}>
      <div
        className="h-[calc(100%-110px)] pb-6"
        style={{
          maxHeight: maxChartHeight - 110,
        }}
      >
        <AreaChart
          data={dataArea !== undefined && dataArea?.length > 0 ? dataArea : []}
          variant="dark"
        />
      </div>
      <div className="flex size-full flex-col gap-6 px-2">
        <div className="flex w-full justify-between">
          <div className="flex flex-col">
            <span className="text-xs font-bold leading-3 text-[#A3A3A3]">
              Faturamento
            </span>
            <span className="text-3xl font-bold leading-7 text-white">
              {numberToReais(linkAnalytics?.revenue ?? 0, 2)}
            </span>
          </div>
          <div className="flex flex-col items-end">
            <span className="text-xs font-bold leading-3 text-[#A3A3A3]">
              Vendas
            </span>
            <span className="text-3xl font-bold leading-7 text-white">
              {linkAnalytics?.sales}
            </span>
          </div>
        </div>

        <div className="flex w-full justify-between">
          <div className="flex flex-col">
            <span className="text-xs font-bold leading-3 text-[#A3A3A3]">
              Ticket médio
            </span>
            <span className="text-3xl font-bold leading-7 text-white">
              {numberToReais(linkAnalytics?.averageTicket ?? 0, 2)}
            </span>
          </div>
          <div className="flex flex-col items-end">
            <span className="text-xs font-bold leading-3 text-[#A3A3A3]">
              Média de idade
            </span>
            <span className="text-3xl font-bold leading-7 text-white">
              {linkAnalytics?.averageAge} anos
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
