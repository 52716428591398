import { create } from 'zustand'

interface SnackbarProps {
  showSnackbar: boolean
  snackbarMessage: string
  showSnackbarWrapper: boolean
  snackbarType: 'success' | 'error' | 'information'
  showError: (errorMessage: string, screenTime?: number) => void
}

export const snackbarStore = create<SnackbarProps>((set) => {
  return {
    showSnackbar: false,
    snackbarMessage: '',
    showSnackbarWrapper: false,
    snackbarType: 'error',
    showError: (errorMessage: string, screenTime = 3000) => {
      set({
        snackbarMessage: errorMessage,
        showSnackbar: true,
        showSnackbarWrapper: true,
        snackbarType: 'error',
      })

      setTimeout(() => {
        set({
          showSnackbar: false,
        })
      }, screenTime)

      setTimeout(() => {
        set({
          snackbarMessage: '',
          showSnackbarWrapper: false,
        })
      }, screenTime + 400)
    },
  }
})
