import { useNavigate } from 'react-router-dom'

import userStore from '@/store/user'
import routingStore from '@/store/routing'

interface UseAuthNavigateReturn {
  checkAuthNavigate: (to: string) => void
}

export function useAuthNavigate(): UseAuthNavigateReturn {
  const { userIsLogged } = userStore()
  const { setShowLogin, setToPath } = routingStore()

  const navigate = useNavigate()

  function checkAuthNavigate(to: string): void {
    if (userIsLogged) {
      navigate(to)
    } else {
      setToPath(to)
      setShowLogin(true)
    }
  }

  return { checkAuthNavigate }
}
