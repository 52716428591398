import { useState } from 'react'
import useSWR from 'swr'
import fetcher, { api } from '@/services/axios'
import { type AxiosResponse } from 'axios'

import {
  type IRefundRequirements,
  type IEventPayment,
} from '@/types/EventPayment'
import { type SectionData } from '@/types/hooks/api/useHomeEvents'

export const useGetEventPayment = (
  eventAlias: string,
): {
  eventPaymentsData: IEventPayment | undefined
  error: string | undefined
  isLoading: boolean
  refresh: () => void
} => {
  const { data, error, mutate } = useSWR<IEventPayment, string>(
    `/events/${eventAlias}/payments`,
    fetcher,
  )

  const isLoading = !(data !== undefined || error !== undefined)

  const eventPaymentsData = data ?? undefined

  function refresh(): void {
    void mutate()
  }

  return { eventPaymentsData, error, isLoading, refresh }
}

export const useGetRefund = (): {
  refund: (refundId: number) => Promise<{ status: number }>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function refund(refundId: number): Promise<{ status: number }> {
    setIsLoading(true)
    // setTimeout(setIsLoading, 2000, false)
    // return
    try {
      await api.get<SectionData[]>(`/payments/${refundId}/refund`)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      const error = err as AxiosResponse<string>
      setIsLoading(false)
      return {
        status: error.status ?? 500,
      }
    }
  }

  return { refund, isLoading }
}

export const useGetRefundRequirements = (
  paymentId: number,
): {
  refundRequirements: IRefundRequirements | undefined
  error: string | undefined
  isLoading: boolean
} => {
  const shouldFetch = paymentId !== undefined
  const { data, error } = useSWR<IRefundRequirements, string>(
    shouldFetch && `/payments/${paymentId}/refund/requirements`,
    fetcher,
  )

  const isLoading = !(data !== undefined || error !== undefined)

  const refundRequirements = data ?? undefined

  return { refundRequirements, error, isLoading }
}
