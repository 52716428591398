import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import {
  type IWalletTickets,
  type IWalletEvent,
} from '@/types/hooks/api/useWallet'

interface Ticket extends IWalletTickets {
  isSelected: boolean
}

interface UseTicketProps {
  sellState: 'selectTicket' | 'shareLink'
  sellTickets: Ticket[]
  sellPrice: number
  sellTicketsEvent: IWalletEvent
  sellLink: string
  setSellState: (sellState: 'selectTicket' | 'shareLink') => void
  setSellTickets: (sellTickets: Ticket[]) => void
  setSellPrice: (sellPrice: number) => void
  setSellTicketsEvent: (sellTicketsEvent: IWalletEvent) => void
  setSellLink: (sellLink: string) => void
  switchTicketSelectState: (id: number) => void
  resetSellStore: () => void
}

const sellStore = create<UseTicketProps>()(
  devtools(
    persist(
      (set) => ({
        sellState: 'selectTicket',
        sellTickets: [],
        sellPrice: 0,
        sellTicketsEvent: {
          id: 0,
          alias: '',
          imageKey: '',
          titleImageKey: '',
          title: '',
          startDate: '',
          endDate: '',
          location: '',
        },
        sellLink: '',
        setSellState: (sellState: 'selectTicket' | 'shareLink') => {
          set({ sellState })
        },
        setSellTickets: (sellTickets: Ticket[]) => {
          set({ sellTickets })
        },
        setSellPrice: (sellPrice: number) => {
          set({ sellPrice })
        },
        setSellTicketsEvent: (sellTicketsEvent: IWalletEvent) => {
          set({ sellTicketsEvent })
        },
        setSellLink: (sellLink: string) => {
          set({ sellLink })
        },
        switchTicketSelectState: (id: number) => {
          set((state) => ({
            sellTickets: state.sellTickets.map((ticket) =>
              ticket.id === id
                ? { ...ticket, isSelected: !ticket.isSelected }
                : { ...ticket, isSelected: false },
            ),
          }))
        },
        resetSellStore: () => {
          set(() => ({
            sellState: 'selectTicket',
            sellTickets: [],
            sellPrice: 0,
            sellTicketsEvent: {
              id: 0,
              alias: '',
              imageKey: '',
              titleImageKey: '',
              title: '',
              startDate: '',
              endDate: '',
              location: '',
            },
          }))
        },
      }),
      {
        name: 'sell-storage',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
)

export default sellStore
