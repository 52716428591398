import { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { SlArrowRight } from 'react-icons/sl'

import EventBrief from '@/components/Event/EventBrief/EventBrief'
import OnFire from '@/components/Global/OnFire/OnFire'
import { isEventFinished } from '@/helpers/isEventFinished'
import { datesToFormat } from '@/helpers/formatDate'

interface EventCardProps {
  alias: string | undefined
  imageUrl: string
  startDate: string
  location?: string
  title?: string
  variation: 'small' | 'medium' | 'big'
  onFire?: boolean
  to?: string
  endDate: string
}

function EventCard({
  alias,
  imageUrl,
  startDate,
  location,
  title,
  variation,
  onFire = false,
  to = `/events/${alias ?? ''}`,
  endDate,
}: EventCardProps): ReactElement {
  const variations = {
    parentDiv: {
      small: 'p-1 border flex-row rounded-r-lg bg-transparent',
      medium: 'p-1 border flex-col rounded-b-lg',
      big: 'p-4 border-2 flex-row rounded-b-lg items-center',
    },
    imageDiv: {
      small: 'w-16 h-16',
      medium: 'relative w-40 h-40 mb-1',
      big: 'w-32 h-32 mr-2',
    },
    image: {
      small: 'w-full rounded h-full object-cover',
      medium: 'w-full rounded-md h-full object-cover',
      big: 'w-full rounded-md h-full object-cover',
    },
    briefDiv: {
      small:
        'bg-background-main w-full max-w-full flex items-center justify-between',
      medium: 'bg-background-main w-40',
      big: 'h-min',
    },
    title: {
      small: 'max-w-full text-ellipsis line-clamp-1 text-xs text-white',
      medium: 'leading-4 text-white text-sm text-ellipsis line-clamp-1',
      big: 'text-2xl text-white font-bold leading-6',
    },
  }

  const isPastEvent = isEventFinished(endDate)
  const formattedEventDate = datesToFormat(startDate, endDate)

  return (
    <Link
      className={twMerge(
        'flex items-center gap-1 rounded-md border border-secondary-gray bg-transparent p-1',
        variation === 'medium' && 'flex-col gap-0',
      )}
      to={to}
    >
      <div>
        <div className={variations.imageDiv[variation]}>
          <img
            src={`${import.meta.env.VITE_S3 as string}/${imageUrl}`}
            alt="Event image"
            className={variations.image[variation]}
          />
          {variation === 'medium' && onFire && (
            <div className="absolute right-2 top-2">
              <OnFire variant="simpleWithBorder" />
            </div>
          )}
        </div>
      </div>
      <div
        className={twMerge(
          'min-w-0 flex-grow',
          variation === 'medium' && 'w-full max-w-40',
        )}
      >
        <div className="flex h-16 justify-between gap-3 rounded-md bg-background-main p-2">
          <div className="min-w-0">
            <h1 className={variations.title[variation]}>{title}</h1>
            <EventBrief
              date={formattedEventDate}
              location={location}
              padding="0"
              variant="white"
              highlighted={variation !== 'big'}
            />
          </div>
          {isPastEvent && (
            <div className="flex w-[82px] min-w-[82px] items-center justify-center rounded-md pr-1">
              <div className="rounded-3xl border border-[#595959] px-2 py-0.5 text-xs font-medium leading-none text-[#595959]">
                Encerrado
              </div>
            </div>
          )}
          {variation === 'small' && !isPastEvent && (
            <div className="mr-1 flex h-full items-center gap-1">
              {onFire && <OnFire variant="simple" />}
              <SlArrowRight color="#7c7c7c" className="" size={12} />
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

export default EventCard
