import Spinner from '@/components/Global/Spinner/Spinner'
import {
  motion,
  AnimatePresence,
  useMotionValue,
  useTransform,
} from 'framer-motion'
import { useRef, useState, type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

interface SliderProps {
  isLoading: boolean
  disabled?: boolean
  handleRefund: () => Promise<void>
}

export function Slider({
  isLoading,
  disabled = false,
  handleRefund,
}: SliderProps): ReactElement {
  const [maxDrag, setMaxDrag] = useState(0)

  const dragRef = useRef<HTMLDivElement>(null)

  const x = useMotionValue(0)
  const input = [0, 300]
  const output = [0.75, 1]
  const opacity = useTransform(x, input, output)

  function calculateMaxDrag(): void {
    if (dragRef.current !== null && dragRef?.current?.clientWidth !== null) {
      setMaxDrag(dragRef.current.clientWidth - 194)
    }
  }

  return (
    <motion.div className="relative h-14 w-full " ref={dragRef}>
      <div
        className={twMerge(
          ' absolute h-14 w-full rounded-full',
          isLoading || disabled ? 'bg-dark-dark-gray' : 'slide',
        )}
      />
      <AnimatePresence mode="wait">
        {isLoading ? (
          <motion.div
            className="flex size-full items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            key="loading-spinner"
          >
            <Spinner
              borderWidth="border-[5px]"
              borderColor="border-[#232323]/50"
              bottomBorderColor="border-b-[#232323]"
              width="w-8"
              height="h-8"
            />
          </motion.div>
        ) : (
          <motion.div
            className="size-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            key="drag-to-cancel"
          >
            <motion.div
              className="ml-2 mt-2 flex h-10 w-fit items-center justify-center rounded-full bg-black/50 px-6"
              drag="x"
              style={{ touchAction: 'none', x, opacity }}
              onDrag={(_, info) => {
                if (info.offset.x < 0) {
                  x.set(0)
                }
                if (info.offset.x > maxDrag) {
                  x.set(maxDrag)
                }
                if (disabled) {
                  x.set(0)
                }
              }}
              onDragEnd={(_, info) => {
                if (info.offset.x > maxDrag - 50 && !disabled) {
                  void handleRefund()
                }
              }}
              dragElastic={0.9}
              dragSnapToOrigin
              onDragStart={() => {
                calculateMaxDrag()
              }}
              dragMomentum={false}
            >
              <span
                className={twMerge(
                  'text-xs font-bold text-white',
                  disabled && 'opacity-30',
                )}
              >
                Deslize para cancelar 💔
              </span>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}
