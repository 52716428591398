import { useState, type ReactElement, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import EventThumbnail from '@/components/Event/EventThumbnail/EventThumbnail'
import Payment from '@/components/EventPayments/Payment/Payment'
import Loading from '@/components/Global/Loading/Loading'
import { ConfirmCancellationModal } from '@/components/EventPayments/ConfirmCancellationModal/ConfirmCancellationModal'

import { useGetEventPayment } from '@/hooks/api/useEventPayment'

import { datesToFormat } from '@/helpers/formatDate'

import { BsArrowLeftShort } from 'react-icons/bs'
import { isTruthy } from '@/helpers/validation'

function EventPayments(): ReactElement {
  const [showConfirmCancellationModal, setShowConfirmCancellationModal] =
    useState(false)
  const [paymentId, setPaymentId] = useState<number | null>(null)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const navigate = useNavigate()
  const { eventAlias } = useParams()

  const { eventPaymentsData, refresh } = useGetEventPayment(eventAlias!)

  useEffect(() => {
    if (eventPaymentsData !== undefined) {
      setIsFirstLoad(false)
    }
  }, [eventPaymentsData])

  if (isFirstLoad) {
    return <Loading />
  }
  return (
    <div className="flex h-full min-h-full flex-col overflow-y-scroll bg-background-main p-4 pb-24">
      <div className="flex gap-4">
        <button
          className="rounded-full bg-dark-white p-0.5"
          onClick={() => {
            navigate('/wallet')
          }}
        >
          <BsArrowLeftShort size={32} color="#232323" />
        </button>
        <h1 className="line-clamp-1 text-2xl text-white">
          Compras {eventPaymentsData?.event?.name}
        </h1>
      </div>

      <div className="flex flex-col gap-4">
        <div className="mt-6">
          <EventThumbnail
            title={eventPaymentsData?.event?.name}
            imageKey={eventPaymentsData?.event?.imageKey ?? ''}
            date={datesToFormat(
              eventPaymentsData?.event?.startDate ?? '',
              eventPaymentsData?.event?.endDate ?? '',
            )}
            location={
              isTruthy(eventPaymentsData?.event?.location?.address)
                ? `${eventPaymentsData?.event?.location?.address ?? ''} • ${
                    eventPaymentsData?.event?.location?.name ?? ''
                  }`
                : 'Local a definir'
            }
            briefVariant="white"
            highlighted
          />
        </div>
      </div>
      {eventPaymentsData?.payments.length === 0 ? (
        <span className="mt-8 w-full text-center text-white">
          Você não realizou nenhuma compra deste evento.
        </span>
      ) : (
        <div className="mt-4 flex w-full flex-col gap-2">
          {eventPaymentsData?.payments
            .sort(
              (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
            )
            .map((eventPayment) => {
              return (
                <Payment
                  payment={eventPayment}
                  key={eventPayment.id}
                  openModal={() => {
                    setPaymentId(eventPayment.id)
                    setShowConfirmCancellationModal(true)
                  }}
                />
              )
            })}
        </div>
      )}
      {showConfirmCancellationModal && (
        <ConfirmCancellationModal
          paymentId={paymentId!}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowConfirmCancellationModal, 400, false)
          }}
          isParentClosing={isParentClosing}
          refreshData={refresh}
        />
      )}
    </div>
  )
}

export default EventPayments
