import { useState, useEffect } from 'react'
import fetcher, { api } from '@/services/axios'
import useSWR from 'swr'

import {
  type IFollowUnfollowOrganizationReturn,
  type IOrganization,
} from '@/types/hooks/api/useOrganization'
import { type AxiosError } from 'axios'

export const useGetOrganization = (
  organizationAlias: string,
): {
  organization: IOrganization | undefined
  error: string | undefined
  isLoading: boolean
  refresh: () => Promise<void>
} => {
  const [isLoading, setIsLoading] = useState(false)
  const { data, error, mutate } = useSWR<IOrganization, string>(
    `/organizations/${organizationAlias}/events`,
    fetcher,
  )

  useEffect(() => {
    if (data !== undefined || error !== undefined) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [data, error])

  const organization: IOrganization | undefined = data ?? undefined

  async function refresh(): Promise<void> {
    await mutate()
  }

  return {
    organization,
    error,
    isLoading,
    refresh,
  }
}

export const usePostFollowOrganization = (
  organizationAlias: string,
): {
  isLoading: boolean
  postFollowOrganization: () => Promise<IFollowUnfollowOrganizationReturn>
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postFollowOrganization(): Promise<IFollowUnfollowOrganizationReturn> {
    setIsLoading(true)

    try {
      await api.post(`/organizations/${organizationAlias}/follow`)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { postFollowOrganization, isLoading }
}

export const useDeleteUnfollowOrganization = (
  organizationAlias: string,
): {
  isLoading: boolean
  deleteUnfollowOrganization: () => Promise<IFollowUnfollowOrganizationReturn>
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function deleteUnfollowOrganization(): Promise<IFollowUnfollowOrganizationReturn> {
    setIsLoading(true)

    try {
      await api.delete(`/organizations/${organizationAlias}/unfollow`)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { deleteUnfollowOrganization, isLoading }
}
