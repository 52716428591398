import QRCode from 'qrcode'
import { type ReactElement, useEffect, useState } from 'react'

interface QRCodeProps {
  value: string
  variant?: 'light' | 'dark'
}

function QrCode({ value, variant = 'dark' }: QRCodeProps): ReactElement {
  const [qrcode, setQrcode] = useState('')

  const GenerateQRCode = (): void => {
    QRCode.toDataURL(
      value,
      {
        errorCorrectionLevel: 'H',
        width: 800,
        margin: 4,
        color: {
          dark: variant === 'dark' ? '#ffffff' : '#232323',
          light: variant === 'dark' ? '#232323' : '#ffffff',
        },
      },
      function (err, url) {
        if (err !== null) {
          return
        }
        setQrcode(url)
      },
    )
  }

  useEffect(() => {
    if (value !== undefined) GenerateQRCode()
  }, [value])

  return (
    <div className="flex size-full justify-center overflow-hidden rounded-xl">
      <img src={qrcode} alt="Event QRCode" className="h-full w-auto" />
    </div>
  )
}

export default QrCode
