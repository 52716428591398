export const SEND_EMAIL_ERROR =
  'Ocorreu um erro ao enviar o email, tente novamente em alguns instantes.'

export const LOGIN_ERROR =
  'Ocorreu um erro ao fazer o login, tente novamente em alguns instantes.'

export const SEND_OTP_ERROR =
  'Ocorreu um erro ao enviar o código, tente novamente em alguns instantes.'

export const PROFILE_PICTURE_ERROR =
  'Ocorreu um erro ao definir a imagem de perfil.'

export const SIGNUP_ERROR = 'Ocorreu um erro ao realizar o cadastro.'

export const VALIDATE_TOKEN_ERROR = 'Occorreu um erro ao validar o token.'

export const EMAIL_NOT_AVAILABLE_ERROR =
  'Esse email já está em uso, tente outro email ou entre em contato com o nosso suporte.'

export const GLUE_STICKER_ERROR =
  'Ocorreu um erro ao colar o sticker, tente novamente em alguns instantes.'

export const CLAIM_ERROR =
  'Ocorreu um erro ao receber os ingressos, tente novamente em alguns instantes.'

export const SEARCH_ERROR = 'Ocorreu um erro ao realizar a pesquisa.'

export const PAYMENT_NOT_APPROVED_ERROR =
  'O pagamento ainda não foi aprovado. Tente novamente em alguns instantes.'

export const PAYMENT_VALIDATION_ERROR =
  'Ocorreu um erro ao validar o pagamento.'

export const CREATE_PIX_PAYMENT_ERROR =
  'Ocorreu um erro ao criar o pagamento PIX'

export const USER_SEARCH_ERROR = 'Ocorreu um erro ao realizar a pesquisa.'

export const NO_USER_FOUND = 'Nenhum usuário encontrado...'

export const CANCEL_ORDER_ERROR =
  'Ocorreu um erro ao cancelar a venda, tente novamente em alguns instantes.'

export const ORDER_NOT_AVAILABLE = 'Esse link de revenda não está mais ativo.'

export const ORDER_ALREADY_SOLD =
  'Esse ingresso já foi comprado por outro comprador.'

export const TICKET_NOT_AVAILABLE =
  'Os ingressos selecionados não estão mais disponíveis...'

export const TICKETS_NOT_AVAILABLE_ANYMORE =
  'Os ingressos selecionados não estão mais disponíveis! As opções serão atualizadas automaticamente.'

export const ORDER_RESERVED =
  'Esse ingresso já está reservado por outro comprador. Tente novamente em alguns instantes.'

export const CANT_CREATE_PAYMENT =
  'Ocorreu um erro ao gerar o pagamento, tente novamente em alguns instantes.'

export const CREATE_MKT_ORDER_ERROR =
  'Erro ao criar link de venda. Tente novamente!'

export const LOAD_PAYMENT_ERROR = 'Erro ao carregar dados do pagamento'

export const FETCH_EVENT_TICKETS_ERROR =
  'Erro ao puxar tickets do evento. Tente novamente.'

export const NO_TICKETS_TO_TRANSFER =
  'Você não possui ingressos para transferir.'

export const NO_TICKETS_TO_SELL = 'Você não possui ingressos para vender.'

export const MIN_SELL_PRICE = 'O valor mínimo para venda é de R$ 1,00'

export const INVALID_BRAZILLIAN_PHONE =
  'Número de telefone inválido. Certifique-se de inserir DDD + 9 e então seu celular.'

export const ERROR_TO_CREATE_FOLLOW = 'Erro ao seguir usuário. Tente novamente.'

export const ERROR_TO_UNFOLLOW =
  'Erro ao deixar de seguir usuário. Tente novamente.'

export const ERROR_TO_CANCEL_FOLLOW =
  'Erro ao cancelar pedido para seguir usuário. Tente novamente.'

export const ERROR_TO_ACCEPT_FOLLOW_REQUEST =
  'Erro ao aceitar solicitação. Tente novamente.'

export const ERROR_TO_DENY_FOLLOW_REQUEST =
  'Erro ao rejeitar solicitação. Tente novamente.'

export const ERROR_TO_LOAD_MORE_USERS = 'Erro ao carregar mais usuários.'
