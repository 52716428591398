import { api } from '@/services/axios'
import { type AxiosError, type AxiosResponse } from 'axios'
import { useState } from 'react'

import {
  type IUserDataResponse,
  type IGetSearchUsersReturn,
  type IGenericSearchDataResponse,
  type IGenericSearchDataReturn,
} from '@/types/hooks/api/useSearch'

export const useGetSearchUsers = (): {
  searchUsers: (searchParam: string) => Promise<IGetSearchUsersReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function searchUsers(
    searchParam: string,
  ): Promise<IGetSearchUsersReturn> {
    setIsLoading(true)

    try {
      const response: AxiosResponse<IUserDataResponse[]> = await api.get(
        `/search/users?term=${searchParam}&skip=0&take=10`,
      )
      setIsLoading(false)
      return {
        status: 200,
        users: response.data.map((user) => {
          return {
            ...user,
          }
        }),
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { searchUsers, isLoading }
}

export const useGetGenericSearch = (): {
  search: (searchParam: string) => Promise<IGenericSearchDataReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function search(
    searchParam: string,
  ): Promise<IGenericSearchDataReturn> {
    setIsLoading(true)

    try {
      const response: AxiosResponse<IGenericSearchDataResponse> = await api.get(
        `/search?term=${searchParam}&skip=0&take=10`,
      )
      setIsLoading(false)
      return {
        status: 200,
        data: response.data,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { search, isLoading }
}
