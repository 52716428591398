import { useEffect, type ReactElement, useState } from 'react'
import { motion } from 'framer-motion'
import { useNavigate, useParams } from 'react-router-dom'

import { useGetPaymentById } from '@/hooks/api/usePayment'

import TicketSummary from '@/components/Global/TicketSummary/TicketSummary'
import CheckoutSuccessModal from '@/components/CheckoutSuccessModal/CheckoutSuccessModal'
import Loading from '@/components/Global/Loading/Loading'
import SnackBar from '@/components/Global/SnackBar/SnackBar'

import EventBase from '@/compositions/EventBase/EventBase'

import { numberToReais } from '@/helpers/formatNumber'
import { isTruthy } from '@/helpers/validation'

import userStore from '@/store/user'

import { type PaymentData } from '@/types/hooks/api/usePayment'

import { LOAD_PAYMENT_ERROR } from '@/errors'

function EventCheckoutSuccess(): ReactElement {
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false)
  const [paymentData, setPaymentData] = useState<PaymentData>()
  const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(true)
  const [isModalClosing, setIsModalClosing] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { paymentId } = useParams()

  const { username } = userStore()

  const { getPaymentById } = useGetPaymentById()

  const navigate = useNavigate()

  async function getPayment(): Promise<void> {
    const response = await getPaymentById(Number(paymentId))
    if (response.status === 200) {
      setPaymentData(response.paymentData)
      setIsLoadingPayment(false)
      setTimeout(setShowPaymentSuccessModal, 1500, true)
    } else {
      setIsLoadingPayment(false)
      setIsError(true)
      setErrorMessage(LOAD_PAYMENT_ERROR)
      setTimeout(setIsError, 3000, false)
    }
  }

  useEffect(() => {
    void getPayment()
  }, [])

  if (isLoadingPayment) return <Loading hidden={isLoadingPayment} />
  return (
    <>
      <EventBase.Root>
        <motion.div
          className="size-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          <div className="flex w-full flex-col gap-2 p-4">
            {paymentData?.productPayments.map((ticket) => {
              const title = isTruthy(ticket.batchDescription)
                ? `${ticket.batchDescription} - ${ticket.ticketDescription}`
                : ticket.ticketDescription
              return (
                <TicketSummary
                  key={ticket.id}
                  title={title}
                  quantity={ticket.quantity}
                  thirdInfo={`Total: ${numberToReais(ticket.subtotal, 2)}`}
                  counterVariant="dark"
                />
              )
            })}
          </div>
        </motion.div>
      </EventBase.Root>
      {showPaymentSuccessModal && (
        <CheckoutSuccessModal
          title="Compra realizada"
          onClick={() => {
            setIsModalClosing(true)
            setTimeout(navigate, 300, `/memories/${username}`)
          }}
          isParentClosing={isModalClosing}
        />
      )}
      <div className="fixed bottom-0 w-full">
        <SnackBar showSnackBar={isError} message={errorMessage} offSet={60} />
      </div>
    </>
  )
}

export default EventCheckoutSuccess
