import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import QRCode from '@/components/UserWallet/QRCode/QRCode'

import { CiCircleCheck } from 'react-icons/ci'

interface EventTicketProps {
  title?: string
  code: string
  validated?: boolean
  handleOpenQRCodeModal?: (code: string) => void
}

function EventTicket({
  title,
  code,
  validated = false,
  handleOpenQRCodeModal,
}: EventTicketProps): ReactElement {
  return (
    <>
      <div
        className="relative h-[352px] w-72 rounded-3xl bg-primary-main"
        style={{ margin: '0 auto' }}
      >
        <div className="absolute left-4 top-4 flex h-12 w-64 items-center justify-center rounded-xl bg-background-main px-2">
          <h1 className="line-clamp-2 text-ellipsis text-center text-white">
            {title}
          </h1>
        </div>
        <div className="absolute -left-2 top-16 size-4 rounded-full bg-background-main" />
        <div className="absolute -right-2 top-16 size-4 rounded-full bg-background-main" />
        <div className="absolute left-[3px] top-[71px] h-[3px] w-full divide-x-2 border-t-[3px] border-dotted border-background-main" />
        <div className="absolute bottom-4 left-4 flex size-64 items-center justify-center rounded-xl bg-background-main">
          <div
            className={twMerge(
              'relative h-full w-full',
              (title === undefined || validated) && 'opacity-50 blur-sm',
            )}
            onClick={() => {
              if (handleOpenQRCodeModal !== undefined)
                handleOpenQRCodeModal(code)
            }}
          >
            <QRCode value={code} />
          </div>
          {title === undefined && (
            <span className="absolute w-4/5 text-center text-lg font-bold leading-5 text-white">
              Clique em um evento para visualizar seus ingressos
            </span>
          )}
          {validated && (
            <div className="absolute flex size-full items-center justify-center">
              <CiCircleCheck
                size={64}
                color="#D5FF5C"
                style={{ strokeWidth: '0.5px' }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default EventTicket
