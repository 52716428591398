import { type ReactElement, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

import Modal from '@/components/Global/Modal/Modal'
import Button from '@/components/Global/Button/Button'

import { BiSticker } from 'react-icons/bi'

import { type IUserSticker } from '@/types/global/sticker'

interface NewStickersModalProps {
  closeModal: () => void
  setNewStickerToGlue: (s: IUserSticker) => void
  onSelectSticker: () => void
  notGluedStickers?: IUserSticker[]
}

function NewStickersModal({
  closeModal,
  setNewStickerToGlue,
  onSelectSticker,
  notGluedStickers,
}: NewStickersModalProps): ReactElement {
  const [activeSlide, setActiveSlide] = useState(0)
  const [isParentClosing, setIsParentClosing] = useState(false)

  function generateRandomRotation(): number {
    const value = Math.floor(Math.random() * 20) + 10
    if (Math.random() > 0.5) {
      return value
    } else {
      return -value
    }
  }

  const newStickersVariants = {
    active: 'transition-all duration-200 ease-in-out',
    inactive: 'brightness-[0.8] transition-all duration-200 ease-in-out',
  }

  function handleCloseModal(): void {
    setIsParentClosing(true)
    setTimeout(closeModal, 400, true)
  }

  function handleGlueSticker(): void {
    if (notGluedStickers === undefined) return
    const selectedSticker = notGluedStickers[activeSlide]
    setNewStickerToGlue(selectedSticker)
    onSelectSticker()
    handleCloseModal()
  }

  return (
    <Modal closeModal={closeModal} isParentClosing={isParentClosing}>
      <div className="w-full">
        <div className="mt-8 flex flex-col gap-2 px-8">
          <h1 className="text-2xl">Seu sticker</h1>
          <div className="flex items-center gap-2">
            <div className="flex size-8 items-center justify-center rounded-full bg-dark-black">
              <BiSticker color="white" size={20} />
            </div>
            <span className="text-sm leading-4">
              O que ficam são as memórias. <br />
              Cole seu{' '}
              <u>
                <i>
                  <strong>sticker</strong>
                </i>
              </u>{' '}
              na sua página de memórias.
            </span>
          </div>
        </div>
        <div className="mb-2 mt-8 w-full">
          <Swiper
            slidesPerView={1.8}
            spaceBetween={0}
            className="z-30 w-full overflow-hidden rounded-2xl"
            centeredSlides
            modules={[Pagination]}
            pagination={{
              clickable: true,
            }}
            onSlideChange={(swiperCore) => {
              const { realIndex } = swiperCore
              setActiveSlide(realIndex)
            }}
          >
            {notGluedStickers?.map((sticker, index) => {
              return (
                <SwiperSlide key={sticker.stickerId}>
                  <div
                    className="flex items-start justify-center"
                    onClick={handleGlueSticker}
                  >
                    <img
                      src={`${import.meta.env.VITE_S3 as string}/${
                        sticker.imageKey ?? ''
                      }`}
                      alt="Sticker"
                      className={
                        newStickersVariants[
                          activeSlide === index ? 'active' : 'inactive'
                        ]
                      }
                      style={{
                        transform:
                          activeSlide === index
                            ? ''
                            : `rotate(${generateRandomRotation()}deg) scale(0.7)`,
                      }}
                    />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        <div className="mb-4 flex h-24 flex-col gap-2 px-4">
          <Button
            text="Colar nas memórias"
            enabled
            onClick={handleGlueSticker}
          />
          <Button
            text="Depois"
            enabled
            onClick={handleCloseModal}
            className="bg-dark-light-gray"
          />
        </div>
      </div>
    </Modal>
  )
}

export default NewStickersModal
