import SnackBar from '@/components/Global/SnackBar/SnackBar'

export function EventFooterInformation({
  showLinkAppliedSnackBar,
  showLinkCoppiedSnackBar,
  link,
}: {
  showLinkAppliedSnackBar: boolean
  showLinkCoppiedSnackBar: boolean
  link: string | null
}): JSX.Element {
  return (
    <SnackBar
      variant="information"
      showSnackBar={showLinkAppliedSnackBar || showLinkCoppiedSnackBar}
      message={
        <>
          {showLinkAppliedSnackBar && (
            <span className="ml-2 w-[90%] text-sm text-white">
              Cupom <strong>{link}</strong> aplicado com sucesso.
            </span>
          )}
          {showLinkCoppiedSnackBar && (
            <span className="ml-2 w-[90%] text-sm text-white">
              Link do evento copiado com sucesso.
            </span>
          )}
        </>
      }
    />
  )
}
