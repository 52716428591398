import { type ReactElement } from 'react'

interface SectionProps {
  title: string
  children: ReactElement
}

function Section({ title, children }: SectionProps): ReactElement {
  return (
    <div>
      <h1 className="pl-4 text-2xl text-white">{title}</h1>
      <div className="mt-2 px-4">{children}</div>
    </div>
  )
}

export default Section
