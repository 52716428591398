import { type ReactElement } from 'react'

import Modal from '@/components/Global/Modal/Modal'
import ProfilePicture from '../ProfilePicture/ProfilePicture'

interface ProfilePictureModalProps {
  isParentClosing?: boolean
  closeModal?: () => void
}

function ProfilePictureModal({
  isParentClosing,
  closeModal,
}: ProfilePictureModalProps): ReactElement {
  return (
    <Modal isParentClosing={isParentClosing} closeModal={closeModal}>
      <ProfilePicture />
    </Modal>
  )
}

export default ProfilePictureModal
