import { useEffect, type ReactElement, useState } from 'react'
import { motion } from 'framer-motion'
import { useParams } from 'react-router-dom'

import TicketSelector from '@/components/Global/TicketSelector/TicketSelector'
import SnackBar from '@/components/Global/SnackBar/SnackBar'

import EventBase from '@/compositions/EventBase/EventBase'

import { useGetTickets, usePostTicketsCheck } from '@/hooks/api/useTickets'
import { useAuthNavigate } from '@/hooks/useAuthNavigate'
import {
  usePixelTrackInitiateCheckout,
  usePixelTrackAddToCart,
} from '@/hooks/pixel'

import ticketsStore from '@/store/tickets'
import eventStore from '@/store/event'

import { TICKET_NOT_AVAILABLE, FETCH_EVENT_TICKETS_ERROR } from '@/errors'
import { isTruthy } from '@/helpers/validation'

interface EventTicketsProps {
  eventId?: number
  isPastEvent: boolean
}

function EventTickets({
  eventId,
  isPastEvent,
}: EventTicketsProps): ReactElement {
  const [total, setTotal] = useState(0)
  const [hasTicketSelected, setHasTicketSelected] = useState(false)
  const [isError, setIsError] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [promo, setPromo] = useState('')
  const [canSelectTickets, setCanSelectTickets] = useState(true)

  const { eventAlias } = useParams()

  const {
    tickets,
    getTotal,
    totalHasFee,
    setTickets,
    addTicket,
    removeTicket,
    canAdd,
    canRemove,
  } = ticketsStore()
  const { setTicketQuantity, integration } = eventStore()

  const { checkAvailability, isLoading: isLoadingCheck } = usePostTicketsCheck(
    eventId ?? 0,
    tickets,
  )

  const { ticketsData, error, isLoading } = useGetTickets(
    eventAlias ?? '',
    promo,
  )

  const { trackInitiateCheckout } = usePixelTrackInitiateCheckout()
  const { trackAddToCart } = usePixelTrackAddToCart()
  const { checkAuthNavigate } = useAuthNavigate()

  const footerTitle = hasTicketSelected
    ? 'Valor total'
    : 'Selecione seus ingressos'
  const footerValue = hasTicketSelected ? total : 0
  const footerSubtitle = totalHasFee() ? '+ taxa' : ''
  const footerButtonText =
    hasTicketSelected && total === 0 ? 'Reservar agora' : 'Comprar agora'
  const footerButtonEnabled = hasTicketSelected && !isLoadingCheck && !isLoading

  useEffect(() => {
    if (ticketsData !== undefined) {
      // Set tickets if there's nothing in the current store
      if (tickets.length === 0) {
        setTickets(ticketsData)
        return
      }

      // Update tickets store if fetched object is different from current store
      if (tickets.length !== ticketsData.length) {
        setTickets(ticketsData)
        return
      }

      // Filter common atributtes to compare
      const tempTicketsData = ticketsData.map((ticket) => {
        return {
          batchDescription: ticket.batchDescription,
          discountAmount: ticket.discountAmount,
          discountPercentage: ticket.discountPercentage,
          limit: ticket.limit,
          price: ticket.price,
          promo: ticket.promo,
          ticketSpecDescription: ticket.ticketSpecDescription,
          ticketSpecId: ticket.ticketSpecId,
          paymentMethods: ticket.paymentMethods,
          extraDescription: ticket.extraDescription,
        }
      })

      const tempTickets = tickets.map((ticket) => {
        return {
          batchDescription: ticket.batchDescription,
          discountAmount: ticket.discountAmount,
          discountPercentage: ticket.discountPercentage,
          limit: ticket.limit,
          price: ticket.price,
          promo: ticket.promo,
          ticketSpecDescription: ticket.ticketSpecDescription,
          ticketSpecId: ticket.ticketSpecId,
          paymentMethods: ticket.paymentMethods,
          extraDescription: ticket.extraDescription,
        }
      })

      // Update tickets store if fetched object is different from current store
      if (JSON.stringify(tempTicketsData) !== JSON.stringify(tempTickets)) {
        setTickets(ticketsData)
        return
      }
    }

    if (error !== undefined) {
      setIsError(true)
      setMessageError(FETCH_EVENT_TICKETS_ERROR)
      setTimeout(setIsError, 3000, false)
    }
  }, [ticketsData, error])

  useEffect(() => {
    setTotal(getTotal())
  }, [tickets])

  async function handleClick(): Promise<void> {
    setCanSelectTickets(false)
    const isAvailable = await checkAvailability()
    setCanSelectTickets(true)
    if (isAvailable) {
      if (isTruthy(integration?.pixelId)) {
        trackInitiateCheckout(integration?.pixelId ?? '')

        tickets.forEach((ticket) => {
          if (ticket.quantitySelected === 0) return
          const productTitle = isTruthy(ticket.batchDescription)
            ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
            : ticket.ticketSpecDescription

          trackAddToCart(
            integration?.pixelId ?? '',
            productTitle,
            ticket.price,
            ticket.quantitySelected,
            ticket.ticketSpecId,
          )
        })
      }
      checkAuthNavigate(`/events/${eventAlias ?? ''}/checkout`)
    } else {
      setIsError(true)
      setMessageError(TICKET_NOT_AVAILABLE)
      setTimeout(setIsError, 3000, false)
    }
  }

  useEffect(() => {
    setTicketQuantity(0)
    // setTickets(tickets)
  }, [])

  useEffect(() => {
    const hasSelected = tickets.some((t) => t.quantitySelected > 0)
    setHasTicketSelected(hasSelected)
  }, [tickets])

  return (
    <EventBase.Root>
      <motion.div
        className="w-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2, ease: 'easeOut' }}
      >
        {isLoading ? (
          <div className="flex w-full flex-col items-center justify-center gap-2 p-4">
            <div className="h-[100px] w-full animate-pulse rounded-md bg-dark-black" />
            <div className="h-[100px] w-full animate-pulse rounded-md bg-dark-black" />
            <div className="h-[100px] w-full animate-pulse rounded-md bg-dark-black" />
          </div>
        ) : (
          <div className="flex w-full flex-col gap-2 p-4 pb-6">
            {tickets?.map((ticket) => {
              const title = isTruthy(ticket.batchDescription)
                ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
                : ticket.ticketSpecDescription
              return (
                <TicketSelector
                  key={String(ticket.ticketSpecId) + ticket.promo}
                  title={title}
                  price={ticket.price}
                  promo={ticket.promo}
                  hasFee={ticket.hasFee}
                  quantitySelected={ticket.quantitySelected}
                  finalPrice={ticket.finalPrice}
                  variant="dark"
                  addTicket={() => {
                    if (!canSelectTickets) return
                    addTicket(ticket)
                  }}
                  removeTicket={() => {
                    if (!canSelectTickets) return
                    removeTicket(ticket)
                  }}
                  canAddTicket={() => {
                    return canAdd(ticket)
                  }}
                  canRemoveTicket={() => {
                    return canRemove(ticket)
                  }}
                  canFastSelect={false}
                  extraDescription={ticket.extraDescription}
                />
              )
            })}
            {tickets.length === 0 && (
              <span className="mt-4 w-full text-center text-sm text-white">
                Nenhum ingresso disponível no momento.
              </span>
            )}
          </div>
        )}
      </motion.div>
      <div className="fixed bottom-[88px] right-0 z-10 w-full">
        <EventBase.AddPromo promo={promo} setPromo={setPromo} />
      </div>
      <EventBase.Footer
        title={footerTitle}
        value={footerValue}
        subtitle={footerSubtitle}
        buttonText={footerButtonText}
        buttonEnabled={footerButtonEnabled}
        buttonOnClick={() => {
          void handleClick()
        }}
        isLoading={isLoadingCheck}
        eventFinished={isPastEvent}
        error={<SnackBar showSnackBar={isError} message={messageError} />}
      />
    </EventBase.Root>
  )
}

export default EventTickets
