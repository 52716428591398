import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import {
  type ITicketData,
  type ITicketStore,
} from '@/types/hooks/api/useTickets'

import { isTruthy } from '@/helpers/validation'

interface UseTicketProps {
  tickets: ITicketStore[]
  setTickets: (tickets: ITicketData[]) => void
  resetTickets: () => void
  canAdd: (ticket: ITicketStore) => boolean
  canRemove: (ticket: ITicketStore) => boolean
  addTicket: (ticketToAdd: ITicketStore) => void
  removeTicket: (ticketToRemove: ITicketStore) => void
  getTicket: (ticketSpecId: number) => ITicketStore
  getTotal: () => number
  totalHasFee: () => boolean
  getFees: () => Array<{
    type: string
    absolute: number
    percentage: number
  }>
}

const ticketsStore = create<UseTicketProps>()(
  devtools(
    persist(
      (set, get) => ({
        tickets: [],
        setTickets: (tickets: ITicketData[]) => {
          const tempTickets = tickets?.map((ticket) => {
            const hasFee = ticket?.paymentMethods?.some(
              (payment) => payment.fee > 0,
            )

            let finalPrice = ticket.price

            if (isTruthy(ticket.promo) && ticket.price > 0) {
              if (isTruthy(ticket.discountAmount)) {
                finalPrice = ticket.price - ticket.discountAmount
              }
              if (isTruthy(ticket.discountPercentage)) {
                finalPrice = ticket.price * (1 - ticket.discountPercentage)
              }
            }

            return {
              ...ticket,
              hasFee,
              quantitySelected: 0,
              finalPrice,
            }
          })
          const sortedTempTickets = tempTickets.sort((ticket) => {
            if (isTruthy(ticket.promo)) return -1
            return 1
          })

          set({
            tickets: sortedTempTickets,
          })
        },
        resetTickets: () => {
          set({ tickets: [] })
        },
        canAdd: (ticket: ITicketStore) => {
          if (ticket.limit === 0) return false
          if (isTruthy(ticket.limit))
            return ticket.quantitySelected < ticket.limit
          return true
        },
        canRemove: (ticket: ITicketStore) => {
          return ticket.quantitySelected > 0
        },
        addTicket: (ticketToAdd: ITicketStore) => {
          set((state) => ({
            tickets: state.tickets.map((ticket) =>
              ticket.ticketSpecId === ticketToAdd.ticketSpecId &&
              ticket.promo === ticketToAdd.promo
                ? { ...ticket, quantitySelected: ticket.quantitySelected + 1 }
                : ticket,
            ),
          }))
        },
        removeTicket: (ticketToRemove: ITicketStore) => {
          set((state) => ({
            tickets: state.tickets.map((ticket) =>
              ticket.ticketSpecId === ticketToRemove.ticketSpecId &&
              ticket.promo === ticketToRemove.promo
                ? { ...ticket, quantitySelected: ticket.quantitySelected - 1 }
                : ticket,
            ),
          }))
        },
        getTicket: (ticketSpecId: number) => {
          const tickets = get().tickets
          return tickets.find(
            (ticket) => ticket.ticketSpecId === ticketSpecId,
          ) as ITicketStore
        },
        getTotal: () => {
          const tickets = get().tickets
          return tickets.reduce((acc, ticket) => {
            return acc + ticket.finalPrice * ticket.quantitySelected
          }, 0)
        },
        totalHasFee: () => {
          const tickets = get().tickets
          return tickets.some(
            (ticket) =>
              ticket.hasFee &&
              ticket.quantitySelected > 0 &&
              ticket.finalPrice > 0,
          )
        },
        getFees: () => {
          const tickets = get().tickets

          const absPixFee = tickets.reduce((acc, ticket) => {
            const pixFee =
              ticket.paymentMethods.find((payment) => payment.type === 'PIX')
                ?.fee ?? 0
            return acc + ticket.finalPrice * pixFee * ticket.quantitySelected
          }, 0)

          const absCardFee = tickets.reduce((acc, ticket) => {
            const creditCardFee =
              ticket.paymentMethods.find(
                (payment) => payment.type === 'CREDITCARD',
              )?.fee ?? 0
            return (
              acc + ticket.finalPrice * creditCardFee * ticket.quantitySelected
            )
          }, 0)

          const absIntlCardFee = tickets.reduce((acc, ticket) => {
            const creditCardFee =
              ticket.paymentMethods.find(
                (payment) => payment.type === 'INTL_CREDITCARD',
              )?.fee ?? 0
            return (
              acc + ticket.finalPrice * creditCardFee * ticket.quantitySelected
            )
          }, 0)

          const total = get().getTotal()

          const fees = [
            {
              type: 'PIX',
              absolute: absPixFee,
              percentage: Math.round((absPixFee / total) * 1000) / 10,
            },
            {
              type: 'BOLETO',
              absolute: 0,
              percentage: 0,
            },
            {
              type: 'CREDITCARD',
              absolute: absCardFee,
              percentage: Math.round((absCardFee / total) * 1000) / 10,
            },
            {
              type: 'INTL_CREDITCARD',
              absolute: absIntlCardFee,
              percentage: Math.round((absIntlCardFee / total) * 1000) / 10,
            },
          ]
          return fees
        },
      }),
      {
        name: 'ticket-storage',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
)

export default ticketsStore
