import { type ReactElement } from 'react'

import bannerHome from '@/assets/banner-home.gif'

import { SlArrowRight } from 'react-icons/sl'

interface EventCreateEventProps {
  title: string
  subtitle: string
}

export default function EventCreateEvent({
  title,
  subtitle,
}: EventCreateEventProps): ReactElement {
  return (
    <a
      href={`${import.meta.env.VITE_ATLAS_URL as string}`}
      className="relative flex w-full items-center justify-between overflow-hidden rounded-md"
    >
      <img
        src={bannerHome}
        alt="Banner de crie seu evento"
        className="-z-1 absolute w-full rounded-md brightness-50"
      />

      <div className="z-10 flex w-full items-center justify-between rounded-md p-4 backdrop-blur-sm">
        <div className="z-10 flex flex-col">
          <span className="text-lg font-black text-white">{title}</span>
          <span className="text-xs font-medium text-white">{subtitle}</span>
        </div>
        <SlArrowRight size={20} color="white" className="z-10" />
      </div>
    </a>
  )
}
