import { useState, useEffect } from 'react'
import useSWR from 'swr'
import { api, fetcher } from '@/services/axios'
import { type AxiosError } from 'axios'

import {
  type ILinkAnalytics,
  type ILinkUser,
  type ILinkUsersReturn,
} from '@/types/hooks/api/useLinks'

import { isTruthy } from '@/helpers/validation'

export const useGetLinkAnalytics = (
  linkHash?: string,
): {
  linkAnalytics: ILinkAnalytics | undefined
  isLoading: boolean
  refresh: () => void
  error?: string
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = linkHash !== undefined
  const { data, error, mutate } = useSWR<ILinkAnalytics, string>(
    shouldFetch && `/links/${linkHash}/analytics`,

    fetcher,
  )

  function refresh(): void {
    void mutate()
  }

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return {
    linkAnalytics: data,
    isLoading,
    refresh,
    error,
  }
}

export const useGetLinkUsers = (): {
  getUsers: (
    linkHash: string,
    page: number,
    searchTerm?: string,
  ) => Promise<ILinkUsersReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function getUsers(
    linkHash?: string,
    page = 0,
    searchTerm?: string,
  ): Promise<ILinkUsersReturn> {
    const take = 50
    const skip = 50 * page
    setIsLoading(true)
    const shouldFetch = linkHash !== undefined && page !== undefined
    if (!shouldFetch) {
      setIsLoading(false)
      return {
        status: 400,
      }
    }

    const url = isTruthy(searchTerm)
      ? `/links/${linkHash}/buyers?skip=${skip}&take=${take}&searchTerm=${
          searchTerm ?? ''
        }`
      : `/links/${linkHash}/buyers?skip=${skip}&take=${take}`

    try {
      const response = await api.get<ILinkUser[]>(url)
      setIsLoading(false)
      return {
        status: 200,
        users: response.data,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { getUsers, isLoading }
}
