import { type ElementType, type ReactElement } from 'react'
import { tv } from 'tailwind-variants'

interface SelectorProps {
  icon?: ElementType
  title: string
  subtitle?: string
  isSelected: boolean
  onClick: () => void
  variant: 'dark' | 'light'
}

const variants = tv({
  base: 'relative flex px-4 border-2 py-3 rounded-lg gap-4 items-center justify-between hover:cursor-pointer transition-color duration-200 ease-in-out',
  variants: {
    border: {
      dark: 'border-background-main bg-background-main',
      darkSelected: 'border-primary-main bg-background-main',
      light: 'border-[#f4f4f4] bg-[#f4f4f4]',
      lightSelected: 'border-black bg-[#f4f4f4]',
    },
  },
})

const selectorDivVariants = tv({
  base: 'relative flex size-5 items-center justify-center rounded-full border-2',
  variants: {
    background: {
      dark: 'bg-background-main',
      darkSelected: 'bg-background-main',
      light: 'border-[#d9d9d9] bg-[#f4f4f4]',
      lightSelected: 'border-[#a3a3a3] bg-[#f4f4f4] ',
    },
  },
})

const selectorVariants = tv({
  base: 'absolute left-1/2 top-1/2 size-3 -translate-x-1/2 -translate-y-1/2 rounded-full',
  variants: {
    background: {
      dark: 'bg-background-main',
      darkSelected: 'bg-primary-main',
      light: 'bg-[#f4f4f4] border-[#f4f4f4]',
      lightSelected: 'bg-black border-[#f4f4f4]',
    },
  },
})

const textVariants = tv({
  base: '',
  variants: {
    type: {
      title: 'text-sm font-bold ',
      subtitle: 'text-xs ',
    },
    color: {
      dark: 'text-white',
      darkSecondary: 'text-text-secondary',
      light: 'text-[#181818]',
      lightSecondary: '#232323',
    },
  },
})

function Selector({
  icon: Icon,
  title,
  subtitle,
  isSelected,
  onClick,
  variant,
}: SelectorProps): ReactElement {
  const variantSelected =
    variant === 'dark'
      ? isSelected
        ? 'darkSelected'
        : 'dark'
      : isSelected
        ? 'lightSelected'
        : 'light'

  const titleVariant = variant
  const subtitleVariant =
    titleVariant === 'dark' ? 'darkSecondary' : 'lightSecondary'

  return (
    <div className={variants({ border: variantSelected })} onClick={onClick}>
      {Icon !== undefined && <Icon color="white" size={16} />}
      <div className="flex w-4/5 flex-col">
        <span className={textVariants({ type: 'title', color: titleVariant })}>
          {title}
        </span>
        <span
          className={textVariants({ type: 'subtitle', color: subtitleVariant })}
        >
          {subtitle}
        </span>
      </div>
      <div className={selectorDivVariants({ background: variantSelected })}>
        <div className={selectorVariants({ background: variantSelected })} />
      </div>
    </div>
  )
}

export default Selector
