import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { type AxiosResponse } from 'axios'

import fetcher, { api } from '@/services/axios'

import {
  type HomeData,
  type SectionData,
  type IFetchOtherEvents,
} from '@/types/hooks/api/useHomeEvents'

export const useGetHomeEvents = (): {
  homeEventsData: HomeData | undefined
  error: string | undefined
  isLoading: boolean
  fetchOtherEvents: (excludedIds: number[]) => Promise<IFetchOtherEvents>
} => {
  const [isLoading, setIsLoading] = useState(true)
  const { data, error } = useSWR<HomeData, string>(
    '/events/front-page',
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  const homeEventsData: HomeData | undefined = data ?? undefined

  async function fetchOtherEvents(
    excludeIds: number[],
  ): Promise<IFetchOtherEvents> {
    const take = 10

    try {
      const response = await api.post<SectionData[]>(
        `/events/list?take=${take}`,
        { excludeIds },
      )
      return {
        status: 200,
        otherEvents: response.data,
      }
    } catch (err) {
      const error = err as AxiosResponse<string>
      return {
        status: error.status,
        error: error.data,
      }
    }
  }

  return { homeEventsData, error, isLoading, fetchOtherEvents }
}
