import { useEffect, useState, type ReactElement } from 'react'

import { ActiveFollowRequest } from '@/components/Notifications/ActiveFollowRequest/ActiveFollowRequest'
import { NewFollowerNotification } from '@/components/Notifications/NewFollowerNotification/NewFollowerNotification'
import { AcceptedFollowRequestNotification } from '@/components/Notifications/AcceptedFollowRequestNotification/AcceptedFollowRequestNotification'

import { type INotification } from '@/types/hooks/api/useNotifications'

import logo from '@/assets/images/logo.png'

import { aggregateNotificationsByDate } from '../helpers/aggregate'
import { formatDateRelativeToToday } from '@/helpers/formatDate'

function SpecificNotification({
  notification,
  hideNotification,
}: {
  notification: INotification
  hideNotification: (id: number) => void
}): ReactElement {
  if (notification.type === 'FOLLOW_REQUEST_RECEIVED') {
    return (
      <ActiveFollowRequest
        hideNotification={hideNotification}
        notification={notification}
      />
    )
  }

  if (notification.type === 'NEW_FOLLOWERS') {
    return <NewFollowerNotification notification={notification} />
  }

  if (
    notification.type === 'FOLLOW_REQUEST_ACCEPTED' &&
    notification.payload.followRequest.status === 'FOLLOWING'
  ) {
    return (
      <AcceptedFollowRequestNotification
        hideNotification={hideNotification}
        notification={notification}
      />
    )
  }

  return <></>
}

export function NotificationHistory({
  notificationHistoryData,
}: {
  notificationHistoryData: INotification[]
}): ReactElement {
  const [aggregatedNotifications, setAggregatedNotifications] = useState(
    aggregateNotificationsByDate(notificationHistoryData),
  )

  useEffect(() => {
    setAggregatedNotifications(
      aggregateNotificationsByDate(notificationHistoryData),
    )
  }, [notificationHistoryData])

  const hideNotification = (id: number): void => {
    setAggregatedNotifications((prevNotifications) =>
      prevNotifications.map((group) => ({
        ...group,
        notifications: group.notifications.map((item) =>
          item.notification.payload.id === id ? { ...item, hide: true } : item,
        ),
      })),
    )
  }

  return (
    <>
      {notificationHistoryData?.length === 0 ? (
        <div className="flex items-center gap-4">
          <img
            src={logo}
            alt="Logo Gandaya"
            className="size-12 rounded-full "
          />
          <span className="text-sm font-bold text-text-secondary">
            Nada de novo por aqui...
          </span>
        </div>
      ) : (
        <div className="flex flex-col gap-3">
          {aggregatedNotifications
            ?.filter((agg) =>
              agg.notifications.some((notification) => !notification.hide),
            )
            .map((agg) => (
              <div key={agg.date}>
                <h2 className="font-semibold text-[#656565]">
                  {formatDateRelativeToToday(agg.date)}
                </h2>
                <div className="my-2 flex flex-col gap-4">
                  {agg.notifications
                    .filter((notification) => !notification.hide)
                    .map((notification) => (
                      <SpecificNotification
                        key={notification.notification.payload.id}
                        hideNotification={hideNotification}
                        notification={notification.notification}
                      />
                    ))}
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  )
}
