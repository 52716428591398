import { type ReactElement, useState } from 'react'
import { motion } from 'framer-motion'

import Login from '@/components/LoginSignup/Login/Login'
import TwoFactor from '@/components/LoginSignup/TwoFactor/TwoFactor'
import Signup from '@/components/LoginSignup/Signup/Signup'
import ProfilePicture from '@/components/LoginSignup/ProfilePicture/ProfilePicture'
import CommunicationMethod from '@/components/LoginSignup/CommunicationMethod/CommunicationMethod'

interface LoginSignupProps {
  setIsError: (isError: boolean) => void
  setErrorMessage: (errorMessage: string) => void
}

function LoginSignup({
  setIsError,
  setErrorMessage,
}: LoginSignupProps): ReactElement {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [communicationMethod, setCommunicationMethod] = useState<
    'SMS' | 'WHATSAPP'
  >('WHATSAPP')

  const [phoneNumberValidationKey, setPhoneNumberValidationKey] = useState('')

  const [state, setState] = useState<
    | 'login'
    | 'twoFactorPhone'
    | 'signup'
    | 'profilePicture'
    | 'communicationMethod'
  >('login')

  return (
    <div className="size-full">
      {state === 'login' ? (
        <motion.div
          key="login"
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'anticipate' }}
          className="flex size-full flex-col items-center"
        >
          <Login
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            setState={setState}
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
          />
        </motion.div>
      ) : state === 'communicationMethod' ? (
        <motion.div
          key="communicationMethod"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'anticipate' }}
          className="flex size-full flex-col items-center"
        >
          <CommunicationMethod
            phoneNumber={phoneNumber}
            setState={setState}
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
            setCommunicationMethod={setCommunicationMethod}
          />
        </motion.div>
      ) : state === 'twoFactorPhone' ? (
        <motion.div
          key="twoFactorPhone"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'anticipate' }}
          className="flex size-full flex-col items-center"
        >
          <TwoFactor
            phoneNumber={phoneNumber}
            setState={setState}
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
            setPhoneNumberValidationKey={setPhoneNumberValidationKey}
            variant="phone"
            communicationMethod={communicationMethod}
          />
        </motion.div>
      ) : state === 'signup' ? (
        <motion.div
          key="signUp"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'anticipate' }}
          className="flex size-full flex-col items-center"
        >
          <Signup
            phoneNumber={phoneNumber}
            phoneNumberValidationKey={phoneNumberValidationKey}
            setState={setState}
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
          />
        </motion.div>
      ) : (
        <motion.div
          key="profilePicture"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'anticipate' }}
          className="flex size-full flex-col items-center"
        >
          <ProfilePicture />
        </motion.div>
      )}
    </div>
  )
}

export default LoginSignup
