import { type ReactElement, useRef, useState } from 'react'

import Modal from '@/components/Global/Modal/Modal'
import TicketSummary from '@/components/Global/TicketSummary/TicketSummary'
import Button from '@/components/Global/Button/Button'
import EventThumbnail from '@/components/Event/EventThumbnail/EventThumbnail'

import { BsCheckLg } from 'react-icons/bs'
import { FiLink } from 'react-icons/fi'
import { LuCopy } from 'react-icons/lu'

import { numberToReais } from '@/helpers/formatNumber'

import { CANCEL_ORDER_ERROR } from '@/errors'

import {
  useGetOrderDetailsByOrderCode,
  usePutCancelOrder,
} from '@/hooks/api/useOrder'

import { datesToFormat } from '@/helpers/formatDate'
import { isTruthy } from '@/helpers/validation'

interface OrderDetailsModalProps {
  orderCode: string
  closeModal: () => void
  refreshWallet: () => void
}

function OrderDetailsModal({
  orderCode,
  closeModal,
  refreshWallet,
}: OrderDetailsModalProps): ReactElement {
  const [linkCopied, setLinkCopied] = useState(false)
  const [cancelOrder, setCancelOrder] = useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const linkRef = useRef<HTMLSpanElement>(null)

  const { orderDetailsData } = useGetOrderDetailsByOrderCode(orderCode)
  const { putCancelOrder, isLoading } = usePutCancelOrder()

  async function copyLinkToClipboard(): Promise<void> {
    if (linkRef.current !== null) {
      setLinkCopied(true)
      setTimeout(setLinkCopied, 3000, false)
      const textToCopy = linkRef.current.textContent
      await navigator.clipboard.writeText(
        `Para comprar meu ingresso da *${
          orderDetailsData?.eventDetails.title ?? ''
        }* de forma segura, acesse meu link de compra:\n\n${
          textToCopy ?? ''
        }\n\n💃🪩🍹🕺`,
      )
    }
  }

  async function handleCancelOrder(): Promise<void> {
    const response = await putCancelOrder(orderDetailsData?.id ?? 0)

    if (response.status === 200) {
      setTimeout(setIsParentClosing, 100, true)
      refreshWallet()
    } else {
      setIsError(true)
      setErrorMessage(CANCEL_ORDER_ERROR)
      setTimeout(setIsError, 3000, false)
    }
  }

  if (orderDetailsData === undefined) return <></>
  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      showSnackBar={isError}
      snackBarMessage={errorMessage}
    >
      <div className="flex size-full flex-col gap-1 p-4">
        <div className="mb-4 flex h-2 w-full justify-center">
          <div className="h-full w-1/3 rounded-full bg-[#D9D9D9]" />
        </div>
        {!cancelOrder ? (
          <>
            <EventThumbnail
              imageKey={orderDetailsData?.eventDetails.backgroundImageKey}
              title={orderDetailsData?.eventDetails.title}
              date={datesToFormat(
                orderDetailsData?.eventDetails.date,
                orderDetailsData?.eventDetails.endDate,
              )}
              location={orderDetailsData?.eventDetails.location}
              highlighted={false}
              briefVariant="dark"
              imageVariant="large"
              eventAlias={orderDetailsData?.eventDetails.eventAlias}
            />
            <div className="my-4 flex max-h-48 flex-col gap-2 overflow-y-scroll">
              {orderDetailsData.tickets.map((ticket, index) => {
                const title = isTruthy(ticket.batchDescription)
                  ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
                  : ticket.ticketSpecDescription
                return (
                  <TicketSummary
                    key={index}
                    title={title}
                    quantity={1}
                    subtitle={'Valor: ' + numberToReais(ticket.paidAmount, 2)}
                    variant="dark"
                    counterVariant="dark"
                  />
                )
              })}
            </div>

            <div className="flex w-full max-w-full items-center justify-between gap-2 overflow-hidden rounded-lg bg-dark-light-gray px-4 py-3">
              <div className="flex w-4/5 flex-col">
                <div className="flex items-center gap-2">
                  <FiLink size={16} color="black" />
                  <span className="font-bold text-text-dark ">
                    Link para venda
                  </span>
                </div>
                <span
                  ref={linkRef}
                  className="my-1 w-full truncate text-xs text-dark-dark-gray"
                >
                  {`${
                    import.meta.env.VITE_KAYA_URL as string
                  }/marketplace/${orderCode}`}
                </span>
              </div>
              <div
                className={
                  'transition-color flex h-8 w-8 items-center justify-center rounded-full duration-200 ease-in-out hover:cursor-pointer' +
                  (linkCopied ? ' bg-dark-dark-gray' : ' bg-dark-white')
                }
                onClick={() => {
                  void copyLinkToClipboard()
                }}
              >
                {linkCopied ? (
                  <BsCheckLg size={18} color="white" />
                ) : (
                  <LuCopy size={18} color="bg-background-main" />
                )}
              </div>
            </div>

            <div className="mb-2 mt-6 flex">
              <div className="flex w-1/2 flex-col gap-1">
                <span className="text-xs leading-3 text-text-secondary">
                  Quanto receberá pela venda
                </span>
                <span className="text-2xl font-bold leading-7">
                  {numberToReais(orderDetailsData.price, 2)}
                </span>
              </div>
              <div className="flex w-1/2 flex-col gap-1">
                <span className="text-xs leading-3 text-text-secondary">
                  Valor cobrado do comprador
                </span>
                <span className="text-2xl leading-7">
                  {numberToReais(
                    orderDetailsData.price *
                      (1 + orderDetailsData.paymentMethods[0].fee),
                    2,
                  )}
                </span>
              </div>
            </div>

            <div className="mt-4 flex h-28 w-full flex-col gap-2">
              <Button
                text={linkCopied ? 'Link copiado' : 'Copiar link'}
                enabled={!linkCopied}
                onClick={() => {
                  void copyLinkToClipboard()
                  setLinkCopied(true)
                }}
                className="text-md"
              />
              <Button
                text="Cancelar venda"
                enabled
                onClick={() => {
                  setCancelOrder(true)
                }}
                className="text-md bg-dark-light-gray"
              />
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-6">
            <h1 className="mt-2 text-2xl">Confirmar cancelamento</h1>
            <div className="flex flex-col gap-4 rounded-xl bg-dark-light-gray p-4">
              <EventThumbnail
                imageKey={orderDetailsData?.eventDetails.backgroundImageKey}
                title={orderDetailsData?.eventDetails.title}
                date={datesToFormat(
                  orderDetailsData?.eventDetails.date,
                  orderDetailsData?.eventDetails.endDate,
                )}
                location={orderDetailsData?.eventDetails.location}
                highlighted={false}
                briefVariant="dark"
                imageVariant="medium"
              />
              <div className="flex max-h-48 flex-col gap-2 overflow-y-scroll">
                {orderDetailsData.tickets.map((ticket, index) => {
                  return (
                    <TicketSummary
                      key={index}
                      quantity={1}
                      title={
                        isTruthy(ticket.batchDescription)
                          ? `${ticket.batchDescription} - ${ticket?.ticketSpecDescription}`
                          : `${ticket?.ticketSpecDescription}`
                      }
                      subtitle={
                        'Valor: ' + numberToReais(ticket?.paidAmount, 2)
                      }
                      variant="dark"
                      counterVariant="dark"
                    />
                  )
                })}
              </div>
            </div>
            <span className="text-center text-sm leading-4">
              O cancelamento do link de venda de ingresso automaticamente
              elimina a possibilidade de qualquer pessoa comprar com o link já
              compartilhado.
            </span>
            <div className="flex h-28 w-full flex-col gap-2">
              <Button
                text="Confirmar"
                enabled={!isLoading}
                isLoading={isLoading}
                onClick={() => {
                  void handleCancelOrder()
                }}
                className={
                  'text-md text-white' +
                  (isLoading ? ' bg-[#C14343]' : ' bg-tonal-red')
                }
              />
              <Button
                text="Voltar"
                enabled
                onClick={() => {
                  setCancelOrder(false)
                }}
                className="text-md bg-dark-light-gray"
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default OrderDetailsModal
