import { type ReactElement } from 'react'

interface EntityCardProps {
  title: string
  imageKey: string
  entityName: string
}

function EntityCard({
  title,
  imageKey,
  entityName,
}: EntityCardProps): ReactElement {
  return (
    <div className="flex w-full flex-col gap-2 rounded-lg bg-dark-black px-4 pt-4">
      <div className="flex gap-4 rounded-lg border border-primary-main p-2">
        <img
          src={`${import.meta.env.VITE_S3 as string}/${imageKey}`}
          alt="Organizer image"
          className="size-12 rounded-md"
        />
        <div className="flex flex-col justify-center">
          <span className="font-regular text-sm leading-5 text-white">
            {title}
          </span>
          <span className="text-lg font-bold leading-5 text-white">
            {entityName}
          </span>
        </div>
      </div>
    </div>
  )
}

export default EntityCard
