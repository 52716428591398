import { useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import Modal from '@/components/Global/Modal/Modal'
import Button from '@/components/Global/Button/Button'
import TicketSummary from '@/components/Global/TicketSummary/TicketSummary'
import TransferAnimation from '../TransferAnimation/TransferAnimation'
import EventThumbnail from '@/components/Event/EventThumbnail/EventThumbnail'
import List from '@/components/Global/List/List'

import transferStore from '@/store/transfer'
import walletStore from '@/store/wallet'

import { usePostTransfer } from '@/hooks/api/useTransfer'

import { BsCheckLg, BsXLg } from 'react-icons/bs'

import { numberToReais } from '@/helpers/formatNumber'
import { datesToFormat } from '@/helpers/formatDate'
import { isTruthy } from '@/helpers/validation'

function ConfirmTransferModal(): ReactElement {
  const [afterTransferPage, setAfterTransferPage] = useState<
    'success' | 'error'
  >()
  const [isModalClosing, setIsModalClosing] = useState(false)

  const navigate = useNavigate()
  const {
    transferTickets,
    transferTicketsEvent,
    receiver,
    transferModalState,
    setTransferState,
    setTransferModalState,
  } = transferStore()
  const { refresh } = walletStore()

  const { imageKey, title, startDate, location, endDate } = transferTicketsEvent

  const { postTransfer } = usePostTransfer()

  async function handleTransfer(): Promise<void> {
    setTransferModalState('isTransfering')
    const selectedTickets = transferTickets
      .filter((ticket) => ticket.isSelected)
      .map((ticket) => ticket.id)
    const response = await postTransfer(receiver.id, selectedTickets)
    if (response.status) {
      setAfterTransferPage('success')
    } else {
      setAfterTransferPage('error')
    }
  }

  return (
    <Modal disableTouchToClose isParentClosing={isModalClosing}>
      <div className="w-full p-4">
        <div className="mb-8 flex h-2 w-full justify-center">
          <div className="h-full w-1/3 rounded-full bg-[#D9D9D9]" />
        </div>
        {transferModalState === 'preTransfer' && (
          <h1 className="mb-8 text-2xl">Confirme os dados</h1>
        )}
        {transferModalState === 'posTransferSuccess' && (
          <div className="mb-10 flex flex-col items-center">
            <BsCheckLg className="size-14 rounded-full bg-primary-main p-3" />
            <h1 className="mt-2 text-2xl">Transferência realizada</h1>
          </div>
        )}
        {transferModalState === 'posTransferError' && (
          <div className="mb-10 flex flex-col items-center">
            <BsXLg
              className="size-14 rounded-full bg-tonal-red p-3"
              color="white"
            />
            <h1 className="mt-2 text-2xl">Erro ao realizar transferência</h1>
          </div>
        )}
        <div className="mb-4 flex items-center gap-2">
          <img
            src={`${import.meta.env.VITE_S3 as string}/${receiver?.imageKey}`}
            alt="Foto perfil"
            className="size-12 rounded-full"
          />
          <div className="flex grow flex-col">
            <span className="font-regular font-bold leading-4 text-text-dark">
              {receiver?.firstName} {receiver?.lastName}
            </span>
            <span className="text-sm text-dark-black">
              @{receiver?.username}
            </span>
          </div>
        </div>

        <div className=" flex flex-col gap-4 rounded-xl bg-dark-light-gray p-4">
          <EventThumbnail
            imageKey={imageKey}
            title={title}
            date={datesToFormat(startDate, endDate)}
            location={location}
            briefVariant="dark"
            highlighted={false}
          />
          <List hasFade fromFade="from-[#F4F4F4]" toFade="to-[#F4F4F4]">
            {transferTickets
              ?.filter((ticket) => ticket.isSelected)
              .map((ticket, index) => {
                const title = isTruthy(ticket.batchDescription)
                  ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
                  : ticket.ticketSpecDescription
                return (
                  <TicketSummary
                    key={index}
                    quantity={1}
                    title={title}
                    subtitle={'Valor: ' + numberToReais(ticket?.price, 2)}
                    variant="dark"
                    counterVariant="dark"
                  />
                )
              })}
          </List>
        </div>
        {transferModalState === 'preTransfer' && (
          <>
            <div className="my-4 w-full px-8 text-center text-xs leading-4 text-dark-dark-gray">
              Não é possível reverter uma transferência depois que ela é
              realizada. O ingresso será posse de{' '}
              <strong>
                {receiver?.firstName} {receiver?.lastName}
              </strong>
              .
            </div>
            <div className="flex flex-col gap-2">
              <Button
                text="Confirmar transferência"
                enabled
                onClick={() => {
                  void handleTransfer()
                }}
                className="h-12 bg-tonal-red text-white"
              />
              <Button
                text="Voltar"
                enabled
                onClick={() => {
                  setIsModalClosing(true)
                  setTimeout(setTransferState, 300, 'selectTicket')
                }}
                className="h-12 bg-dark-light-gray "
              />
            </div>
          </>
        )}
        {transferModalState === 'isTransfering' && (
          <TransferAnimation
            afterTransferPage={afterTransferPage}
            onEnd={setTransferModalState}
            username={`${receiver.username}`}
          />
        )}
        {(transferModalState === 'posTransferSuccess' ||
          transferModalState === 'posTransferError') && (
          <div className="mt-8 flex w-full flex-col gap-2">
            {transferModalState === 'posTransferError' && (
              <Button
                enabled={true}
                onClick={() => {
                  setTransferModalState('preTransfer')
                }}
                className="h-12 bg-dark-light-gray text-dark-black"
                text="Tentar novamente"
              />
            )}
            <Button
              enabled={true}
              onClick={async () => {
                setIsModalClosing(true)
                if (refresh !== undefined) await refresh()
                setTimeout(navigate, 300, '/wallet')
              }}
              className="h-12 bg-primary-main text-dark-black"
              text="Abrir minha carteira"
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ConfirmTransferModal
