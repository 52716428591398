import { useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import userStore from '@/store/user'

import Modal from '@/components/Global/Modal/Modal'
import Selector from '@/components/Global/Selector/Selector'
import Button from '@/components/Global/Button/Button'

import { useLogout } from '@/hooks/api/useLogout'

interface OptionsModalProps {
  closeModal: () => void
  isParentClosing?: boolean
  openSupportModal: () => void
}

export default function OptionsModal({
  closeModal,
  isParentClosing,
  openSupportModal,
}: OptionsModalProps): ReactElement {
  const [optionSelected, setOptionSelected] = useState<
    '' | 'support' | 'logout'
  >('')
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { logout, isLoading } = useLogout()

  const navigate = useNavigate()

  const { logoutUser } = userStore()

  async function handleLogout(): Promise<void> {
    const response = await logout()
    if (response.status === 200) {
      logoutUser()
      closeModal()
      navigate('/')
    } else {
      setIsError(true)
      setErrorMessage('Erro ao fazer logout. Tente novamente.')
      setTimeout(setIsError, 5000, false)
    }
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      variant="dark"
      showSnackBar={isError}
      snackBarMessage={errorMessage}
    >
      <div className="flex w-full flex-col gap-2  p-2">
        <Selector
          title="Suporte"
          subtitle="Entre em contato conosco para criar eventos ou tirar dúvidas."
          variant="dark"
          isSelected={optionSelected === 'support'}
          onClick={() => {
            setOptionSelected('support')
          }}
        />
        <Selector
          title="Logout"
          subtitle="Você será desconectado da sua conta e para acessá-la terá que fazer o login novamente."
          variant="dark"
          isSelected={optionSelected === 'logout'}
          onClick={() => {
            setOptionSelected('logout')
          }}
        />
        <div className="my-2 h-12 w-full">
          <Button
            text="Confirmar"
            enabled={optionSelected !== '' && !isLoading}
            onClick={() => {
              if (optionSelected === 'support') {
                closeModal()
                openSupportModal()
              } else if (optionSelected === 'logout') {
                void handleLogout()
              }
            }}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  )
}
