import { useEffect, type ReactElement } from 'react'

// Styling
import './index.css'

// Routing
import { Route, useLocation, Routes, BrowserRouter } from 'react-router-dom'

// Pages
import Layout from './layouts/Layout'
import Home from './pages/Home'
import Event from './pages/Event/Event'
import LoginSignup from './pages/LoginSignup'
import Wallet from './pages/Wallet/Wallet'
import Marketplace from './pages/Marketplace/Marketplace'
import Memories from './pages/Memories'
import Claim from './pages/Claim'
import Search from './pages/Search'
import PageNotFound from './pages/PageNotFound'
import Notifications from './pages/Notifications'
import Link from './pages/Link'

// Components
import ProtectedRoute from './components/Global/ProtectedRoute/ProtectedRoute'
import IphoneFrameMask from './components/Global/IphoneFrameMask/IphoneFrameMask'
import Organization from './pages/Organization'

// Hooks
import { usePing } from './hooks/api/usePing'
import { useGetFollowRequests } from './hooks/api/useNotifications'

// Store
import userStore from './store/user'
import routingStore from './store/routing'

// Scroll to top on route change
function ScrollToTop(): ReactElement {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return <></>
}

function App(): ReactElement {
  const { loginUser, logoutUser, setUserIsLogged, setFollowRequests } =
    userStore()
  const { setIsPinging } = routingStore()

  const { ping } = usePing()
  const { getFollowRequests } = useGetFollowRequests()

  async function handlePing(): Promise<void> {
    const pong = await ping()

    if (pong.status === 200 && pong.user !== undefined) {
      loginUser(pong.user)
      setUserIsLogged(true)

      const response = await getFollowRequests()
      if (response.status === 200 && response.followRequests !== undefined)
        setFollowRequests(response.followRequests.length)
    } else {
      logoutUser()
      setUserIsLogged(false)
    }
    setIsPinging(false)
  }

  useEffect(() => {
    void handlePing()
  }, [])

  return (
    <IphoneFrameMask>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" index element={<Home />} />
            <Route path="/events/:eventAlias/*" element={<Event />} />
            <Route path="/close-friends/:eventAlias/*" element={<Event />} />
            <Route path="/login" element={<LoginSignup />} />
            <Route path="/search" element={<Search />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/memories/:username" element={<Memories />} />
            <Route path="/marketplace/*" element={<Marketplace />} />
            <Route
              path="/organizations/:organizationAlias"
              element={<Organization />}
            />
            <Route path="/links/:eventAlias/:hash" element={<Link />} />

            <Route element={<ProtectedRoute />}>
              <Route path="/wallet/*" element={<Wallet />} />
              <Route path="/claim/:claimId" element={<Claim />} />
            </Route>

            <Route path="/*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </IphoneFrameMask>
  )
}

export default App
