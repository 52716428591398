import { useEffect, useState, type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import Modal from '@/components/Global/Modal/Modal'
import InterestMenu from './InterestMenu/InterestMenu'
import { InterestViewsGoing } from './InterestViews/InterestViewGoing'
import { InterestViewsInterested } from './InterestViews/InterestViewsInterested'
import { InterestViewsViewed } from './InterestViews/InterestViewsViewed'

import interestStore from '@/store/interest'
import { snackbarStore } from '@/store/snackbar'

import { type IEventInterest } from '@/types/Event'
import { ERROR_TO_LOAD_MORE_USERS } from '@/errors'
import { useGetUsersStatusPaged } from '@/hooks/api/Event'
import { useParams } from 'react-router-dom'
import Button from '@/components/Global/Button/Button'
import { useUserInterest } from '../helpers/useUserInterest'

interface EventInterestModalProps {
  closeModal: () => void
  isParentClosing: boolean
  interestData?: IEventInterest
}

const emojis = ['🧐', '👀', '🪩']

function EventInterestModal({
  closeModal,
  isParentClosing,
  interestData,
}: EventInterestModalProps): ReactElement {
  const [currentView, setCurrentView] = useState<
    'GOING' | 'INTERESTED' | 'VIEWED'
  >('GOING')
  const [pageHeight, setPageHeight] = useState(500)
  const [currentPage, setCurrentPage] = useState(0)
  const [currentEmoji, setCurrentEmoji] = useState(0)

  const { setCloseModal, appendAll, setNoNewUsers, noNewUsers, userStatus } =
    interestStore()
  const { snackbarMessage, showSnackbar, showError } = snackbarStore()

  const { eventAlias } = useParams()

  const { handleUpdateUserInterest } = useUserInterest(eventAlias!)

  const { getUsersStatusPaged, isLoading: isFetchingMoreUsers } =
    useGetUsersStatusPaged(eventAlias!)

  async function handleFetchMoreUsers(): Promise<void> {
    if (isFetchingMoreUsers) return
    const response = await getUsersStatusPaged(currentPage)

    if (response.status !== 200) {
      showError(ERROR_TO_LOAD_MORE_USERS)
      setNoNewUsers({
        GOING: true,
        INTERESTED: true,
        VIEWED: true,
      })
      return
    }
    if (response.usersStatus === undefined) return

    // Update content
    appendAll(response.usersStatus)

    // Stop rendering infinite scroll if no new users
    setNoNewUsers({
      GOING: response.usersStatus.GOING.users.length < 50,
      INTERESTED: response.usersStatus.INTERESTED.users.length < 50,
      VIEWED: response.usersStatus.VIEWED.users.length < 50,
    })
  }

  const cycleEmoji = (): void => {
    setCurrentEmoji((prev) => (prev + 1) % emojis.length) // Cycle through emojis
  }

  // Set up an interval to rotate emojis every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(cycleEmoji, 3000) // 3000ms = 3 seconds

    // Cleanup the interval on component unmount
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    setCloseModal(closeModal)
  }, [])

  // Fetch more users based on page update
  useEffect(() => {
    if (currentPage > 0) {
      void handleFetchMoreUsers()
    }
  }, [currentPage])

  // Initial noUsers check
  useEffect(() => {
    if (interestData === undefined) return
    setNoNewUsers({
      GOING: interestData.GOING.users.length < 50,
      INTERESTED: interestData.INTERESTED.users.length < 50,
      VIEWED: interestData.VIEWED.users.length < 50,
    })
  }, [interestData])

  if (interestData === undefined) return <></>
  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      showSnackBar={showSnackbar}
      snackBarMessage={snackbarMessage}
    >
      <motion.div
        className="relative flex max-h-[528px] min-h-[352px] w-full flex-col items-start gap-4 overflow-hidden"
        animate={{ height: pageHeight }}
        transition={{ duration: 0.1, ease: 'easeInOut' }}
      >
        <AnimatePresence>
          {userStatus !== 'GOING' && userStatus !== 'INTERESTED' && (
            <motion.div
              className="absolute inset-0 z-10 flex size-full flex-col justify-between rounded-t-lg px-6 backdrop-blur-xl"
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex h-full flex-col items-center justify-center">
                <AnimatePresence mode="wait">
                  <motion.h3
                    key={emojis[currentEmoji]} // Ensure each emoji has a unique key
                    className="select-none text-center text-6xl"
                    initial={{ x: '-15%', opacity: 0, scale: 0.5 }} // Slide in from left and start small
                    animate={{ x: '0%', opacity: 1, scale: 1 }} // Grow to full size at the center
                    exit={{ x: '15%', opacity: 0, scale: 0.5 }} // Shrink and slide out to the right
                    transition={{ duration: 0.35 }} // Adjust duration as needed
                  >
                    {emojis[currentEmoji]}
                  </motion.h3>
                </AnimatePresence>
                <div className="pt-8">
                  <p className="mx-auto max-w-64 text-center text-lg font-medium leading-snug">
                    Faça o RSVP do evento para saber quem também vai!
                  </p>
                </div>
              </div>
              <div className="flex gap-2.5 py-6">
                <Button
                  onClick={() => {
                    void handleUpdateUserInterest('GOING')
                  }}
                  className="h-12 w-[30%] font-semibold"
                  enabled
                  text="Vou"
                />
                <Button
                  onClick={() => {
                    void handleUpdateUserInterest('INTERESTED')
                  }}
                  className="h-12 w-[70%] border border-dark-black bg-transparent font-semibold"
                  enabled
                  text="Tenho interesse"
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <div className="flex w-full flex-col gap-4">
          <span className="pl-4 pt-4 text-2xl font-bold">Convidados</span>
          <InterestMenu
            currentView={currentView}
            setCurrentView={setCurrentView}
            data={{
              GOING: interestData.GOING.total,
              INTERESTED: interestData.INTERESTED.total,
              VIEWED: interestData.VIEWED.total,
            }}
          />
        </div>
        <div className="relative flex size-full flex-col items-center px-4">
          {currentView === 'GOING' && (
            <InterestViewsGoing
              setPageHeight={setPageHeight}
              newPage={() => {
                setCurrentPage((current) => current + 1)
              }}
              noNewUsers={noNewUsers.GOING}
            />
          )}
          {currentView === 'INTERESTED' && (
            <InterestViewsInterested
              setPageHeight={setPageHeight}
              newPage={() => {
                setCurrentPage((current) => current + 1)
              }}
              noNewUsers={noNewUsers.INTERESTED}
            />
          )}
          {currentView === 'VIEWED' && (
            <InterestViewsViewed
              setPageHeight={setPageHeight}
              newPage={() => {
                setCurrentPage((current) => current + 1)
              }}
              noNewUsers={noNewUsers.VIEWED}
            />
          )}
        </div>
      </motion.div>
    </Modal>
  )
}

export default EventInterestModal
