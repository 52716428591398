import { type ReactElement, useState } from 'react'

import Modal from '@/components/Global/Modal/Modal'
import LoginSignup from '@/components/LoginSignup/LoginSignup'

interface LoginSignupModalProps {
  isParentClosing: boolean
  closeModal: () => void
}

function LoginSignupModal({
  isParentClosing,
  closeModal,
}: LoginSignupModalProps): ReactElement {
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  return (
    <Modal
      showSnackBar={isError}
      snackBarMessage={errorMessage}
      isParentClosing={isParentClosing}
      closeModal={closeModal}
    >
      <LoginSignup setIsError={setIsError} setErrorMessage={setErrorMessage} />
    </Modal>
  )
}

export default LoginSignupModal
