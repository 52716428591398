import { useState } from 'react'
import useSWR from 'swr'
import fetcher, { api } from '@/services/axios'
import { type AxiosError } from 'axios'

import {
  type IStickersData,
  type IGlueStickerReturn,
} from '@/types/hooks/api/useStickers'
import { type IUserSticker } from '@/types/global/sticker'

export const useGetStickersByUsername = (
  username: string,
): {
  stickersData: IStickersData[] | undefined
  error: string | undefined
  isLoading: boolean
} => {
  const { data, error } = useSWR<IStickersData[], string>(
    `/users/${username}/stickers`,
    fetcher,
  )

  const isLoading = data === undefined || error !== undefined

  const stickersData: IStickersData[] | undefined = data ?? undefined

  return { stickersData, error, isLoading }
}

export const usePutGlueSticker = (): {
  putGlueSticker: (stickerData: IUserSticker) => Promise<IGlueStickerReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function putGlueSticker(
    stickerData: IUserSticker,
  ): Promise<IGlueStickerReturn> {
    setIsLoading(true)

    const { id, rotation, xPos, yPos, visibility } = stickerData

    const requestBody = {
      rotation,
      xPos,
      yPos,
      visibility,
    }

    try {
      await api.put(`/users/stickers/${id}`, requestBody)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { putGlueSticker, isLoading }
}
