import { type ReactElement, type ElementType } from 'react'

import Selector from '@/components/Global/Selector/Selector'

import { numberToReais } from '@/helpers/formatNumber'

import { MdPix } from 'react-icons/md'
import { RiBillLine } from 'react-icons/ri'
import { BsFillCreditCardFill } from 'react-icons/bs'
import { TbWorld } from 'react-icons/tb'

interface PaymentSelectorProps {
  selectedPayment: string
  setSelectedPayment: (
    value: 'PIX' | 'CREDITCARD' | 'BOLETO' | 'INTL_CREDITCARD' | '',
  ) => void
  acceptedPaymentMethods?: {
    PIX: boolean
    CREDITCARD: boolean
    BOLETO: boolean
    INTL_CREDITCARD: boolean
  }
  fees: Array<{
    type: string
    absolute: number
    percentage: number
  }>
}

function PaymentSelector({
  selectedPayment,
  setSelectedPayment,
  acceptedPaymentMethods,
  fees,
}: PaymentSelectorProps): ReactElement {
  function getSubittle(
    type: 'PIX' | 'CREDITCARD' | 'BOLETO' | 'INTL_CREDITCARD' | '',
  ): string {
    const feeAbs = fees.find((fee) => fee.type === type)?.absolute ?? 0
    const feeRel = fees.find((fee) => fee.type === type)?.percentage ?? 0

    if (feeAbs === 0) {
      return 'Sem taxa'
    }
    return `+${numberToReais(feeAbs, 2)} (${feeRel}%)`
  }

  return (
    <>
      {acceptedPaymentMethods !== undefined ? (
        <div className="flex flex-col gap-2">
          {acceptedPaymentMethods.PIX && (
            <Selector
              icon={MdPix as ElementType}
              title="Pix"
              isSelected={selectedPayment === 'PIX'}
              onClick={() => {
                setSelectedPayment('PIX')
              }}
              subtitle={getSubittle('PIX')}
              variant="dark"
            />
          )}
          {acceptedPaymentMethods.BOLETO && (
            <Selector
              icon={RiBillLine as ElementType}
              title="Boleto"
              isSelected={selectedPayment === 'BOLETO'}
              onClick={() => {
                setSelectedPayment('BOLETO')
              }}
              variant="dark"
            />
          )}
          {acceptedPaymentMethods.CREDITCARD && (
            <Selector
              icon={BsFillCreditCardFill as ElementType}
              title="Cartão de Crédito"
              isSelected={selectedPayment === 'CREDITCARD'}
              onClick={() => {
                setSelectedPayment('CREDITCARD')
              }}
              subtitle={getSubittle('CREDITCARD')}
              variant="dark"
            />
          )}
          {acceptedPaymentMethods.INTL_CREDITCARD && (
            <Selector
              icon={TbWorld as ElementType}
              title="International Credit Card"
              isSelected={selectedPayment === 'INTL_CREDITCARD'}
              onClick={() => {
                setSelectedPayment('INTL_CREDITCARD')
              }}
              subtitle={getSubittle('INTL_CREDITCARD')}
              variant="dark"
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default PaymentSelector
