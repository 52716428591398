import { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import { TbShare2 } from 'react-icons/tb'

import eventStore from '@/store/event'

interface EventShareProps {
  eventName: string
}

export default function EventShare({
  eventName,
}: EventShareProps): ReactElement {
  const { setShowLinkCoppiedSnackBar } = eventStore()

  const { eventAlias } = useParams()

  const url = `${import.meta.env.VITE_KAYA_URL as string}/${
    eventAlias as string
  }`

  async function openShareModal(): Promise<void> {
    if (navigator.share !== undefined) {
      try {
        await navigator.share({
          title: `GANDAYA | ${eventName}`,
          url,
        })
      } catch (error) {
        console.error('Error sharing:', error)
      }
    } else {
      try {
        await navigator.clipboard.writeText(url)
        setShowLinkCoppiedSnackBar(true)
        setTimeout(setShowLinkCoppiedSnackBar, 4000, false)
      } catch (error) {
        console.error('Error copying link:', error)
      }
    }
  }

  return (
    <button
      className="absolute bottom-4 right-4 flex size-9 items-center justify-center rounded-full bg-white shadow-md"
      onClick={() => {
        void openShareModal()
      }}
    >
      <TbShare2 size={22} className="mb-0.5" />
    </button>
  )
}
