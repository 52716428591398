import { type ElementType, type ReactElement } from 'react'

import { twMerge } from 'tailwind-merge'

import Button from '@/components/Global/Button/Button'
import SnackBar from '@/components/Global/SnackBar/SnackBar'

import { GoSearch } from 'react-icons/go'

import transferStore from '@/store/transfer'

import { type IGenericUser } from '@/types/global/user'

interface SearchSelectUserProps {
  searchParam: string
  setSearchParam: (searchParam: string) => void
  handleSearch: () => Promise<void>
  users: IGenericUser[]
  isSearching: boolean
  isError: boolean
  errorMessage: string
}

function SearchSelectUser({
  searchParam,
  setSearchParam,
  handleSearch,
  users,
  isSearching,
  isError,
  errorMessage,
}: SearchSelectUserProps): ReactElement {
  const { receiver, setReceiver, setTransferState } = transferStore()

  return (
    <>
      <h1 className="text-regular text-white">Para quem vai transferir?</h1>
      <div className="mt-2 flex justify-between rounded-lg border-2 border-primary-main">
        <div className=" flex w-4/5 flex-col p-2">
          <span className="text-2xs font-bold text-white">
            Username, email ou celular
          </span>
          <input
            type="text"
            className="w-full bg-background-main font-bold text-white focus:border-0 focus:outline-none"
            onChange={(e) => {
              setSearchParam(e.target.value)
            }}
            value={searchParam}
            onKeyDown={(e) => {
              if (e.key === 'Enter') void handleSearch()
            }}
          />
        </div>
        <div className="size-14">
          <Button
            icon={GoSearch as ElementType}
            enabled={!isSearching && searchParam !== ''}
            onClick={() => {
              void handleSearch()
            }}
            className="rounded-lg border-4 border-background-main"
            isLoading={isSearching}
          />
        </div>
      </div>
      <div className="mt-4 flex w-full flex-wrap gap-3">
        {users.length > 0 && (
          <>
            {users.map((user, index) => {
              return (
                <div
                  className="flex max-w-16 flex-col items-center"
                  key={index}
                  onClick={() => {
                    setReceiver(user)
                  }}
                >
                  <img
                    className={twMerge(
                      'h-16 w-16 rounded-full border-2 transition-colors duration-100 ease-in-out',
                      user.id === receiver.id
                        ? 'border-primary-main'
                        : 'border-background-main',
                    )}
                    src={`${import.meta.env.VITE_S3 as string}/${
                      user.imageKey
                    }`}
                    alt={'User'}
                  />
                  <span className="max-w-[64px] truncate text-xs text-white">
                    {user.firstName}
                  </span>
                  <span className="max-w-[64px] truncate text-xs text-white">
                    {user.lastName}
                  </span>
                </div>
              )
            })}
          </>
        )}
      </div>

      <div className="fixed bottom-0 left-0 w-full">
        <SnackBar showSnackBar={isError} message={errorMessage} />
        <div className="w-full bg-dark-black p-4">
          <div className="h-12 w-full">
            <Button
              text="Selecionar ingressos"
              enabled={receiver.id !== 0}
              onClick={() => {
                setTransferState('selectTicket')
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default SearchSelectUser
