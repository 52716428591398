import { useState, type ReactElement } from 'react'

import Modal from '@/components/Global/Modal/Modal'
import Button from '@/components/Global/Button/Button'

import { useDeleteUnfollow } from '@/hooks/api/Social'

import notificationStore from '@/store/notification'
import { twMerge } from 'tailwind-merge'

interface UnfollowModalProps {
  closeModal: () => void
  isParentClosing?: boolean
  triggerRefresh: () => Promise<void>
}

export function UnfollowModal({
  closeModal,
  isParentClosing,
  triggerRefresh,
}: UnfollowModalProps): ReactElement {
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { deleteUnfollow, isLoading } = useDeleteUnfollow()
  const { onUnfollow, user: selectedUser } = notificationStore()

  async function handleUnfollow(): Promise<void> {
    const response = await deleteUnfollow(selectedUser?.id as unknown as number)
    if (response.status === 200) {
      onUnfollow()
      closeModal()
      void triggerRefresh()
    } else {
      setIsError(true)
      setErrorMessage('Erro ao deixar de seguir. Tente novamente.')
      setTimeout(setIsError, 5000, false)
    }
  }

  return (
    <Modal
      disableTouchToClose
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      variant="dark"
      showSnackBar={isError}
      snackBarMessage={errorMessage}
    >
      <div className="flex w-full flex-col items-center gap-6 bg-white p-4">
        <div className="mt-2 flex items-center justify-center gap-2">
          <h1 className="text-center text-2xl font-semibold leading-tight">
            Você quer deixar de seguir{' '}
            <span className="font-black">@{selectedUser?.username}</span>?
          </h1>
        </div>
        <span className="text-center leading-5">
          Para seguir esse perfil novamente, você terá que enviar uma nova
          solicitação.
        </span>
        <div className="flex w-full flex-col gap-2">
          <div className="h-12">
            <Button
              isLoading={isLoading}
              onClick={handleUnfollow}
              className={twMerge('text-white', !isLoading && 'bg-tonal-red')}
              enabled={!isLoading}
              text="Deixar de seguir"
            />
          </div>
          <div className="h-12">
            <Button
              onClick={closeModal}
              enabled={!isLoading}
              text="Cancelar"
              className="bg-dark-light-gray"
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
