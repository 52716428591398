import { create } from 'zustand'

interface eventProps {
  ticketQuantity: number
  setTicketQuantity: (quantity: number) => void
  integration?: {
    pixelId: string
  }
  setPixelId: (pixelId: string) => void
  showLinkCoppiedSnackBar: boolean
  setShowLinkCoppiedSnackBar: (show: boolean) => void
}

const eventStore = create<eventProps>()((set) => ({
  ticketQuantity: 0,
  setTicketQuantity: (quantity) => {
    set(() => ({
      ticketQuantity: quantity,
    }))
  },
  integration: undefined,
  setPixelId: (pixelId) => {
    set(() => ({
      integration: {
        pixelId,
      },
    }))
  },
  showLinkCoppiedSnackBar: false,
  setShowLinkCoppiedSnackBar: (show) => {
    set(() => ({
      showLinkCoppiedSnackBar: show,
    }))
  },
}))

export default eventStore
