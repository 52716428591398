import { type ReactElement, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import { EventFooterInformation } from '@/components/Event/EventFooterInformation/EventFooterInformation'
import EventBase from '@/compositions/EventBase/EventBase'

import ticketsStore from '@/store/tickets'
import eventStore from '@/store/event'
import interestStore from '@/store/interest'
import userStore from '@/store/user'

import { type IEventData } from '@/types/hooks/api/useEvent'

import { isTruthy } from '@/helpers/validation'

import { usePixelTrackPageView } from '@/hooks/pixel'
import { useGetUsersStatus } from '@/hooks/api/Event'
import { isEventFinished } from '@/helpers/isEventFinished'

interface EventStoryProps {
  eventData: IEventData | undefined
  refresh: () => void
}

export function EventStory({
  eventData,
  refresh,
}: EventStoryProps): ReactElement {
  const [showLinkAppliedSnackBar, setShowLinkAppliedSnackBar] = useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [showInterestModal, setShowInterestModal] = useState(false)

  const navigate = useNavigate()
  const { eventAlias } = useParams()

  const { setTicketQuantity, setPixelId, showLinkCoppiedSnackBar } =
    eventStore()
  const { resetTickets } = ticketsStore()
  const {
    setRefreshData,
    updateAll,
    setRefreshCurrentUserData,
    alias: interestAlias,
  } = interestStore()
  const { userIsLogged } = userStore()

  const searchParams = new URLSearchParams(document.location.search)
  const link = searchParams.get('link')

  const { trackPageView } = usePixelTrackPageView()
  const { usersData, refresh: refreshUsersStatus } = useGetUsersStatus(
    eventAlias!,
  )

  function handleClick(): void {
    navigate('tickets')
  }

  useEffect(() => {
    resetTickets()
    ticketsStore.persist.clearStorage()
    setTicketQuantity(0)

    if (isTruthy(link)) {
      setShowLinkAppliedSnackBar(true)
      setTimeout(setShowLinkAppliedSnackBar, 4000, false)
    }
  }, [])

  useEffect(() => {
    // Pixel track
    if (isTruthy(eventData?.integrationCredentials?.pixelId))
      trackPageView(eventData?.integrationCredentials?.pixelId ?? '')
  }, [window.fbq, eventData])

  useEffect(() => {
    setPixelId(eventData?.integrationCredentials?.pixelId ?? '')
  }, [eventData])

  useEffect(() => {
    setRefreshData(() => {
      void refreshUsersStatus()
    })
    setRefreshCurrentUserData(refresh)
  }, [refresh])

  useEffect(() => {
    if (usersData !== undefined) updateAll(usersData, eventAlias)
  }, [usersData])

  // Updates users status once the user logs in
  useEffect(() => {
    void refreshUsersStatus()
  }, [userIsLogged])

  const footerTitle =
    eventAlias === 'sal-de-caraiva' // TEMPORARY INIT
      ? 'Ingressos' // TEMPORARY END
      : eventData?.lowestTicketPrice === 0
        ? 'Ingresso'
        : eventData?.lowestTicketPrice === null
          ? 'Ingressos'
          : 'A partir de'

  const footerValue =
    eventAlias === 'sal-de-caraiva' // TEMPORARY INIT
      ? 'Disponíveis' // TEMPORARY END
      : eventData?.lowestTicketPrice === 0
        ? 'Grátis'
        : eventData?.lowestTicketPrice === null
          ? 'Indisponíveis'
          : (eventData?.lowestTicketPrice ?? 0)

  const footerSubtitle =
    eventAlias === 'sal-de-caraiva' // TEMPORARY INIT
      ? '' // TEMPORARY END
      : eventData?.lowestTicketPrice === 0 ||
          eventData?.lowestTicketPrice === null
        ? ''
        : '+ taxa'

  const footerButtonText =
    eventData?.lowestTicketPrice === 0 ? 'Reservar agora' : 'Comprar agora'

  const isPastEvent =
    eventData?.date.endDate !== undefined
      ? isEventFinished(eventData?.date.endDate)
      : false

  return (
    <>
      <EventBase.Root>
        <motion.div
          className="w-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          {!eventData!.hideSocial && (
            <EventBase.Interest
              openEventInterestModal={() => {
                setShowInterestModal(true)
              }}
              initialUserStatus={eventData!.userStatus}
            />
          )}
          {!eventData!.hideReactions && (
            <EventBase.Reactions
              eventId={eventData?.id ?? 0}
              eventReactions={eventData?.reactions}
            />
          )}
          <div className="flex flex-col gap-8 pt-2">
            {isTruthy(eventData?.description) && (
              <EventBase.Description text={eventData?.description} />
            )}
            {isTruthy(eventData?.lineup) &&
              (eventData?.lineup?.length ?? 0) > 0 && (
                <EventBase.Bit title="Line-up" className="px-0">
                  <EventBase.LineUp artists={eventData?.lineup} />
                </EventBase.Bit>
              )}
            {isTruthy(eventData?.spotify) && (
              <EventBase.Playlist spotify={eventData?.spotify} />
            )}
            {(isTruthy(eventData?.location) ||
              isTruthy(eventData?.terms) ||
              isTruthy(eventData?.faq)) && (
              <EventBase.Bit title="Infos">
                <>
                  <EventBase.Info.Time eventData={eventData} />
                  {isTruthy(eventData?.location) && (
                    <EventBase.Info.Location
                      name={eventData?.location.name}
                      fullAddress={eventData?.location.address}
                      latitude={eventData?.location.latitude}
                      longitude={eventData?.location.longitude}
                    />
                  )}
                  {isTruthy(eventData?.terms) && (
                    <EventBase.Info.Terms terms={eventData?.terms} />
                  )}
                  {isTruthy(eventData?.faq) && eventData!.faq.length > 0 && (
                    <EventBase.Info.Faq questionsAnswers={eventData?.faq} />
                  )}
                </>
              </EventBase.Bit>
            )}
            {eventAlias === interestAlias && !eventData!.hideSocial && (
              <EventBase.Bit title="RSVPs" className="px-0">
                <EventBase.RSVPs
                  openEventInterestModal={() => {
                    setShowInterestModal(true)
                  }}
                />
              </EventBase.Bit>
            )}
            <EventBase.Bit className="m-0">
              {eventData?.eventType === 'CLOSE_FRIENDS' ? (
                <EventBase.CreateEvent
                  title="Crie seu evento close friends!"
                  subtitle="Clique aqui e crie sua festa em minutos!"
                />
              ) : (
                <EventBase.CreateEvent
                  title="Crie seu evento com a gente!"
                  subtitle="Clique aqui e crie sua festa em minutos!"
                />
              )}
            </EventBase.Bit>
            {isTruthy(eventData?.partners) &&
              eventData!.partners.length > 0 && (
                <EventBase.Bit title="Parceiros" className="px-0">
                  <EventBase.Partners partners={eventData?.partners} />
                </EventBase.Bit>
              )}
            {isTruthy(eventData?.organization) &&
              eventData?.hosts.length === 0 && (
                <EventBase.Bit className="m-0">
                  <EventBase.Organization
                    organizationData={{
                      alias: eventData?.organization.alias ?? '',
                      name: eventData?.organization.name ?? '',
                      imageKey: eventData?.organization.imageKey ?? '',
                      description: eventData?.organization.description ?? '',
                      followStatus:
                        eventData?.organization.organizationFollowStatus ?? '',
                    }}
                    refresh={refresh}
                  />
                </EventBase.Bit>
              )}
            {isTruthy(eventData?.hosts.length) && (
              <EventBase.Bit className="m-0">
                <EventBase.Hosts hosts={eventData?.hosts} />
              </EventBase.Bit>
            )}
          </div>
        </motion.div>
        {showInterestModal && (
          <EventBase.InterestModal
            isParentClosing={isParentClosing}
            closeModal={() => {
              setIsParentClosing(true)
              setTimeout(setIsParentClosing, 400, false)
              setTimeout(setShowInterestModal, 400, false)
            }}
            interestData={usersData}
          />
        )}
      </EventBase.Root>
      <EventBase.Footer
        title={footerTitle}
        value={footerValue}
        subtitle={footerSubtitle}
        buttonText={footerButtonText}
        buttonEnabled={true}
        buttonOnClick={() => {
          handleClick()
        }}
        hideButton={eventData?.lowestTicketPrice === null}
        eventFinished={isPastEvent}
        information={
          <EventFooterInformation
            link={link}
            showLinkAppliedSnackBar={showLinkAppliedSnackBar}
            showLinkCoppiedSnackBar={showLinkCoppiedSnackBar}
          />
        }
      />
    </>
  )
}

export default EventStory
