import {
  type ReactElement,
  type ElementType,
  type ButtonHTMLAttributes,
} from 'react'

import { twMerge } from 'tailwind-merge'

import { motion } from 'framer-motion'
import Spinner from '@/components/Global/Spinner/Spinner'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string
  icon?: ElementType
  enabled: boolean
  isLoading?: boolean
  customSpinnerHeight?: string
  customSpinnerWidth?: string
  iconClassName?: string
}

function Button({
  text,
  icon: Icon,
  enabled,
  onClick,
  isLoading = false,
  className,
  customSpinnerHeight = 'w-8',
  customSpinnerWidth = 'h-8',
  iconClassName = 'color-background-main',
  ...props
}: ButtonProps): ReactElement {
  const variations = {
    enabled: 'bg-primary-main text-black',
    disabled: 'bg-[#A2A2A2] text-[#656565]',
  }

  return (
    <button
      className={twMerge(
        'relative flex h-full w-full items-center justify-center rounded-full text-lg font-bold transition-colors ',
        variations[enabled ? 'enabled' : 'disabled'],
        className,
      )}
      disabled={!enabled}
      onClick={onClick}
      {...props}
    >
      {isLoading ? (
        <motion.div
          key="spinner"
          className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'easeInOut' }}
        >
          <Spinner
            borderWidth="border-4"
            borderColor="border-[#232323]/50"
            bottomBorderColor="border-b-[#232323]"
            width={customSpinnerWidth}
            height={customSpinnerHeight}
          />
        </motion.div>
      ) : (
        <motion.div
          key="text-icon"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'easeInOut' }}
        >
          {text !== undefined && text}
          {Icon !== undefined && (
            <Icon
              size={20}
              className={iconClassName}
              style={{ strokeWidth: '3' }}
            />
          )}
        </motion.div>
      )}
    </button>
  )
}
export default Button
