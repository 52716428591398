import { useEffect, type ReactElement } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Loading from '@/components/Global/Loading/Loading'
import EventThumbnail from '@/components/Event/EventThumbnail/EventThumbnail'

import walletStore from '@/store/wallet'

import { datesToFormat } from '@/helpers/formatDate'

import { BsArrowLeftShort } from 'react-icons/bs'
import EventHistoryTicketsCard from '../../../components/EventsHistory/EventHistoryTicketsCard/EventHistoryTicketsCard'
import EventHistoryUsersCard from '../../../components/EventsHistory/EventHistoryUsersCard/EventHistoryUsersCard'

function EventHistory(): ReactElement {
  const navigate = useNavigate()
  const { eventAlias } = useParams()

  const { currentWalletEvent, setCurrentEvent, walletEvents } = walletStore()

  useEffect(() => {
    if (walletEvents !== undefined) {
      const event = walletEvents.find((event) => event.alias === eventAlias)
      if (event !== undefined) {
        setCurrentEvent(event)
      }
    }
  }, [currentWalletEvent, walletEvents])

  if (currentWalletEvent === undefined) {
    return <Loading />
  }

  return (
    <div className="flex h-full min-h-full flex-col overflow-y-scroll bg-background-main p-4 pb-24">
      <div className="flex gap-4">
        <button
          className="rounded-full bg-dark-white p-0.5"
          onClick={() => {
            navigate('/wallet/history')
          }}
        >
          <BsArrowLeftShort size={32} color="#232323" />
        </button>
        <h1 className="text-2xl text-white">Meu evento</h1>
      </div>
      {currentWalletEvent !== undefined && (
        <div className="flex flex-col gap-4">
          <div className="mt-6">
            <EventThumbnail
              title={currentWalletEvent.title}
              titleImageKey={currentWalletEvent.titleImageKey}
              imageKey={currentWalletEvent.imageKey}
              date={datesToFormat(
                currentWalletEvent.startDate,
                currentWalletEvent.endDate,
              )}
              location={currentWalletEvent.location ?? 'Local a definir'}
              briefVariant="white"
              highlighted
            />
          </div>
          <EventHistoryTicketsCard currentWalletEvent={currentWalletEvent} />
          <EventHistoryUsersCard alias={currentWalletEvent.alias} />
        </div>
      )}
    </div>
  )
}

export default EventHistory
