import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { useAuthNavigate } from '@/hooks/useAuthNavigate'

interface AuthLinkProps {
  to: string
  className?: string
  children: ReactElement
}

function AuthLink({ to, className, children }: AuthLinkProps): ReactElement {
  const { checkAuthNavigate } = useAuthNavigate()

  return (
    <>
      <div
        className={twMerge('cursor-pointer', className)}
        onClick={() => {
          checkAuthNavigate(to)
        }}
      >
        {children}
      </div>
    </>
  )
}

export default AuthLink
