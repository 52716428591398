import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

interface InterestMenuProps {
  currentView: 'GOING' | 'INTERESTED' | 'VIEWED'
  setCurrentView: (currentView: 'GOING' | 'INTERESTED' | 'VIEWED') => void
  data: {
    GOING: number
    INTERESTED: number
    VIEWED: number
  }
}

function InterestMenu({
  currentView,
  setCurrentView,
  data,
}: InterestMenuProps): ReactElement {
  return (
    <div className="relative w-full">
      <div className="flex w-full items-start gap-2 overflow-x-scroll px-4">
        <button
          className={twMerge(
            'whitespace-nowrap rounded-full border border-dark-black px-4 py-1 text-xs font-medium text-dark-black transition-all duration-100 ease-in-out',
            currentView === 'GOING' && 'bg-dark-black text-white',
          )}
          onClick={() => {
            setCurrentView('GOING')
          }}
          style={{ fontVariantLigatures: 'none' }}
        >
          {`Vão (${data.GOING})`}
        </button>
        <button
          className={twMerge(
            'whitespace-nowrap rounded-full border border-dark-black px-4 py-1 text-xs font-medium text-dark-black',
            currentView === 'INTERESTED' && 'bg-dark-black text-white',
          )}
          onClick={() => {
            setCurrentView('INTERESTED')
          }}
          style={{ fontVariantLigatures: 'none' }}
        >
          {`Têm interesse (${data.INTERESTED})`}
        </button>
        <button
          className={twMerge(
            'whitespace-nowrap rounded-full border border-dark-black px-4 py-1 text-xs font-medium text-dark-black',
            currentView === 'VIEWED' && 'bg-dark-black text-white',
          )}
          onClick={() => {
            setCurrentView('VIEWED')
          }}
          style={{ fontVariantLigatures: 'none' }}
        >
          {`Visualizaram (${data.VIEWED})`}
        </button>
      </div>

      <div className="absolute left-0 top-0 h-full w-4 bg-gradient-to-r from-white to-transparent" />
      <div className="absolute right-0 top-0 h-full w-4 bg-gradient-to-l from-white to-transparent" />
    </div>
  )
}

export default InterestMenu
