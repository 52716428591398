import { useEffect, type ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

// Subpages
import UserWallet from './UserWallet/UserWallet'
import Transfer from './Transfer/Transfer'
import Sell from './Sell/Sell'
import WalletHistory from './WalletHistory/WalletHistory'
import EventHistory from './EventHistory/EventsHistory'
import EventPayments from './EventPayments/EventPayments'

// Fetch
import { useGetWalletByUserId } from '@/hooks/api/useWallet'

// Stores
import walletStore from '@/store/wallet'

function Wallet(): ReactElement {
  const { walletTicketGroups, isLoading, refresh } = useGetWalletByUserId()

  const { setWalletEvents, setIsLoadingWalletFetch, setRefresh } = walletStore()

  useEffect(() => {
    setWalletEvents(walletTicketGroups ?? [])
    setIsLoadingWalletFetch(isLoading)
    setRefresh(refresh)
  }, [walletTicketGroups, isLoading])

  return (
    <>
      <Routes>
        <Route index element={<UserWallet />} />
        <Route path="/transfer" element={<Transfer />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/history" element={<WalletHistory />} />
        <Route path="/history/:eventAlias" element={<EventHistory />} />
        <Route path="/payments/:eventAlias" element={<EventPayments />} />
      </Routes>
    </>
  )
}

export default Wallet
