import { type ReactElement } from 'react'

import TicketSummary from '@/components/Global/TicketSummary/TicketSummary'

import { type IPayment } from '@/types/EventPayment'

import { dateToFormatNoWeekDay } from '@/helpers/formatDate'
import { numberToReais } from '@/helpers/formatNumber'
import { isTruthy } from '@/helpers/validation'

interface PaymentProps {
  payment: IPayment
  openModal: () => void
}

function Payment({ payment, openModal }: PaymentProps): ReactElement {
  return (
    <div className="flex w-full flex-col rounded-md bg-dark-black p-4">
      <div className="flex w-full items-center justify-between">
        <span className="text-sm font-bold leading-3 text-white">
          {dateToFormatNoWeekDay(payment.date)}
        </span>
        <div className="flex w-fit items-center gap-2 self-center rounded-md bg-background-main px-3 py-2 text-xs font-bold text-white">
          {payment.paymentStatus === 'REFUND_PENDING' ? (
            <>
              <div className="size-2.5 rounded-full bg-tonal-yellow" />
              <span>Reembolso em processo</span>
            </>
          ) : payment.paymentStatus === 'EXPIRED' ? (
            <>
              <div className="size-2.5 rounded-full bg-tonal-yellow" />
              <span>Compra expirada</span>
            </>
          ) : payment.paymentStatus === 'CANCELLED' ? (
            <>
              <div className="size-2.5 rounded-full bg-tonal-red" />
              <span>Compra cancelada</span>
            </>
          ) : payment.paymentStatus === 'APPROVED' ? (
            <>
              <div className="size-2.5 rounded-full bg-primary-main" />
              <span>Compra concluída</span>
            </>
          ) : (
            <>
              <div className="size-2.5 rounded-full bg-tonal-yellow" />
              <span>Pagamento pendente</span>
            </>
          )}
        </div>
      </div>
      <div className="flex w-full flex-col gap-2 pt-4">
        {payment.productPayments.map((productPayment, index) => {
          const title = isTruthy(productPayment.batchDescription)
            ? `${productPayment.batchDescription} - ${productPayment.ticketSpecDescription}`
            : productPayment.ticketSpecDescription
          return (
            <TicketSummary
              title={title}
              quantity={productPayment.quantity}
              key={index}
              counterVariant="dark"
              thirdInfo={numberToReais(productPayment.price, 2)}
            />
          )
        })}
      </div>
      {payment.paymentStatus === 'APPROVED' && (
        <button
          className="mt-2 size-fit self-center rounded-full border-2 border-white bg-transparent px-8 py-2 text-sm font-bold text-white"
          onClick={openModal}
        >
          Cancelar compra
        </button>
      )}
    </div>
  )
}

export default Payment
