import { useState, type ReactElement, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import SnackBar from '@/components/Global/SnackBar/SnackBar'

interface ModalProps {
  closeModal?: () => void
  children: ReactElement
  disableTouchToClose?: boolean
  isParentClosing?: boolean
  showSnackBar?: boolean
  snackBarMessage?: string
  hasRoundedBorder?: boolean
  // isParentClosing is responsible for closing the Modal internally before the parent component unmounts it
  variant?: 'light' | 'dark'
  snackBarVariant?: 'error' | 'information' | 'success'
}

export function Modal({
  closeModal,
  children,
  disableTouchToClose = false,
  isParentClosing = false,
  showSnackBar,
  snackBarMessage,
  hasRoundedBorder = true,
  variant = 'light',
  snackBarVariant = 'error',
}: ModalProps): ReactElement {
  const [renderOverlay, setRenderOverlay] = useState(true)
  const [renderModal, setRenderModal] = useState(false)

  const modalRef = useRef<HTMLDivElement>(null)
  const modalDivRef = useRef<HTMLDivElement>(null)

  function handleModalClose(): void {
    document.body.style.overflow = ''
    setRenderModal(false)
    setTimeout(setRenderOverlay, 200, false)
    if (closeModal !== undefined) setTimeout(closeModal, 200)
  }

  function checkWindowHeight(): void {
    if (modalRef.current === null) return
    if (modalRef.current?.clientHeight === window.innerHeight) {
      modalDivRef.current!.style.borderRadius = `0px`
    } else {
      modalDivRef.current!.style.borderTopLeftRadius = `8px`
      modalDivRef.current!.style.borderTopRightRadius = `8px`
    }
  }

  // Render overlay first, then render modal
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    setTimeout(setRenderModal, 200, true)
  }, [])

  useEffect(() => {
    document.body.style.overflow = ''
    if (isParentClosing) handleModalClose()
  }, [isParentClosing])

  useEffect(() => {
    const handleResize = (): void => {
      checkWindowHeight()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <AnimatePresence>
      {renderOverlay && (
        <motion.div
          key="overlay"
          className="fixed inset-0 z-[49] h-max min-h-full w-full backdrop-blur-sm backdrop-brightness-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          onClick={() => {
            if (!disableTouchToClose) handleModalClose()
          }}
        />
      )}

      {renderModal && (
        <motion.div
          key="modal"
          className="fixed bottom-0 left-0 z-50 flex max-h-screen w-full flex-col items-end"
          initial={{ y: '100%', height: 0 }}
          animate={{ y: '0', height: 'fit-content' }}
          exit={{ y: '100%' }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          ref={modalRef}
          onAnimationComplete={() => {
            // This observer takes care of any change on children size
            // that affects the modal's height. This is used on the
            // LoginSignup component to change the modal's border radius
            const resizeObserver = new ResizeObserver(() => {
              checkWindowHeight()
            })
            resizeObserver.observe(modalDivRef.current!)
            return () => {
              resizeObserver.disconnect()
            }
          }}
        >
          <>
            <SnackBar
              showSnackBar={showSnackBar}
              message={snackBarMessage}
              variant={snackBarVariant}
            />
            <div
              className={twMerge(
                'z-30 flex max-h-screen w-full flex-col items-center justify-center overflow-scroll',
                hasRoundedBorder && 'rounded-t-lg',
                variant === 'light' && 'bg-white',
                variant === 'dark' && 'bg-dark-black',
              )}
              ref={modalDivRef}
            >
              {children}
            </div>
          </>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Modal
