import { useEffect, useState, type ReactElement } from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'

import routingStore from '@/store/routing'
import userStore from '@/store/user'

import Loading from '@/components/Global/Loading/Loading'

function ProtectedRoute(): ReactElement {
  const [isLoading, setIsLoading] = useState(true)

  const { setToPath, isPinging } = routingStore()
  const { userIsLogged } = userStore()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!isPinging) {
      setIsLoading(false)

      if (!userIsLogged) {
        navigate('/login')
        setToPath(location.pathname)
      }
    }
  }, [isPinging])

  if (isLoading) return <Loading hidden={!isLoading} />
  return <Outlet />
}

export default ProtectedRoute
