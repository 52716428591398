import { api } from '@/services/axios'
import { useState } from 'react'
import { type AxiosError, type AxiosResponse } from 'axios'

import {
  type IVerifyPhoneOtpProps,
  type IVerifyPhoneOtpReturn,
  type IVerifyPhoneOtpResponse,
  type IVerifyEmailOtpProps,
  type IVerifyEmailOtpReturn,
  type IVerifyEmailOtpResponse,
  type IResendEmailOtpResponse,
} from '@/types/hooks/api/useOTP'

export const usePostRequestPhoneOTP = (): {
  postRequestPhoneOtp: (
    phoneNumber: string,
    communicationMethod: 'SMS' | 'WHATSAPP',
  ) => Promise<boolean>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postRequestPhoneOtp(
    phoneNumber: string,
    communicationMethod: 'SMS' | 'WHATSAPP',
  ): Promise<boolean> {
    setIsLoading(true)

    const requestBody = {
      phoneNumber,
      communicationMethod,
    }

    try {
      await api.post(`/auth/request-otp`, requestBody)
      setIsLoading(false)
      return true
    } catch (err) {
      setIsLoading(false)
      return false
    }
  }

  return { postRequestPhoneOtp, isLoading }
}

export const usePostRequestEmailOTP = (): {
  postRequestEmailOtp: (email: string) => Promise<boolean>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postRequestEmailOtp(email: string): Promise<boolean> {
    setIsLoading(true)

    const requestBody = {
      email,
    }

    try {
      await api.post(`/auth/request-email-otp`, requestBody)
      setIsLoading(false)
      return true
    } catch (err) {
      setIsLoading(false)
      return false
    }
  }

  return { postRequestEmailOtp, isLoading }
}

export const usePostVerifyPhoneOTP = (): {
  postVerifyPhoneOtp: (
    verificationObj: IVerifyPhoneOtpProps,
  ) => Promise<IVerifyPhoneOtpReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postVerifyPhoneOtp(
    verificationObj: IVerifyPhoneOtpProps,
  ): Promise<IVerifyPhoneOtpReturn> {
    setIsLoading(true)

    const { phoneNumber, code } = verificationObj

    const requestBody = {
      phoneNumber,
      code,
      origin: 'KAYA',
    }

    try {
      const response: AxiosResponse<IVerifyPhoneOtpResponse> = await api.post(
        `/auth/verify-otp`,
        requestBody,
      )
      setIsLoading(false)
      return {
        status: 200,

        user: response.data.user,
      }
    } catch (err) {
      const error = err as AxiosError<IVerifyPhoneOtpResponse>
      setIsLoading(false)

      if (error.response?.status === 401) {
        return {
          status: 401,
          phoneNumberValidationKey:
            error.response.data.phoneNumberValidationKey,
        }
      }
      return {
        status: error.response?.status as number,
      }
    }
  }

  return { postVerifyPhoneOtp, isLoading }
}

export const usePostVerifyEmailOTP = (): {
  postVerifyEmailOtp: (
    verificationObj: IVerifyEmailOtpProps,
  ) => Promise<IVerifyEmailOtpReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postVerifyEmailOtp(
    verificationObj: IVerifyEmailOtpProps,
  ): Promise<IVerifyEmailOtpReturn> {
    setIsLoading(true)

    const requestBody = verificationObj

    try {
      await api.post(`/auth/validate-email-otp`, requestBody)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      const error = err as AxiosError<IVerifyEmailOtpResponse>
      setIsLoading(false)

      if (error.response?.status === 401) {
        return {
          status: 401,
        }
      }
      if (error.response?.status === 406) {
        return {
          status: 406,
        }
      }
      return {
        status: error.response?.status as number,
      }
    }
  }

  return { postVerifyEmailOtp, isLoading }
}

export const usePostResendEmailOTP = (): {
  postResendEmailOtp: () => Promise<IResendEmailOtpResponse>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postResendEmailOtp(): Promise<IResendEmailOtpResponse> {
    setIsLoading(true)

    try {
      await api.post(`/auth/resend-email-otp`)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      const error = err as AxiosError<IResendEmailOtpResponse>
      setIsLoading(false)

      if (error.response?.status === 401) {
        return {
          status: 401,
        }
      }
      if (error.response?.status === 406) {
        return {
          status: 406,
        }
      }
      return {
        status: error.response?.status as number,
      }
    }
  }

  return { postResendEmailOtp, isLoading }
}
