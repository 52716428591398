import { create } from 'zustand'

interface NavbarProps {
  navbarIsShown: boolean
  hideNavbar: () => void
  showNavbar: () => void
}

const navbarStore = create<NavbarProps>()((set) => ({
  navbarIsShown: true,
  hideNavbar: () => {
    set(() => ({ navbarIsShown: false }))
  },
  showNavbar: () => {
    set(() => ({ navbarIsShown: true }))
  },
}))

export default navbarStore
