import { twMerge } from 'tailwind-merge'

import Button from '@/components/Global/Button/Button'

import type { ReactElement } from 'react'
import { type INotificationFollowRequest } from '@/types/hooks/api/useNotifications'

export function FollowRequestButton({
  onClick,
  followRequest,
  isLoading,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
  followRequest: INotificationFollowRequest
  isLoading?: boolean
}): ReactElement {
  const text =
    followRequest.status === 'FOLLOWING'
      ? 'Seguindo'
      : followRequest.status === 'NOT_FOLLOWING'
        ? 'Seguir de volta'
        : 'Pendente'

  return (
    <Button
      enabled={isLoading !== undefined ? !isLoading : true}
      onClick={onClick}
      isLoading={isLoading}
      customSpinnerHeight="w-[18px]"
      customSpinnerWidth="h-[18px]"
      className={twMerge(
        'flex h-6 w-[106px] items-center justify-center whitespace-nowrap rounded-md text-xs font-bold leading-3 text-dark-black',
        followRequest.status === 'FOLLOWING' && 'bg-[#a7a7a7]',
      )}
      text={text}
    />
  )
}
