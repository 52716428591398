import { useState, useEffect } from 'react'
import useSWR from 'swr'
import fetcher, { api } from '@/services/axios'

import {
  type OrderDetailsData,
  type CreateOrderDetailsResponse,
  type CreateOrderDetailsReturn,
  type CancelOrderReturn,
} from '@/types/hooks/api/useOrder'
import { type AxiosError, type AxiosResponse } from 'axios'

export const useGetOrderDetailsByOrderCode = (
  code: string,
): {
  orderDetailsData: OrderDetailsData | undefined
  error: string | undefined
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)
  const { data, error } = useSWR<OrderDetailsData, string>(
    '/orders/' + code,
    fetcher,
  )

  useEffect(() => {
    if (data !== undefined || error !== undefined) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [data])

  const orderDetailsData: OrderDetailsData | undefined =
    data !== undefined
      ? {
          ...data,
        }
      : undefined

  return { orderDetailsData, error, isLoading }
}

export const usePostCreateOrder = (): {
  isLoading: boolean
  postCreateOrder: (
    eventId: number,
    price: number,
    tickets: number[],
  ) => Promise<CreateOrderDetailsReturn>
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postCreateOrder(
    eventId: number,
    price: number,
    tickets: number[],
  ): Promise<CreateOrderDetailsReturn> {
    setIsLoading(true)

    const requestBody = {
      eventId,
      price,
      ticketIds: tickets,
    }

    try {
      const response: AxiosResponse<CreateOrderDetailsResponse> =
        await api.post(`/orders`, requestBody)
      const code = response.data.code
      setIsLoading(false)
      return {
        status: 200,
        code,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
        code: '',
      }
    }
  }

  return { postCreateOrder, isLoading }
}

export const usePutCancelOrder = (): {
  isLoading: boolean
  putCancelOrder: (id: number) => Promise<CancelOrderReturn>
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function putCancelOrder(id: number): Promise<CancelOrderReturn> {
    setIsLoading(true)

    try {
      await api.put(`/orders/cancel/${id}`)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      setIsLoading(false)
      const error = err as AxiosError
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { putCancelOrder, isLoading }
}
