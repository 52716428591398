import { type ReactElement } from 'react'
import { Link } from 'react-router-dom'

import EventImage from '@/components/Event/EventImage/EventImage'
import EventBrief from '@/components/Event/EventBrief/EventBrief'
import OnFire from '@/components/Global/OnFire/OnFire'

import deviceStore from '@/store/device'

interface MainEventCardProps {
  eventAlias?: string
  imageKey: string | undefined
  titleImage?: string | undefined
  title?: string | undefined
  date: string | undefined
  location: string | undefined
  tags: string[] | undefined
  onFire?: boolean
}

function MainEventCard({
  eventAlias,
  imageKey,
  titleImage,
  title,
  date,
  location,
  tags,
  onFire = false,
}: MainEventCardProps): ReactElement {
  const { isMobile } = deviceStore()

  const borderRadiusOverride = isMobile
    ? ''
    : `${62 * (window.innerHeight / 1150)}px`

  return (
    <Link
      to={`/events/${eventAlias ?? ''}`}
      className="mx-4 overflow-hidden rounded-lg border-2 border-secondary-gray bg-dark-black p-2"
      style={{
        borderTopLeftRadius: borderRadiusOverride,
        borderTopRightRadius: borderRadiusOverride,
      }}
    >
      <div className="relative">
        <EventImage
          imageKey={imageKey}
          expanded
          rounded
          desktopBorderRadius={
            isMobile ? '' : `${62 * (window.innerHeight / 1150)}px`
          }
        />
        {onFire && (
          <div className="absolute right-4 top-4 z-20">
            <OnFire variant="withText" />
          </div>
        )}
      </div>
      <div className="mt-2">
        <EventBrief
          titleImage={titleImage}
          title={title}
          date={date}
          location={location}
          highlighted
          padding=""
          tags={tags}
          variant="white"
        />
      </div>
    </Link>
  )
}

export default MainEventCard
