import { useState, type ReactElement } from 'react'

import Button from '@/components/Global/Button/Button'

import {
  useDeleteUnfollow,
  usePostCreateFollowRequest,
} from '@/hooks/api/Social'

import userStore from '@/store/user'
import routingStore from '@/store/routing'
import { snackbarStore } from '@/store/snackbar'

import { ERROR_TO_CREATE_FOLLOW } from '@/errors'

import { type IUserData } from '@/types/hooks/api/useProfile'

import { PiUserSwitch } from 'react-icons/pi'

import { isTruthy } from '@/helpers/validation'

interface ProfileCardProps {
  userData?: IUserData
  isAuthUser: boolean
  setShowProfilePictureModal: (value: boolean) => void
}

export function ProfileCard({
  userData,
  isAuthUser,
  setShowProfilePictureModal,
}: ProfileCardProps): ReactElement {
  const { setShowLogin, setToPath } = routingStore()
  const { userIsLogged } = userStore()
  const { showError } = snackbarStore()

  const [followRequestStatus, setFollowRequestStatus] = useState<
    'FOLLOWING' | 'NOT_FOLLOWING' | 'PENDING' | undefined
  >(userData?.followRequest.status)

  const { postCreateFollowRequest, isLoading: followRequestIsLoading } =
    usePostCreateFollowRequest()
  const { deleteUnfollow, isLoading: unfollowRequestIsLoading } =
    useDeleteUnfollow()

  async function handleFollow(): Promise<void> {
    if (!userIsLogged) {
      setShowLogin(true)
      return
    }
    if (userData == null) return
    const response = await postCreateFollowRequest(userData?.id)
    if (response.status === 200) {
      setFollowRequestStatus('PENDING')
    } else {
      showError(ERROR_TO_CREATE_FOLLOW)
    }
  }

  async function handleUnfollow(): Promise<void> {
    if (!userIsLogged) {
      setShowLogin(true)
      return
    }
    if (userData == null) return
    const response = await deleteUnfollow(userData?.id)
    if (response.status === 200) {
      setFollowRequestStatus('NOT_FOLLOWING')
    } else {
      showError(ERROR_TO_CREATE_FOLLOW)
    }
  }

  return (
    <div className="relative flex w-full flex-col items-center justify-between gap-3 overflow-hidden rounded-xl bg-black/50 p-4 backdrop-blur-sm">
      <div className="flex max-w-full items-center gap-4">
        <div className="relative aspect-square w-1/4 min-w-[64px]  rounded-full">
          {isTruthy(userData?.imageKey) ? (
            <img
              src={`${import.meta.env.VITE_S3 as string}/${
                userData?.imageKey ?? ''
              }`}
              alt="Profile pic"
              className="size-full overflow-hidden rounded-full"
            />
          ) : (
            <div className="relative flex size-full items-center justify-center rounded-full bg-white/10 p-2">
              <span className="text-center text-xs font-bold text-white">
                SEM FOTO DE PERFIL
              </span>
            </div>
          )}
          {isAuthUser && (
            <div
              className="absolute bottom-0 right-0 flex aspect-square h-1/3 items-center justify-center rounded-full bg-primary-main hover:cursor-pointer"
              onClick={() => {
                setToPath('none')
                setShowProfilePictureModal(true)
              }}
            >
              <PiUserSwitch size={'75%'} color="black" />
            </div>
          )}
        </div>
        <div className="flex w-full justify-evenly">
          <div className="flex flex-col items-center gap-1">
            <span className="text-lg font-bold leading-4 text-white">
              {userData?.numberOfEvents}
            </span>
            <span className="text-sm leading-4 text-white">Eventos</span>
          </div>
          <div className="flex flex-col items-center gap-1">
            <span className="text-lg font-bold leading-4 text-white">
              {userData?.followers}
            </span>
            <span className="text-sm leading-4 text-white">Seguidores</span>
          </div>
          <div className="flex flex-col items-center gap-1">
            <span className="text-lg font-bold leading-4 text-white">
              {userData?.following}
            </span>
            <span className="text-sm leading-4 text-white">Seguindo</span>
          </div>
        </div>
      </div>
      <span className="w-full font-medium leading-4 text-white">
        {`${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`}
      </span>

      {!isAuthUser && (
        <div className="w-full">
          {followRequestStatus === 'NOT_FOLLOWING' && (
            <div className="h-8 w-full">
              <Button
                enabled
                text="Seguir"
                className="rounded-lg text-base"
                isLoading={followRequestIsLoading}
                onClick={handleFollow}
              />
            </div>
          )}

          {followRequestStatus === 'PENDING' && (
            <div className="h-8 w-full">
              <Button
                enabled={false}
                text="Solicitação enviada"
                className="rounded-lg bg-[#839744] text-base text-[#181818]"
              />
            </div>
          )}

          {followRequestStatus === 'FOLLOWING' && (
            <div className="h-8 w-full">
              <Button
                enabled
                text="Deixar de seguir"
                className="rounded-lg text-base"
                isLoading={unfollowRequestIsLoading}
                onClick={handleUnfollow}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
