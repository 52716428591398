import EventTime from '@/components/Event/EventTime/EventTime'
import EventLocation from '@/components/Event/EventLocation/EventLocation'
import EventTerms from '@/components/Event/EventTerms/EventTerms'
import EventFaq from '@/components/Event/EventFaq/EventFaq'

const EventInfo = {
  Time: EventTime,
  Location: EventLocation,
  Terms: EventTerms,
  Faq: EventFaq,
}

export default EventInfo
