import { useEffect, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import PeopleCounter from '@/components/Event/PeopleCounter/PeopleCounter'
import Spinner from '@/components/Global/Spinner/Spinner'
import InterestUser from '../../InterestUser/InterestUser'

import interestStore from '@/store/interest'

interface InterestViewsViewedProps {
  setPageHeight: (h: number) => void
  newPage: () => void
  noNewUsers: boolean
}

export function InterestViewsViewed({
  setPageHeight,
  newPage,
  noNewUsers,
}: InterestViewsViewedProps): ReactElement {
  const navigate = useNavigate()

  const { viewed } = interestStore()

  const renderedUsers = viewed.users.filter(
    (user) =>
      user.followRequest !== undefined &&
      user.followRequest.status === 'FOLLOWING',
  )

  useEffect(() => {
    if (renderedUsers.length === 0) {
      setPageHeight(400 + 42)
    } else {
      const height = Math.min(520, 200 + renderedUsers.length * 53)
      setPageHeight(height + 42)
    }
  }, [renderedUsers])

  // Infinite scroll handler
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        newPage()
      }
    })

    const loadSentry = document.querySelector('#loadSentryViewedView')
    if (loadSentry !== null) intersectionObserver.observe(loadSentry)

    return () => {
      intersectionObserver.disconnect()
    }
  }, [])

  if (renderedUsers.length === 0)
    return (
      <div className="flex size-full flex-col items-center justify-between">
        <div className="flex size-full flex-col items-center justify-center gap-4">
          <span className="text-center font-medium leading-4">
            Ninguém que você segue visualizou <br /> (ainda) este evento 👀
          </span>
          <span className="text-center font-medium leading-4">
            Tá esperando o quê para <br /> começar a seguir mais pessoas?
          </span>

          <PeopleCounter
            data={viewed}
            isClickable={false}
            avatars={4}
            showChevron={false}
          />
        </div>
        <div className="mb-4 h-12 w-4/5">
          <button
            className="size-full rounded-full bg-primary-main font-bold"
            onClick={() => {
              navigate('/search')
            }}
          >
            Encontrar amigos
          </button>
        </div>
      </div>
    )
  return (
    <div className="flex size-full flex-col items-start gap-4">
      <div>
        <p className="font-medium leading-4">
          Aqui, você só pode ver quem você segue! 🫣
        </p>
      </div>
      <div className="relative size-full max-h-[330px]">
        <div className="flex size-full flex-col items-center gap-2.5 overflow-scroll py-4">
          {renderedUsers.map((user) => {
            return <InterestUser key={user.id} user={user} />
          })}
          {!noNewUsers && renderedUsers.length > 50 && (
            <div
              id="loadSentryViewedView"
              className="my-4 flex w-full items-center justify-center gap-4"
            >
              <Spinner
                borderWidth="border-4"
                borderColor="border-dark-black/50"
                bottomBorderColor="border-b-dark-black"
              />
            </div>
          )}
        </div>
        <div className="absolute left-0 top-0 h-6 w-full bg-gradient-to-b from-white to-transparent" />
        <div className="absolute bottom-0 left-0 h-6 w-full bg-gradient-to-t from-white to-transparent" />
      </div>
      <div className="flex h-fit pb-4 pl-4">
        <PeopleCounter
          data={viewed}
          isClickable={false}
          avatars={4}
          showChevron={false}
        />
      </div>
    </div>
  )
}
