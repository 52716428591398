import { type ReactNode, type ReactElement } from 'react'

interface EventRootProps {
  children: ReactNode
}

function EventRoot({ children }: EventRootProps): ReactElement {
  return (
    <div className="relative flex w-full max-w-lg flex-col items-start justify-center overflow-hidden pb-24">
      {children}
    </div>
  )
}

export default EventRoot
