import fetcher, { api } from '@/services/axios'
import { useEffect, useState } from 'react'
import { type AxiosError } from 'axios'
import useSWR from 'swr'

import {
  type IClaimReturnData,
  type IClaimResponseData,
} from '@/types/hooks/api/useClaim'

export const useClaimTickets = (
  claimId: string,
): {
  claimTickets: () => Promise<IClaimReturnData>
  data: IClaimResponseData | undefined
  isLoadingPostClaim: boolean
  isLoadingFetchClaim: boolean
} => {
  const [isLoadingPostClaim, setIsLoadingPostClaim] = useState(false)
  const [isLoadingFetchClaim, setIsLoadingFetchClaim] = useState(true)

  const { data, error } = useSWR<IClaimResponseData, string>(
    `/tickets/grant/${claimId}`,
    fetcher,
  )

  useEffect(() => {
    if (data !== undefined || error !== undefined) {
      setIsLoadingFetchClaim(false)
    } else {
      setIsLoadingFetchClaim(true)
    }
  }, [data, error])

  async function claimTickets(): Promise<IClaimReturnData> {
    setIsLoadingPostClaim(true)

    try {
      await api.post(`/tickets/claim/${claimId}`)
      setIsLoadingPostClaim(false)
      return {
        status: 200,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoadingPostClaim(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { claimTickets, isLoadingPostClaim, isLoadingFetchClaim, data }
}
