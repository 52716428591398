import { useState, type ReactElement } from 'react'

import Loading from '@/components/Global/Loading/Loading'
import { UnfollowModal } from '@/components/Notifications/UnfollowModal/UnfollowModal'
import { NotificationHistory } from '@/components/Notifications/NotificationHistory/NotificationHistory'

import { useGetNotificationHistory } from '@/hooks/api/useNotifications'

import notificationStore from '@/store/notification'

function Notifications(): ReactElement {
  const { notificationHistoryData, isLoading, refresh } =
    useGetNotificationHistory()
  const [isParentClosing, setIsParentClosing] = useState(false)
  const { setShowUnfollowConfirmationModal, showUnfollowConfirmationModal } =
    notificationStore()

  if (isLoading) return <Loading />

  return (
    <div className="flex min-h-full flex-col bg-background-main py-4 pb-16">
      <h1 className="px-4 text-2xl text-white">Notificações</h1>
      <div className="w-full p-4">
        {notificationHistoryData !== undefined && (
          <NotificationHistory
            notificationHistoryData={notificationHistoryData}
          />
        )}
      </div>
      {showUnfollowConfirmationModal && (
        <UnfollowModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowUnfollowConfirmationModal, 400, false)
          }}
          isParentClosing={isParentClosing}
          triggerRefresh={async () => {
            await refresh()
          }}
        />
      )}
    </div>
  )
}

export default Notifications
