import { useState, type ReactElement, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import { BiSolidDiscount } from 'react-icons/bi'

interface AddPromoProps {
  promo: string
  setPromo: (s: string) => void
}

function AddPromo({ promo, setPromo }: AddPromoProps): ReactElement {
  const [promoState, setPromoState] = useState<
    'addPromo' | 'input' | 'promoActivated'
  >('addPromo')
  const [insidePromo, setInsidePromo] = useState('')

  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div className="flex h-12 w-full justify-end overflow-hidden">
      <AnimatePresence mode="wait">
        {promoState === 'addPromo' && (
          <motion.div
            key="flap"
            className="flex w-12 items-center justify-center rounded-l-lg border border-r-0 border-primary-main bg-dark-black/50 backdrop-blur-sm hover:cursor-pointer"
            initial={{ x: 60 }}
            animate={{ x: 0 }}
            exit={{ x: 60 }}
            transition={{ duration: 0.25, ease: 'easeInOut' }}
            onClick={() => {
              setPromoState('input')
            }}
          >
            <BiSolidDiscount size={32} color="#D5FF5C" />
          </motion.div>
        )}
        {promoState === 'input' && (
          <motion.div
            key="input"
            className="w-full bg-dark-black/50 p-2 backdrop-blur-sm"
            initial={{ x: 500 }}
            animate={{ x: 0 }}
            exit={{ x: 500 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            <div className="flex w-full items-center gap-2">
              <div>
                <button
                  className="size-6 rotate-45 rounded-full bg-white text-2xl font-light leading-3 text-black"
                  onClick={() => {
                    setPromo('')
                    setPromoState('addPromo')
                  }}
                >
                  +
                </button>
              </div>
              <input
                type="text"
                className="ml-2 w-full bg-transparent font-bold text-white caret-primary-main outline-none placeholder:font-bold placeholder:text-dark-dark-gray"
                placeholder="Insira seu código aqui"
                ref={inputRef}
                onChange={(e) => {
                  setInsidePromo(e.target.value)
                }}
                value={insidePromo}
              />
              <div>
                <button
                  className="size-8 rounded-md bg-primary-main text-2xl leading-3 text-black transition-colors duration-150 ease-in-out disabled:bg-dark-dark-gray disabled:text-white"
                  onClick={() => {
                    setPromo(insidePromo)
                    setPromoState('promoActivated')
                  }}
                  disabled={insidePromo === ''}
                >
                  +
                </button>
              </div>
            </div>
          </motion.div>
        )}
        {promoState === 'promoActivated' && (
          <motion.div
            key="promoActivated"
            className="flex w-min items-center rounded-l-lg border border-r-0 border-primary-main bg-dark-black bg-dark-black/50 px-4 pr-3.5 backdrop-blur-sm hover:cursor-pointer"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ duration: 0.25, ease: 'easeInOut' }}
            onClick={() => {
              setPromoState('input')
            }}
          >
            <div className="flex h-6 items-center rounded-md bg-primary-main px-2 py-1">
              <span className="line-clamp-1 text-nowrap text-xs leading-3 text-black">
                {promo}
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default AddPromo
