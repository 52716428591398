import { type ReactElement, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import LoginSignup from '@/components/LoginSignup/LoginSignup'
import SnackBar from '@/components/Global/SnackBar/SnackBar'
import Loading from '@/components/Global/Loading/Loading'

import navbarStore from '@/store/navbar'
import deviceStore from '@/store/device'
import userStore from '@/store/user'
import routingStore from '@/store/routing'

import { GoHomeFill } from 'react-icons/go'

function LoginSignupPage(): ReactElement {
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const bgImageId = Math.floor(Math.random() * 10) + 1

  const { hideNavbar } = navbarStore()
  const { isMobile } = deviceStore()
  const { userIsLogged } = userStore()
  const { isPinging } = routingStore()

  const navigate = useNavigate()

  useEffect(() => {
    hideNavbar()
  }, [])

  useEffect(() => {
    if (userIsLogged) navigate('/')
  }, [userIsLogged])

  if (isPinging) return <Loading hidden={false} />
  return (
    <div className="relative flex min-h-full w-full flex-col items-center justify-center">
      {isMobile && (
        <video
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            display: 'block',
            width: '100vw',
            height: '100vh',
            objectFit: 'cover',
          }}
          src={`https://gandaya.b-cdn.net/bg-videos/v${bgImageId}.mp4`}
          autoPlay
          loop
          muted
          playsInline
          className="-z-10 bg-black blur-md brightness-75"
        />
      )}
      <div className="flex h-full w-[90%] flex-col items-start justify-center gap-4 py-8">
        <div
          className="flex items-center justify-center gap-4 rounded-xl bg-dark-black px-4 py-2 hover:cursor-pointer"
          onClick={() => {
            navigate('/')
          }}
        >
          <GoHomeFill color="white" />
          <span className="text-white">Home</span>
        </div>
        <div className="flex w-full flex-col justify-center rounded-xl bg-dark-white">
          <LoginSignup
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
          />
        </div>
      </div>
      <div className="absolute bottom-0 z-30 w-[90%]">
        <SnackBar showSnackBar={isError} message={errorMessage} offSet={20} />
      </div>
    </div>
  )
}

export default LoginSignupPage
