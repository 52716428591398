import {
  type ReactElement,
  useEffect,
  useRef,
  useState,
  type SyntheticEvent,
} from 'react'
import { twMerge } from 'tailwind-merge'

import { type IUserSticker } from '@/types/global/sticker'

interface MemoriesProps {
  maxCoordY: number
  editStickers: boolean
  gluedStickers?: IUserSticker[]
  setAllImagesLoaded: (allImagesAreLoaded: boolean) => void
}

type IImgRatios = Record<
  number,
  {
    width: number
    height: number
  }
>

function Stickers({
  maxCoordY,
  editStickers,
  gluedStickers,
  setAllImagesLoaded,
}: MemoriesProps): ReactElement {
  const [numberOfLoadedImages, setNumberOfLoadedImages] = useState(0)
  const [imgRatios, setImgRatios] = useState<IImgRatios>({})
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (divRef.current !== null) divRef.current.style.height = `${maxCoordY}px`
  }, [maxCoordY, divRef])

  useEffect(() => {
    if (numberOfLoadedImages === gluedStickers?.length) setAllImagesLoaded(true)
  }, [numberOfLoadedImages, setAllImagesLoaded])

  const handleImageLoad = (
    id: number,
    event: SyntheticEvent<HTMLImageElement, Event>,
  ): void => {
    const img = event.target as HTMLImageElement
    const currentWidth = img.naturalWidth
    const currentHeight = img.naturalHeight

    const diagonalReference = 350

    const currentDiagonal = Math.sqrt(currentHeight ** 2 + currentWidth ** 2)

    const diagonalRatio = diagonalReference / currentDiagonal
    const widthRatio = currentWidth * diagonalRatio
    const heightRatio = currentHeight * diagonalRatio

    setImgRatios((prev) => ({
      ...prev,
      [id]: {
        width: widthRatio,
        height: heightRatio,
      },
    }))
  }

  return (
    <div className="relative w-full" ref={divRef}>
      {gluedStickers?.map((sticker) => {
        let widthRatio = 0
        let heightRatio = 0
        let render = false
        if (imgRatios[sticker.id] !== undefined) {
          widthRatio = imgRatios[sticker.id].width
          heightRatio = imgRatios[sticker.id].height
          render = true
        }

        return (
          <img
            key={sticker.id}
            src={`${import.meta.env.VITE_S3 as string}/${
              sticker.imageKey ?? ''
            }`}
            alt="sticker"
            className={twMerge(
              'absolute aspect-auto transition-all duration-200 ease-in-out ',
              editStickers && 'brightness-50',
              !render && 'hidden',
            )}
            style={{
              top: `${sticker.yPos}px`,
              left: `${sticker.xPos * 100}%`,
              rotate: `${sticker.rotation ?? 0}deg`,
              transformOrigin: '0% 0%',
              width: `${widthRatio}px`,
              height: `${heightRatio}px`,
            }}
            onLoad={(e) => {
              handleImageLoad(sticker.id, e)
              setNumberOfLoadedImages((prev) => prev + 1)
            }}
          />
        )
      })}
    </div>
  )
}

export default Stickers
