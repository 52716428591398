import { type ReactElement, useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'

import Footer from '@/components/Global/Footer/Footer'
import TicketSummary from '@/components/Global/TicketSummary/TicketSummary'
import PaymentSelector from '@/components/PaymentSelector/PaymentSelector'
import PixPayment from '@/components/PixPayment/PixPayment'
import SnackBar from '@/components/Global/SnackBar/SnackBar'
import Loading from '@/components/Global/Loading/Loading'
import CheckoutSuccessModal from '@/components/CheckoutSuccessModal/CheckoutSuccessModal'
import UserCard from '@/components/Marketplace/UserCard/UserCard'
import EventThumbnail from '@/components/Event/EventThumbnail/EventThumbnail'

import { usePostCheckout } from '@/hooks/api/useCheckout'
import { useGetOrderDetailsByOrderCode } from '@/hooks/api/useOrder'
import { useGetPaymentById } from '@/hooks/api/usePayment'

import userStore from '@/store/user'
import routingStore from '@/store/routing'

import { datesToFormat } from '@/helpers/formatDate'
import { isTruthy } from '@/helpers/validation'

import {
  CANT_CREATE_PAYMENT,
  PAYMENT_NOT_APPROVED_ERROR,
  ORDER_RESERVED,
  PAYMENT_VALIDATION_ERROR,
  ORDER_NOT_AVAILABLE,
  ORDER_ALREADY_SOLD,
} from '@/errors'

interface IndividualPaymentFees {
  type: string
  absolute: number
  percentage: number
}

function OrderDetails(): ReactElement {
  const [isError, setIsError] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const [pixCopied, setPixCopied] = useState(false)
  const [pixCode, setPixCode] = useState('')
  const [isLoadingVerify, setIsLoadingVerify] = useState(false)
  const [snackBarShown, setSnackBarShown] = useState(false)

  const [showPix, setShowPix] = useState(false)
  const [showTicketDetails, setShowTicketDetails] = useState(true)
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false)
  const [paymentId, setPaymentId] = useState(0)
  const [total, setTotal] = useState(0)

  const { username, userIsLogged } = userStore()
  const { setShowLogin, setToPath } = routingStore()

  const footerRef = useRef<HTMLDivElement>(null)
  const ticketsDivRef = useRef<HTMLDivElement>(null)

  const navigate = useNavigate()
  const { orderCode } = useParams()

  const { orderDetailsData, isLoading } = useGetOrderDetailsByOrderCode(
    orderCode ?? '',
  )
  const {
    createPaymentPix,
    createPaymentCard,
    createPaymentIntlCard,
    isLoading: isLoadingCheckout,
  } = usePostCheckout(
    orderDetailsData?.eventDetails.eventId ?? 0,
    undefined,
    orderDetailsData?.id ?? 0,
  )
  const { getPaymentById } = useGetPaymentById()

  const paymentMethods = orderDetailsData?.paymentMethods.reduce(
    (acc, { type }) => ({
      ...acc,
      [type]: true,
    }),
    {
      PIX: false,
      CREDITCARD: false,
      BOLETO: false,
      INTL_CREDITCARD: false,
    },
  )

  function getOrderFees(): IndividualPaymentFees[] {
    if (orderDetailsData === undefined) return []
    const pixFee =
      orderDetailsData?.paymentMethods.find((method) => method.type === 'PIX')
        ?.fee ?? 0
    const creditCardFee =
      orderDetailsData?.paymentMethods.find(
        (method) => method.type === 'CREDITCARD',
      )?.fee ?? 0
    const intlCreditCardFee =
      orderDetailsData?.paymentMethods.find(
        (method) => method.type === 'INTL_CREDITCARD',
      )?.fee ?? 0

    const absPixFee = orderDetailsData?.price * pixFee
    const absCreditCardFee = orderDetailsData?.price * creditCardFee

    const total = orderDetailsData?.price

    const fees = [
      {
        type: 'PIX',
        absolute: absPixFee,
        percentage: Math.round((absPixFee / total) * 1000) / 10,
      },
      {
        type: 'BOLETO',
        absolute: 0,
        percentage: 0,
      },
      {
        type: 'CREDITCARD',
        absolute: absCreditCardFee,
        percentage: Math.round((absCreditCardFee / total) * 1000) / 10,
      },
      {
        type: 'INTL_CREDITCARD',
        absolute: absCreditCardFee,
        percentage: Math.round((intlCreditCardFee / total) * 1000) / 10,
      },
    ]
    return fees
  }

  async function handleClick(): Promise<void> {
    if (selectedPayment === 'PIX') {
      const response = await createPaymentPix()
      if (response.status === 200) {
        setShowTicketDetails(false)
        setTimeout(setShowPix, 500, true)
        setPixCode(response.code ?? '')
        setPaymentId(response.paymentId ?? 0)
      } else {
        if (response.status === 406) setErrorMsg(ORDER_RESERVED)
        else setErrorMsg(CANT_CREATE_PAYMENT)
        setIsError(true)
        setTimeout(setIsError, 3000, false)
      }
    } else if (selectedPayment === 'CREDITCARD') {
      const response = await createPaymentCard()
      if (response.status === 200) {
        window.open(response.redirectUrl ?? '', '_blank')
      } else {
        setErrorMsg(CANT_CREATE_PAYMENT)
        setIsError(true)
        setTimeout(setIsError, 3000, false)
      }
    } else if (selectedPayment === 'INTL_CREDITCARD') {
      const response = await createPaymentIntlCard()
      if (response.status === 200) {
        window.open(response.redirectUrl ?? '', '_blank')
      } else {
        setErrorMsg(CANT_CREATE_PAYMENT)
        setIsError(true)
        setTimeout(setIsError, 3000, false)
      }
    }
  }

  //  Defines a padding bottom to the tickets div to avoid the footer to overlap the tickets
  const setPaddingBottom = (): void => {
    if (footerRef.current !== null && ticketsDivRef.current !== null) {
      const footerElement = footerRef.current
      const ticketsDivElement = ticketsDivRef.current
      const paddingBottom = footerElement.clientHeight
      ticketsDivElement.style.paddingBottom = `${paddingBottom}px`
    }
  }

  async function handleVerifyPayment(): Promise<boolean> {
    setIsLoadingVerify(true)
    const response = await getPaymentById(Number(paymentId))
    setIsLoadingVerify(false)
    if (response.status === 200 && response.paymentData !== undefined) {
      if (response.paymentData.approved) {
        setShowPaymentSuccessModal(true)
        return true
      } else {
        setErrorMsg(PAYMENT_NOT_APPROVED_ERROR)
        setIsError(true)
        setTimeout(setIsError, 3000, false)
        return false
      }
    } else {
      setErrorMsg(PAYMENT_VALIDATION_ERROR)
      setIsError(true)
      setTimeout(setIsError, 3000, false)
      return false
    }
  }

  async function copyToClipboard(): Promise<void> {
    setPixCopied(true)
    setTimeout(setPixCopied, 3000, false)
    await navigator.clipboard.writeText(pixCode)
  }

  const ticketsUnavailable =
    orderDetailsData !== undefined &&
    (orderDetailsData?.reserved ||
      orderDetailsData?.sold ||
      !orderDetailsData?.active)

  useEffect(() => {
    setPaddingBottom()
  }, [isLoading])

  useEffect(() => {
    const fees = getOrderFees()
    const selectedFee = fees.find((fee) => fee.type === selectedPayment)
    setTotal((orderDetailsData?.price ?? 0) + (selectedFee?.absolute ?? 0))
  }, [selectedPayment, orderDetailsData])

  useEffect(() => {
    if (orderDetailsData !== undefined && !snackBarShown) {
      if (orderDetailsData?.sold) setErrorMsg(ORDER_ALREADY_SOLD)
      else if (orderDetailsData?.reserved) setErrorMsg(ORDER_RESERVED)
      else if (!orderDetailsData?.active) setErrorMsg(ORDER_NOT_AVAILABLE)
      else {
        return
      }

      setSnackBarShown(true)
      setIsError(true)
      setTimeout(setIsError, 4000, false)
    }
  }, [orderDetailsData, snackBarShown])

  if (isLoading) {
    return <Loading hidden={!isLoading} />
  }
  return (
    <div className="min-h-full bg-background-main">
      <div
        className="relative flex w-full max-w-lg flex-col items-start justify-center gap-4 overflow-hidden px-4 pt-6 "
        ref={ticketsDivRef}
      >
        <h1 className="text-2xl text-white">Marketplace</h1>
        <EventThumbnail
          imageKey={orderDetailsData?.eventDetails.backgroundImageKey ?? ''}
          title={orderDetailsData?.eventDetails.title}
          titleImageKey={orderDetailsData?.eventDetails.titleImageKey}
          date={datesToFormat(
            orderDetailsData?.eventDetails.date ?? '',
            orderDetailsData?.eventDetails.endDate ?? '',
          )}
          location={orderDetailsData?.eventDetails.location ?? ''}
          briefVariant="white"
          imageVariant="large"
        />
        <UserCard
          title="Vendido por:"
          imageKey={orderDetailsData?.sellerDetails.profileImageKey ?? ''}
          username={orderDetailsData?.sellerDetails.username ?? ''}
          name={orderDetailsData?.sellerDetails.name ?? ''}
        />
        <div className="flex w-full flex-col gap-2">
          {orderDetailsData?.tickets.map((ticket) => {
            const title = isTruthy(ticket.batchDescription)
              ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
              : ticket.ticketSpecDescription
            return (
              <TicketSummary
                key={ticket.id}
                title={title}
                quantity={1}
                variant="light"
                counterVariant="dark"
              />
            )
          })}
        </div>
      </div>
      <Footer
        title={
          ticketsUnavailable
            ? `Ingresso${orderDetailsData.tickets.length > 1 ? 's' : ''}`
            : 'Valor total'
        }
        value={
          ticketsUnavailable
            ? `Indisponíve${orderDetailsData.tickets.length > 1 ? 'is' : 'l'}`
            : total
        }
        buttonText={
          showPix ? (pixCopied ? 'Código copiado' : 'Copiar código') : 'Pagar'
        }
        buttonEnabled={
          selectedPayment !== '' && !isLoadingCheckout && !pixCopied
        }
        buttonOnClick={() => {
          if (!userIsLogged) {
            setToPath(`none`)
            setShowLogin(true)
            return
          }
          if (showPix) {
            setPixCopied(true)
            void copyToClipboard()
          } else {
            void handleClick()
          }
        }}
        secondaryDisabled={showPix}
        isLoading={isLoadingCheckout}
        error={<SnackBar showSnackBar={isError} message={errorMsg} />}
        ref={footerRef}
        hideButton={ticketsUnavailable}
      >
        {!ticketsUnavailable ? (
          <AnimatePresence>
            {showTicketDetails && (
              <motion.div
                key="payment-selector"
                initial={{ y: 200 }}
                animate={{ y: 0 }}
                exit={{ y: 200, height: 0 }}
                transition={{ duration: 0.2 }}
                className="-z-20 w-full bg-dark-black px-4 pb-1 pt-4"
              >
                <PaymentSelector
                  selectedPayment={selectedPayment}
                  setSelectedPayment={setSelectedPayment}
                  acceptedPaymentMethods={paymentMethods}
                  fees={getOrderFees()}
                />
              </motion.div>
            )}
            {showPix && (
              <motion.div
                key="pix"
                initial={{ y: 450 }}
                animate={{ y: 0 }}
                exit={{ y: 450 }}
                transition={{ duration: 0.2 }}
                className="-z-20 w-full bg-dark-black px-4 pb-1 pt-4"
              >
                <PixPayment
                  code={pixCode}
                  codeCopied={pixCopied}
                  copyToClipboard={copyToClipboard}
                  isLoadingVerify={isLoadingVerify}
                  handleVerifyPayment={handleVerifyPayment}
                />
              </motion.div>
            )}
          </AnimatePresence>
        ) : (
          <></>
        )}
      </Footer>

      {showPaymentSuccessModal && (
        <CheckoutSuccessModal
          title="Compra realizada"
          onClick={() => {
            setShowPaymentSuccessModal(false)
            navigate(`/memories/${username}`)
          }}
        />
      )}
    </div>
  )
}

export default OrderDetails
