import { useEffect, useState } from 'react'
import useSWR from 'swr'

import fetcher, { api } from '@/services/axios'

import {
  type ITicketStore,
  type ITicketData,
} from '@/types/hooks/api/useTickets'
import { isTruthy } from '@/helpers/validation'

export const useGetTickets = (
  eventAlias: string,
  link?: string,
): {
  ticketsData: ITicketData[] | undefined
  error: string | undefined
  isLoading: boolean
} => {
  let url = `/events/${eventAlias}/tickets`
  if (isTruthy(link)) url = `/events/${eventAlias}/tickets?link=${link!}`

  const { data, error } = useSWR<ITicketData[], string>(url, fetcher)
  const [isLoading, setIsLoading] = useState(true)

  const ticketsData: ITicketData[] | undefined = data ?? undefined

  useEffect(() => {
    if (data !== undefined || error !== undefined) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [data, error])

  return {
    ticketsData,
    error,
    isLoading,
  }
}

export const usePostTicketsCheck = (
  eventId: number,
  tickets: ITicketStore[],
): {
  checkAvailability: () => Promise<boolean>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function checkAvailability(): Promise<boolean> {
    setIsLoading(true)

    const requestBody = {
      eventId,
      products: tickets
        .filter((ticket) => ticket.quantitySelected > 0)
        .map((ticket) => {
          return {
            ticketSpecId: ticket.ticketSpecId,
            quantity: ticket.quantitySelected,
          }
        }),
    }

    try {
      await api.post(`/tickets/check`, requestBody)
      setIsLoading(false)
      return true
    } catch (err) {
      setIsLoading(false)
      return false
    }
  }

  return { checkAvailability, isLoading }
}
