import { useState, type ReactElement, useEffect } from 'react'

import SearchBar from '@/components/Global/SearchBar/SearchBar'
import Spinner from '@/components/Global/Spinner/Spinner'
import LinkUser from '../LinkUser/LinkUser'

import { useGetLinkUsers } from '@/hooks/api/useLinks'

import { type ILinkUser } from '@/types/hooks/api/useLinks'

interface LinkUsersProps {
  linkHash: string
}

export default function LinkUsers({ linkHash }: LinkUsersProps): ReactElement {
  const [searchParam, setSearchParam] = useState('')
  const [renderedUsers, setRenderedUsers] = useState<ILinkUser[]>([])
  const [usersList, setUsersList] = useState<ILinkUser[]>([])
  const [fetchMoreUsers, setFetchMoreUsers] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)

  const { getUsers, isLoading } = useGetLinkUsers()

  async function handleFirstFetch(): Promise<void> {
    const response = await getUsers(linkHash, 0)

    if (response.status === 200) {
      setRenderedUsers(response.users ?? [])
      setUsersList(response.users ?? [])

      if (response.users!.length < 50) {
        setFetchMoreUsers(false)
      }
    }
  }

  async function handleSearchUsers(): Promise<void> {
    const response = await getUsers(linkHash, 0, searchParam)

    if (response.status === 200) {
      setRenderedUsers(response.users ?? [])
    }
  }

  async function handleGetMoreUsers(): Promise<void> {
    const response = await getUsers(linkHash, currentPage, searchParam)

    if (response.status === 200) {
      setRenderedUsers((prev) => [...prev, ...response.users!])
      setUsersList((prev) => [...prev, ...response.users!])
      if (response.users!.length < 50) {
        setFetchMoreUsers(false)
      }
    }
  }

  useEffect(() => {
    void handleFirstFetch()
  }, [])

  useEffect(() => {
    if (searchParam === '') {
      setRenderedUsers(usersList)
    }
  }, [searchParam])

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setCurrentPage((current) => current + 1)
      }
    })

    const loadSentry = document.querySelector('#loadSentry')
    if (loadSentry !== null) intersectionObserver.observe(loadSentry)

    return () => {
      intersectionObserver.disconnect()
    }
  }, [isLoading])

  useEffect(() => {
    if (currentPage > 0) {
      void handleGetMoreUsers()
    }
  }, [currentPage])

  return (
    <div className="flex h-fit w-full flex-col gap-2 pb-14">
      <SearchBar
        variant="primary"
        searchParam={searchParam}
        setSearchParam={setSearchParam}
        isSearching={isLoading}
        placeholder="Pesquisar usuário"
        handleSearch={() => {
          void handleSearchUsers()
        }}
      />
      <div className="flex h-fit w-full flex-col gap-2 p-2 pt-4">
        {renderedUsers.map((user, index) => {
          return <LinkUser user={user} key={index} />
        })}
        {fetchMoreUsers && (
          <div
            id="loadSentry"
            className="mt-8 flex w-full items-center justify-center gap-4 "
          >
            <Spinner
              borderWidth="border-4"
              borderColor="border-primary-main/50"
              bottomBorderColor="border-b-primary-main"
            />
          </div>
        )}
      </div>
    </div>
  )
}
