import { create } from 'zustand'

interface NotificationProps {
  showUnfollowConfirmationModal: boolean
  user: { id: number; username: string } | null
  onUnfollow: () => void
  setShowUnfollowConfirmationModal: (s: boolean) => void
  setUser: (u: { id: number; username: string }) => void
  setOnUnfollow: (onUnf: () => void) => void
}

const notificationStore = create<NotificationProps>()((set) => ({
  showUnfollowConfirmationModal: false,
  user: null,
  onUnfollow: () => null,

  setOnUnfollow: (onUnf: () => void) => {
    set(() => ({ onUnfollow: onUnf }))
  },

  setUser: (u: { id: number; username: string }) => {
    set(() => ({ user: u }))
  },

  setShowUnfollowConfirmationModal: (s: boolean) => {
    set(() => ({ showUnfollowConfirmationModal: s }))
  },
}))

export default notificationStore
