import EventRoot from './EventRoot/EventRoot'
import EventImage from '@/components/Event/EventImage/EventImage'
import EventBrief from '@/components/Event/EventBrief/EventBrief'
import EventReactions from '@/components/Event/EventReactions/EventReactions'
import EventDescription from '@/components/Event/EventDescription/EventDescription'
import EventLineUp from '@/components/Event/EventLineUp/EventLineUp'
import EventPlaylist from '@/components/Event/EventPlaylist/EventPlaylist'
import EventPartners from '@/components/Event/EventPartners/EventPartners'
import EventRSVPs from '@/components/Event/EventRSVPs/EventRSVPs'
import EventBit from '@/components/Event/EventBit/EventBit'
import EventOrganization from '@/components/Event/EventOrganization/EventOrganization'
import Footer from '@/components/Global/Footer/Footer'
import AddPromo from '@/components/Event/AddPromo/AddPromo'
import EventCreateEvent from '@/components/Event/EventCreateEvent/EventCreateEvent'
import EventHosts from '@/components/Event/EventHosts/EventHosts'
import EventInterest from '@/components/Event/EventInterest/EventInterest'
import EventInterestModal from '@/components/Event/EventInterestModal/EventInterestModal'

import EventInfo from '../EventInfo/EventInfo'

const EventBase = {
  Root: EventRoot,
  Image: EventImage,
  Brief: EventBrief,
  Reactions: EventReactions,
  Description: EventDescription,
  LineUp: EventLineUp,
  Playlist: EventPlaylist,
  Info: EventInfo,
  Partners: EventPartners,
  RSVPs: EventRSVPs,
  Organization: EventOrganization,
  Bit: EventBit,
  Footer,
  AddPromo,
  CreateEvent: EventCreateEvent,
  Hosts: EventHosts,
  Interest: EventInterest,
  InterestModal: EventInterestModal,
}

export default EventBase
