import MainEventCard from '@/components/Home/MainEventCard/MainEventCard'
import EventCard from '@/components/Global/EventCard/EventCard'
import Carousel from '@/components/Home/Carousel/Carousel'
import Section from '@/components/Home/Section/Section'

const HomeBase = {
  Main: MainEventCard,
  Card: EventCard,
  Carousel,
  Section,
}

export default HomeBase
