import { type ReactElement, useState, useEffect } from 'react'
import { Stage, Layer } from 'react-konva/lib/ReactKonvaCore'

import Button from '@/components/Global/Button/Button'
import Sticker from '@/components/Memories/Sticker/Sticker'

import { BsXLg } from 'react-icons/bs'

import { type KonvaEventObject } from 'konva/lib/Node'

import navbarStore from '@/store/navbar'

import { usePutGlueSticker } from '@/hooks/api/useStickers'

import { type IUserSticker } from '@/types/global/sticker'

import { GLUE_STICKER_ERROR } from '@/errors'

interface StickerPositionProps {
  x: number
  y: number
  rotation: number
}

interface AddStickerProps {
  maxCoordY: number
  setMaxCoordY: (n: number) => void
  newStickerToGlue: IUserSticker
  addGluedSticker: (s: IUserSticker) => void
  removeNotGluedSticker: (stickerId: number) => void
  exitEditMode: () => void
  setIsError: (p: boolean) => void
  setErrorMessage: (m: string) => void
  refreshProfileData: () => void
}

function AddSticker({
  maxCoordY,
  setMaxCoordY,
  newStickerToGlue,
  addGluedSticker,
  removeNotGluedSticker,
  exitEditMode,
  setIsError,
  setErrorMessage,
  refreshProfileData,
}: AddStickerProps): ReactElement {
  const [selectedId, setSelectedId] = useState(0)
  const [stickerPosition, setStickerPosition] = useState<StickerPositionProps>()

  const { hideNavbar, showNavbar } = navbarStore()

  const { putGlueSticker, isLoading } = usePutGlueSticker()

  // eslint-disable-next-line
  function checkDeselect(e: KonvaEventObject<any>): void {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage()
    if (clickedOnEmpty) {
      setSelectedId(0)
    }
  }

  useEffect(() => {
    hideNavbar()
  }, [])

  const stageWidth = window.innerWidth < 432 ? window.innerWidth : 432

  async function handleGlueSticker(): Promise<void> {
    // 1. postGlueSticker
    // Sucesso: continuar ; Erro: sobe SnackBar
    // 2. Atualizar lista de sticker grudados do componente pai
    // 3. Fechar componente AddSticker pelo pai

    // 1
    const stickerData: IUserSticker = {
      ...newStickerToGlue,
      rotation: stickerPosition?.rotation ?? 0,
      xPos: stickerPosition?.x ?? 0,
      yPos: stickerPosition?.y ?? 0,
    }

    const response = await putGlueSticker(stickerData)

    if (response.status !== 200) {
      refreshProfileData()
      setIsError(true)
      setErrorMessage(GLUE_STICKER_ERROR)
      setTimeout(setIsError, 3000, false)

      showNavbar()
      exitEditMode()
      return
    }

    // 2
    addGluedSticker({
      ...newStickerToGlue,
      xPos: stickerPosition?.x ?? 0,
      yPos: stickerPosition?.y ?? 0,
      rotation: stickerPosition?.rotation ?? 0,
    })
    removeNotGluedSticker(newStickerToGlue.id)
    setMaxCoordY(maxCoordY + 200)

    // 3
    showNavbar()
    exitEditMode()
  }

  return (
    <>
      {newStickerToGlue !== undefined && (
        <Stage
          width={stageWidth}
          height={maxCoordY + 200}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
        >
          <Layer>
            <Sticker
              id={newStickerToGlue.stickerId}
              stickerImageKey={newStickerToGlue.imageKey}
              onSelect={() => {
                setSelectedId(newStickerToGlue.stickerId)
              }}
              isSelected={selectedId === newStickerToGlue.stickerId}
              setStickerPosition={setStickerPosition}
            />
          </Layer>
        </Stage>
      )}
      <div className="fixed bottom-0 mb-4 h-12 w-full px-4">
        <Button
          enabled={!isLoading}
          onClick={() => {
            void handleGlueSticker()
          }}
          text="Colar"
          className="drop-shadow-lg"
          isLoading={isLoading}
        />
      </div>
      <button
        onClick={() => {
          showNavbar()
          exitEditMode()
        }}
        className="fixed right-8 top-8 z-40 flex size-10 items-center justify-center rounded-full"
      >
        <BsXLg color="white" size={20} style={{ strokeWidth: '1px' }} />
      </button>
    </>
  )
}

export default AddSticker
