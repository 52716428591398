import { api } from '@/services/axios'
import { useState } from 'react'
import { type AxiosError, type AxiosResponse } from 'axios'

import {
  type ISignupData,
  type ISignupReturn,
  type ISignupResponse,
  type IVerifyReturn,
} from '@/types/hooks/api/useSignup'

export const usePostSignup = (): {
  postSignup: (data: ISignupData) => Promise<ISignupReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postSignup(data: ISignupData): Promise<ISignupReturn> {
    setIsLoading(true)

    const body = {
      ...data,
      origin: 'KAYA',
    }

    try {
      const response: AxiosResponse<ISignupResponse> = await api.post(
        '/auth/signup',
        body,
      )
      setIsLoading(false)
      return {
        status: 200,
        user: response.data,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { postSignup, isLoading }
}

export const usePostVerifyUsername = (): {
  verifyUsername: (username: string) => Promise<IVerifyReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function verifyUsername(username: string): Promise<IVerifyReturn> {
    setIsLoading(true)

    const reqBody = {
      username,
    }

    try {
      await api.post('users/username/availability', reqBody)
      setIsLoading(false)
      return {
        status: 200,
        isAvailable: true,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
        isAvailable: false,
      }
    }
  }

  return { verifyUsername, isLoading }
}

export const usePostVerifyCPF = (): {
  verifyCPF: (cpf: string) => Promise<IVerifyReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function verifyCPF(cpf: string): Promise<IVerifyReturn> {
    setIsLoading(true)

    const reqBody = {
      cpf,
    }

    try {
      await api.post('/users/cpf/availability', reqBody)
      setIsLoading(false)
      return {
        status: 200,
        isAvailable: true,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
        isAvailable: false,
      }
    }
  }

  return { verifyCPF, isLoading }
}

export const usePostVerifyEmail = (): {
  verifyEmail: (email: string) => Promise<IVerifyReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function verifyEmail(email: string): Promise<IVerifyReturn> {
    setIsLoading(true)

    const reqBody = {
      email,
    }

    try {
      await api.post('/users/email/availability', reqBody)
      setIsLoading(false)
      return {
        status: 200,
        isAvailable: true,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
        isAvailable: false,
      }
    }
  }

  return { verifyEmail, isLoading }
}
