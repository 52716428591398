import Button from '@/components/Global/Button/Button'
import PeopleCounter from '@/components/Event/PeopleCounter/PeopleCounter'
import interestStore from '@/store/interest'

interface EventRSVPsProps {
  openEventInterestModal: () => void
}

export function EventRSVPs({
  openEventInterestModal,
}: EventRSVPsProps): JSX.Element {
  const { viewed } = interestStore()

  return (
    <div className="px-4">
      <div className="w-full rounded-lg bg-dark-black px-4 py-6">
        <PeopleCounter
          isClickable={false}
          className="mb-6"
          variant="dark"
          avatars={5}
          showChevron={false}
          data={viewed}
          openEventInterestModal={openEventInterestModal}
          size="lg"
        />
        <Button
          onClick={openEventInterestModal}
          className="bg-white py-2 text-sm font-semibold"
          enabled
          text="Ver RSVPs"
        />
      </div>
    </div>
  )
}

export default EventRSVPs
