import { type ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'

import { FollowRequestButton } from '@/components/Notifications/FollowRequestButton/FollowRequestButton'

import {
  usePostCreateFollowRequest,
  usePutCancelFollowRequest,
} from '@/hooks/api/Social'

import { snackbarStore } from '@/store/snackbar'
import notificationStore from '@/store/notification'

import { ERROR_TO_CANCEL_FOLLOW, ERROR_TO_CREATE_FOLLOW } from '@/errors'

import {
  type INotification,
  type INotificationFollowRequest,
} from '@/types/hooks/api/useNotifications'

export function NewFollowerNotification({
  notification,
}: {
  notification: INotification
}): ReactElement {
  const [followRequest, setFollowRequest] =
    useState<INotificationFollowRequest>(notification.payload.followRequest)
  const { showError } = snackbarStore()
  const { postCreateFollowRequest, isLoading: isLoadingCreateFollowRequest } =
    usePostCreateFollowRequest()
  const { putCancelFollowRequest, isLoading: isLoadingCancelFollowRequest } =
    usePutCancelFollowRequest()
  const { setOnUnfollow, setShowUnfollowConfirmationModal, setUser } =
    notificationStore()

  async function handleFollowRequestAction(): Promise<void> {
    if (followRequest.status === 'FOLLOWING') {
      setOnUnfollow(() => {
        setFollowRequest({
          status: 'NOT_FOLLOWING',
          id: null,
        })
      })
      setUser({
        id: notification.payload.userId,
        username: notification.payload.username,
      })
      setShowUnfollowConfirmationModal(true)
    }

    if (followRequest.status === 'NOT_FOLLOWING') {
      const response = await postCreateFollowRequest(
        notification.payload.userId,
      )
      if (response.status === 200) {
        setFollowRequest({
          status: 'PENDING',
          id:
            response.followRequestId === undefined
              ? null
              : response.followRequestId,
        })
      } else {
        showError(ERROR_TO_CREATE_FOLLOW)
      }
    }

    if (followRequest.status === 'PENDING') {
      const response = await putCancelFollowRequest(followRequest.id as number)
      if (response.status === 200) {
        setFollowRequest({ status: 'NOT_FOLLOWING', id: null })
      } else {
        showError(ERROR_TO_CANCEL_FOLLOW)
      }
    }
  }

  return (
    <div className="flex w-full items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        <Link
          className="w-full max-w-12 rounded-full"
          to={`/memories/${notification.payload.username}`}
        >
          <img
            src={`${import.meta.env.VITE_S3 as string}/${
              notification.payload.imageKey
            }`}
            alt="Foto de perfil"
            className="block size-12 rounded-full"
          />
        </Link>
        <div className="flex flex-col gap-1.5">
          <p className="text-sm leading-tight text-white ">
            <Link
              to={`/memories/${notification.payload.username}`}
              className="font-bold"
            >
              @{notification.payload.username}
            </Link>{' '}
            começou a seguir você
          </p>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <FollowRequestButton
          isLoading={
            isLoadingCreateFollowRequest || isLoadingCancelFollowRequest
          }
          onClick={handleFollowRequestAction}
          followRequest={followRequest}
        />
      </div>
    </div>
  )
}
