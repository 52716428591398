import { useEffect, useState } from 'react'
import fetcher, { api } from '@/services/axios'
import useSWR from 'swr'
import { type AxiosError } from 'axios'

import {
  type IProfilePictureResponse,
  type IProfilePictureReturn,
  type IDefaultProfilePicture,
} from '@/types/hooks/api/useProfilePicture'

export const useGetDefaultProfilePictures = (): {
  isLoading: boolean
  defaultProfilePictures: IDefaultProfilePicture[] | undefined
} => {
  const [isLoading, setIsLoading] = useState(true)

  const { data, error } = useSWR<IDefaultProfilePicture[], string>(
    `/users/profile-picture/default`,
    fetcher,
  )

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  const defaultProfilePictures = data ?? undefined

  return {
    isLoading,
    defaultProfilePictures,
  }
}

export const usePostNewProfilePicture = (): {
  isLoading: boolean
  postNewProfilePicture: (
    profilePicture: File,
  ) => Promise<IProfilePictureReturn>
} => {
  const [isLoading, setIsLoading] = useState(true)

  async function postNewProfilePicture(
    profilePicture: File,
  ): Promise<IProfilePictureReturn> {
    setIsLoading(true)

    const formData = new FormData()
    formData.append('image', profilePicture)

    try {
      const response = await api.post<IProfilePictureResponse>(
        `/users/profile-picture`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      setIsLoading(false)
      return {
        status: 200,
        imageKey: response.data.imageKey,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return {
    isLoading,
    postNewProfilePicture,
  }
}

export const usePostDefaultProfilePicture = (): {
  postDefaultProfilePicture: (imageId: number) => Promise<IProfilePictureReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)

  async function postDefaultProfilePicture(
    imageId: number,
  ): Promise<IProfilePictureReturn> {
    setIsLoading(true)

    const requestBody = {
      imageId,
    }

    try {
      const response = await api.post<IProfilePictureResponse>(
        `/users/profile-picture/default`,
        requestBody,
      )
      setIsLoading(false)
      return {
        status: 200,
        imageKey: response.data.imageKey,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return {
    postDefaultProfilePicture,
    isLoading,
  }
}
