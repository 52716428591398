import { useState } from 'react'
import useSWR from 'swr'

import fetcher, { api } from '@/services/axios'

import {
  type INotification,
  type IFollowRequest,
  type IFollowRequestReturn,
} from '@/types/hooks/api/useNotifications'
import { type AxiosError } from 'axios'

export const useGetFollowRequests = (): {
  getFollowRequests: () => Promise<IFollowRequestReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function getFollowRequests(): Promise<IFollowRequestReturn> {
    setIsLoading(true)

    try {
      const response = await api.get<IFollowRequest[]>(
        `/social/follow/requests`,
      )
      setIsLoading(false)
      return {
        status: 200,
        followRequests: response.data,
      }
    } catch (err) {
      setIsLoading(false)
      const error = err as AxiosError
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return {
    getFollowRequests,
    isLoading,
  }
}

export const useGetNotificationHistory = (): {
  notificationHistoryData: INotification[] | undefined
  error: string | undefined
  isLoading: boolean
  refresh: () => Promise<void>
} => {
  const { data, error, isLoading, mutate } = useSWR<INotification[], string>(
    `/users/notifications/history`,
    fetcher,
  )

  async function refresh(): Promise<void> {
    await mutate()
  }

  return {
    notificationHistoryData: data,
    error,
    isLoading,
    refresh,
  }
}
