import { useState, type ReactElement, useEffect } from 'react'

import { BsEyeSlash, BsEye } from 'react-icons/bs'

import userStore from '@/store/user'

import { useGetBalance } from '@/hooks/api/useWallet'

import { numberToReais } from '@/helpers/formatNumber'

function AccountBalance(): ReactElement {
  const [showCash, setShowCash] = useState(false)

  const { setBalance } = userStore()

  const { balance } = useGetBalance()

  useEffect(() => {
    if (balance !== undefined) setBalance(balance)
  }, [balance])

  return (
    <>
      <span className="text-sm font-semibold text-dark-dark-gray">
        Saldo disponível
      </span>
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-8">
          <span className="text-3xl font-bold text-white">{`${
            showCash && balance !== undefined
              ? numberToReais(balance, 2)
              : 'R$ •••••••'
          }`}</span>
          {balance !== undefined && balance > 0 && (
            <button
              className="flex items-center gap-2 rounded-full border border-white bg-transparent px-4 py-0.5 text-dark-black"
              onClick={() => {
                const msg = 'Olá! Gostaria de sacar o meu saldo disponível.'
                const url = `https://wa.me/${
                  import.meta.env.VITE_SUPPORT_PHONE as string
                }?text=${msg}`
                window.open(url, '_blank')
              }}
            >
              <span className="h-fit rounded-full text-center text-sm font-bold text-white">
                Sacar
              </span>
            </button>
          )}
        </div>

        <div
          className="hover:cursor-pointer"
          onClick={() => {
            setShowCash(!showCash)
          }}
        >
          {showCash ? (
            <BsEyeSlash color="white" size={32} />
          ) : (
            <BsEye color="white" size={32} />
          )}
        </div>
      </div>
    </>
  )
}

export default AccountBalance
