import { type ReactElement, createElement } from 'react'
import { twMerge } from 'tailwind-merge'

interface ListProps {
  children: Array<ReactElement<{ name: string }>>
  hasFade?: boolean
  fromFade?: string
  toFade?: string
}

function List({
  children,
  hasFade = true,
  fromFade = '',
  toFade = '',
}: ListProps): ReactElement {
  return (
    <div className="relative">
      <div
        className={twMerge(
          'flex max-h-48 flex-col gap-2 overflow-y-scroll',
          hasFade && children.length > 3 ? 'py-4' : '',
        )}
      >
        {children.map((child, index) => {
          return createElement(child.type, {
            ...{
              ...child.props,
              key: index,
            },
          })
        })}
      </div>
      {hasFade && children.length > 3 && (
        <>
          <div
            className={twMerge(
              'absolute left-0 top-0 z-10 h-4 w-full bg-gradient-to-b to-transparent',
              fromFade,
            )}
          />
          <div
            className={twMerge(
              'absolute bottom-0 left-0 z-10 h-4 w-full bg-gradient-to-b from-transparent',
              toFade,
            )}
          />
        </>
      )}
    </div>
  )
}

export default List
