import { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

interface EventHostsProps {
  hosts?: Array<{
    id: number
    email: string
    firstName: string
    lastName: string
    profileImageKey: string
    username: string
    visibility: 'VISIBLE' | 'HIDDEN'
  }>
}

export default function EventHosts({ hosts }: EventHostsProps): ReactElement {
  const navigate = useNavigate()

  return (
    <div className="flex h-fit w-full flex-col rounded-lg bg-dark-black p-2">
      {hosts
        ?.filter((host) => host.visibility !== 'HIDDEN')
        .map((host) => {
          return (
            <button
              key={host.id}
              className="flex w-full items-center justify-start gap-3 rounded-md p-2 hover:hover:bg-background-main"
              onClick={() => {
                navigate(`/memories/${host.username}`)
              }}
            >
              <img
                src={`${import.meta.env.VITE_S3 as string}/${
                  host.profileImageKey
                }`}
                alt="Host image"
                className="size-11 rounded-full"
              />
              <div className="flex flex-col items-start">
                <span className="text-xs text-text-secondary">Host</span>
                <span className="text-sm font-black text-white">
                  {host.firstName} {host.lastName}
                </span>
              </div>
            </button>
          )
        })}
    </div>
  )
}
