import { type ReactElement } from 'react'

import TicketCounter from '@/components/Global/TicketCounter/TicketCounter'

import { type ILinkUser } from '@/types/hooks/api/useLinks'

interface LinkUserProps {
  user: ILinkUser
}

export default function LinkUser({ user }: LinkUserProps): ReactElement {
  return (
    <div className="flex w-full items-center gap-2">
      <img
        src={`${import.meta.env.VITE_S3 as string}/${user.profileImageKey}`}
        alt="User"
        className="aspect-square size-8 rounded-full"
      />

      <div className="flex w-full flex-col">
        <span className="line-clamp-1 text-sm font-bold leading-4 text-white">
          {user.firstName} {user.lastName}
        </span>
        <span className="text-xs leading-3 text-white">@{user.username}</span>
      </div>

      <div>
        <TicketCounter
          value={user.quantity}
          direction={1}
          variant="light"
          size="size-8"
          textSize="text-sm"
        />
      </div>
    </div>
  )
}
