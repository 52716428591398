import { useEffect, useState, useRef, type ReactElement } from 'react'
import { useNavigate } from 'react-router'

import { FiClock } from 'react-icons/fi'
import { MdPix } from 'react-icons/md'
import { LuCopy } from 'react-icons/lu'
import { BsCheckLg } from 'react-icons/bs'

import Button from '../Global/Button/Button'

interface PixPaymentProps {
  code: string
  codeCopied: boolean
  isLoadingVerify: boolean
  handleVerifyPayment: () => Promise<boolean>
  copyToClipboard: () => Promise<void>
}

function PixPayment({
  code,
  codeCopied,
  isLoadingVerify,
  copyToClipboard,
  handleVerifyPayment,
}: PixPaymentProps): ReactElement {
  const [elapsedTime, setElapsedTime] = useState(0)
  const [finalPaymentCheck, setFinalPaymentCheck] = useState(false)
  const linkRef = useRef<HTMLSpanElement>(null)

  const navigate = useNavigate()

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime((prevElapsedTime) => prevElapsedTime + 1)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const totalDuration = 5 * 60 // 5 minutes
  let remainingTime = totalDuration - elapsedTime
  if (remainingTime <= 0) {
    if (!finalPaymentCheck) {
      setFinalPaymentCheck(true)
    }
    remainingTime = 0
  }
  const remainingMinutes = Math.floor(remainingTime / 60)
  const remainingSeconds = remainingTime % 60
  const formattedRemainingTime = `${remainingMinutes}:${remainingSeconds
    .toString()
    .padStart(2, '0')}`

  const loadingBarWidth = (elapsedTime / 300) * 100

  async function handleFinalVerifyPayment(): Promise<void> {
    const paymentSucceeded = await handleVerifyPayment()
    if (!paymentSucceeded) setTimeout(navigate, 3000, -1)
  }

  useEffect(() => {
    if (finalPaymentCheck) {
      void handleFinalVerifyPayment()
    }
  }, [finalPaymentCheck])

  return (
    <div className="flex size-full flex-col gap-8 pb-8">
      <div className="flex w-full flex-col gap-4">
        <div>
          <span className="text-xl font-black text-primary-main">
            {formattedRemainingTime}
          </span>
          <div className="h-2 w-full overflow-hidden rounded-full bg-background-main">
            <div
              style={{ width: `${loadingBarWidth}%` }}
              className="h-2 bg-primary-main transition-[width] duration-1000 ease-linear"
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex size-8 items-center justify-center rounded-full bg-dark-dark-gray">
            <FiClock size={20} color="white" />
          </div>

          <span className="w-[90%] text-sm text-white">
            Reservamos seu ingresso por 5 minutos. Pague agora para garantir
            seus ingressos.
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between gap-2 rounded-lg bg-background-main px-4 py-3">
          <div className="flex w-4/5 flex-col">
            <div className="flex items-center gap-2">
              <MdPix size={16} color="white" />
              <span className="font-bold text-white ">Pix copa e cola</span>
            </div>
            <span
              className="clamp-1 truncate text-dark-dark-gray"
              ref={linkRef}
            >
              {code}
            </span>
          </div>
          <div
            className={
              'transition-color flex h-8 w-8 items-center justify-center rounded-full duration-200 ease-in-out hover:cursor-pointer' +
              (codeCopied ? ' bg-primary-dark' : ' bg-primary-main')
            }
            onClick={() => {
              void copyToClipboard()
            }}
          >
            {codeCopied ? (
              <BsCheckLg size={18} color="white" />
            ) : (
              <LuCopy size={18} color="bg-background-main" />
            )}
          </div>
        </div>
        <div className="flex h-10 items-center justify-between gap-2">
          <span className="flex size-8 items-center justify-center rounded-full bg-dark-dark-gray font-black text-white">
            1
          </span>
          <span className="w-[90%] text-sm text-white">
            Copie o código Pix e acesse o app do banco que usará para realizar o
            pagamento;
          </span>
        </div>

        <div className="flex h-10 items-center justify-between gap-2">
          <span className="flex size-8 items-center justify-center rounded-full bg-dark-dark-gray font-black text-white">
            2
          </span>
          <span className="w-[90%] text-sm text-white">
            Na área do Pix do seu banco, procure ”Pix copia e cola”
          </span>
        </div>

        <div className="flex h-10 items-center justify-between gap-2">
          <span className="flex size-8 items-center justify-center rounded-full bg-dark-dark-gray font-black text-white">
            3
          </span>
          <span className="w-[90%] text-sm text-white">
            Cole o código Pix e faça o pagamento
          </span>
        </div>
        <div className="h-9 w-28">
          <Button
            className="bg-white text-dark-black"
            onClick={() => {
              void handleVerifyPayment()
            }}
            enabled={!isLoadingVerify}
            text="Já paguei"
            isLoading={isLoadingVerify}
          />
        </div>
      </div>
    </div>
  )
}

export default PixPayment
