import { type ReactElement } from 'react'
import { useParams, Route, Routes, useNavigate } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

// Subpages
import EventStory from './EventStory/EventStory'
import EventTickets from './EventTickets/EventTickets'
import EventCheckout from './EventCheckout/EventCheckout'
import EventCheckoutSuccess from './EventCheckoutSuccess/EventCheckoutSuccess'

// Component
import Loading from '@/components/Global/Loading/Loading'
import ProtectedRoute from '@/components/Global/ProtectedRoute/ProtectedRoute'

// Hooks
import { useGetEventByAlias } from '@/hooks/api/useEvent'

// Store
import navbarStore from '@/store/navbar'

// Layouts
import EventLayout from '@/layouts/Event/Event'
import PixelMeta from '@/components/MetaPixel/MetaPixel'

// Helpers
import { isTruthy } from '@/helpers/validation'
import { isEventFinished } from '@/helpers/isEventFinished'

function Event(): ReactElement {
  const { eventAlias } = useParams()

  const navigate = useNavigate()

  const { showNavbar } = navbarStore()

  // Fetch event data
  const { eventData, isLoading, mutate } = useGetEventByAlias(eventAlias ?? '')

  if (isLoading) {
    return <Loading hidden={!isLoading} />
  }
  if (eventData === undefined && !isLoading)
    return (
      <div className="absolute left-0 top-0 z-50 flex size-full flex-col items-center justify-center bg-dark-black p-8">
        <h1 className="mb-8 text-center text-3xl text-white">
          Não encontramos este evento 🙁
        </h1>
        <button
          className="fixed bottom-8 w-4/5 rounded-full bg-primary-main px-8 py-4 font-bold"
          onClick={() => {
            showNavbar()
            navigate('/')
          }}
        >
          Ir para a página inicial
        </button>
      </div>
    )

  const isPastEvent =
    eventData?.date.endDate !== undefined
      ? isEventFinished(eventData?.date.endDate)
      : false

  return (
    <HelmetProvider>
      {isTruthy(eventData?.integrationCredentials) && (
        <PixelMeta pixelId={eventData?.integrationCredentials?.pixelId ?? ''} />
      )}
      <Routes>
        <Route element={<EventLayout eventData={eventData} />}>
          <Route
            index
            element={
              <EventStory
                eventData={eventData}
                refresh={() => {
                  void mutate()
                }}
              />
            }
          />
          <Route
            path="/tickets"
            element={
              <EventTickets isPastEvent={isPastEvent} eventId={eventData?.id} />
            }
          />
          <Route element={<ProtectedRoute />}>
            <Route
              path="/checkout"
              element={
                <EventCheckout
                  isPastEvent={isPastEvent}
                  eventId={eventData?.id}
                />
              }
            />
            <Route
              path="/checkout/success/:paymentId"
              element={<EventCheckoutSuccess />}
            />
          </Route>
        </Route>
      </Routes>
    </HelmetProvider>
  )
}
export default Event
