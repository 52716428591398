import { usePutUserStatus } from '@/hooks/api/Event'
import interestStore from '@/store/interest'
import routingStore from '@/store/routing'
import userStore from '@/store/user'

type UserInterest = 'GOING' | 'INTERESTED' | 'VIEWED'

export function useUserInterest(eventAlias: string): {
  handleUpdateUserInterest: (newStatus: UserInterest) => Promise<void>
} {
  const { refreshData, userStatus, setUserStatus } = interestStore()
  const { userIsLogged } = userStore()
  const { setShowLogin, setToPath } = routingStore()
  const { putUserStatus } = usePutUserStatus(eventAlias)

  const handleUpdateUserInterest = async (
    newStatus: UserInterest,
  ): Promise<void> => {
    if (!userIsLogged) {
      setToPath('none')
      setShowLogin(true)
      return
    }

    const previousStatus = userStatus
    let tempNewStatus = newStatus

    // If the user is already in the status, change it to VIEWED
    if (userStatus === newStatus) {
      tempNewStatus = 'VIEWED'
    }

    setUserStatus(tempNewStatus)
    const response = await putUserStatus(tempNewStatus)

    // Rollback to previous status if some error happen on the update
    if (response.status !== 200) {
      setUserStatus(previousStatus)
    }

    if (refreshData !== undefined) refreshData()
  }

  return { handleUpdateUserInterest }
}
