import { useEffect, type ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import Button from '@/components/Global/Button/Button'
import Spinner from '@/components/Global/Spinner/Spinner'
import InterestUser from '../../InterestUser/InterestUser'

import userStore from '@/store/user'
import interestStore from '@/store/interest'

import { usePutUserStatus } from '@/hooks/api/Event'

interface InterestViewsInterestedProps {
  setPageHeight: (h: number) => void
  newPage: () => void
  noNewUsers: boolean
}

export function InterestViewsInterested({
  setPageHeight,
  newPage,
  noNewUsers,
}: InterestViewsInterestedProps): ReactElement {
  const { id } = userStore()
  const { interested, refreshData, refreshCurrentUserData } = interestStore()
  const { eventAlias } = useParams()

  const { putUserStatus, isLoading } = usePutUserStatus(eventAlias!)

  async function handleUserInterest(): Promise<void> {
    await putUserStatus('INTERESTED')

    if (refreshCurrentUserData !== undefined) refreshCurrentUserData()
    if (refreshData !== undefined) refreshData()
  }

  useEffect(() => {
    if (interested.users.length === 0) {
      setPageHeight(400)
    } else {
      const height = Math.min(506, 140 + interested.users.length * 53)
      setPageHeight(height)
    }
  }, [interested])

  // Infinite scroll handler
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        newPage()
      }
    })

    const loadSentry = document.querySelector('#loadSentryInterestedView')
    if (loadSentry !== null) intersectionObserver.observe(loadSentry)

    return () => {
      intersectionObserver.disconnect()
    }
  }, [isLoading])

  if (interested.users.length === 0)
    return (
      <div className="flex size-full flex-col items-center justify-evenly gap-4">
        <div className="flex flex-col gap-6">
          <span className="text-center font-medium leading-4">
            Ninguém mostrou interesse <br /> (ainda) neste evento 👀
          </span>
          <span className="text-center font-medium leading-4">
            Tá esperando o quê para <br /> ser o primeiro?
          </span>
        </div>

        <div className="h-9 w-2/3">
          <Button
            enabled={!isLoading}
            isLoading={isLoading}
            text="Acho que vou"
            className="border border-dark-black bg-white text-sm"
            onClick={handleUserInterest}
            customSpinnerHeight="h-6"
            customSpinnerWidth="w-6"
          />
        </div>
      </div>
    )
  return (
    <div className="relative size-full max-h-[400px]">
      <div className="flex size-full flex-col items-center gap-2.5 overflow-scroll py-4">
        {interested.users
          .sort((user) => (user.id === id ? -1 : 1))
          .map((user) => {
            return <InterestUser key={user.id} user={user} />
          })}
        {!noNewUsers && (
          <div
            id="loadSentryInterestedView"
            className="my-4 flex w-full items-center justify-center gap-4 "
          >
            <Spinner
              borderWidth="border-4"
              borderColor="border-dark-black/50"
              bottomBorderColor="border-b-dark-black"
            />
          </div>
        )}
      </div>
      <div className="absolute left-0 top-0 h-6 w-full bg-gradient-to-b from-white to-transparent" />
      <div className="absolute bottom-0 left-0 h-6 w-full bg-gradient-to-t from-white to-transparent" />
    </div>
  )
}
