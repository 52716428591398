import { useEffect, type ReactElement, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate, useParams } from 'react-router-dom'

import TicketSummary from '@/components/Global/TicketSummary/TicketSummary'
import SnackBar from '@/components/Global/SnackBar/SnackBar'
import PaymentSelector from '@/components/PaymentSelector/PaymentSelector'
import PixPayment from '@/components/PixPayment/PixPayment'
import CheckoutSuccessModal from '@/components/CheckoutSuccessModal/CheckoutSuccessModal'

import EventBase from '@/compositions/EventBase/EventBase'

import { usePostCheckout } from '@/hooks/api/useCheckout'
import { useGetPaymentById } from '@/hooks/api/usePayment'
import { usePixelTrackAddPaymentInfo } from '@/hooks/pixel'

import ticketsStore from '@/store/tickets'
import userStore from '@/store/user'
import eventStore from '@/store/event'

import { numberToReais } from '@/helpers/formatNumber'
import { isTruthy } from '@/helpers/validation'

import {
  CANT_CREATE_PAYMENT,
  PAYMENT_NOT_APPROVED_ERROR,
  PAYMENT_VALIDATION_ERROR,
  TICKETS_NOT_AVAILABLE_ANYMORE,
} from '@/errors'

interface EventCheckoutProps {
  eventId?: number
  isPastEvent: boolean
}

function EventCheckout({
  eventId,
  isPastEvent,
}: EventCheckoutProps): ReactElement {
  const [total, setTotal] = useState(0)
  const [isError, setIsError] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState<
    'PIX' | 'CREDITCARD' | 'BOLETO' | 'INTL_CREDITCARD' | ''
  >('')
  const [errorMsg, setErrorMsg] = useState('')
  const [pixCopied, setPixCopied] = useState(false)
  const [payTicket, setPayTicket] = useState(false)
  const [pixCode, setPixCode] = useState('')
  const [paymentId, setPaymentId] = useState(0)
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false)
  const [isLoadingVerify, setIsLoadingVerify] = useState(false)
  const [allFreeTickets, setAllFreeTickets] = useState(false)
  const [hasTicketSelected, setHasTicketSelected] = useState(false)
  const [isModalClosing, setIsModalClosing] = useState(false)

  const navigate = useNavigate()
  const { eventAlias } = useParams()

  const { tickets, getTotal, getFees } = ticketsStore()
  const { username } = userStore()
  const { setTicketQuantity, integration } = eventStore()

  const {
    createPaymentPix,
    createPaymentCard,
    createPaymentIntlCard,
    reserveFreeTicket,
    isLoading,
  } = usePostCheckout(eventId ?? 0, tickets)
  const { getPaymentById } = useGetPaymentById()

  const { trackAddPaymentInfo } = usePixelTrackAddPaymentInfo()

  useEffect(() => {
    setTotal(getTotal())
    const ticketsQuantity = tickets.reduce(
      (acc, ticket) => acc + ticket.quantitySelected,
      0,
    )
    setTicketQuantity(ticketsQuantity)
  }, [tickets])

  useEffect(() => {
    const fee = getFees().find((fee) => fee.type === selectedPayment)
    if (fee !== undefined) {
      setTotal(getTotal() + fee.absolute)
    }
  }, [selectedPayment])

  async function handleClick(): Promise<void> {
    if (isTruthy(integration?.pixelId ?? ''))
      trackAddPaymentInfo(integration?.pixelId ?? '')
    if (selectedPayment === 'PIX') {
      const response = await createPaymentPix()
      if (response.status === 200) {
        setPayTicket(true)
        setPixCode(response.code ?? '')
        setPaymentId(response.paymentId ?? 0)
      } else {
        if (response.status === 406) {
          setErrorMsg(TICKETS_NOT_AVAILABLE_ANYMORE)
          setTimeout(navigate, 3400, `/events/${eventAlias ?? ''}/tickets`)
        } else {
          setErrorMsg(CANT_CREATE_PAYMENT)
        }
        setIsError(true)
        setTimeout(setIsError, 3000, false)
      }
    } else if (selectedPayment === 'CREDITCARD') {
      const response = await createPaymentCard()
      if (response.status === 200) {
        // window.open(response.redirectUrl ?? '', '_blank')
        window.location.replace(response.redirectUrl ?? '')
      } else {
        setErrorMsg(CANT_CREATE_PAYMENT)
        setIsError(true)
        setTimeout(setIsError, 3000, false)
      }
    } else if (selectedPayment === 'INTL_CREDITCARD') {
      const response = await createPaymentIntlCard()
      if (response.status === 200) {
        // window.open(response.redirectUrl ?? '', '_blank')
        window.location.replace(response.redirectUrl ?? '')
      } else {
        setErrorMsg(CANT_CREATE_PAYMENT)
        setIsError(true)
        setTimeout(setIsError, 3000, false)
      }
    } else if (allFreeTickets) {
      const response = await reserveFreeTicket()
      if (response.status === 200) {
        setPaymentId(response.paymentId ?? 0)
        setShowPaymentSuccessModal(true)
      } else {
        if (response.status === 406) setErrorMsg(TICKETS_NOT_AVAILABLE_ANYMORE)
        else setErrorMsg(CANT_CREATE_PAYMENT)
        setIsError(true)
        setTimeout(setIsError, 3000, false)
        setTimeout(navigate, 3000, `/events/${eventAlias ?? ''}/tickets`)
      }
    }
  }

  // Checking if all selected tickets have the same payment methods
  const acceptedPaymentMethods = {
    PIX: !!tickets
      .filter((ticket) => ticket.quantitySelected > 0 && ticket.finalPrice > 0)
      .every((ticket) =>
        ticket?.paymentMethods?.some((payment) => payment.type === 'PIX'),
      ),
    CREDITCARD: !!tickets
      .filter((ticket) => ticket.quantitySelected > 0 && ticket.finalPrice > 0)
      .every((ticket) =>
        ticket?.paymentMethods?.some(
          (payment) => payment.type === 'CREDITCARD',
        ),
      ),
    INTL_CREDITCARD: !!tickets
      .filter((ticket) => ticket.quantitySelected > 0 && ticket.finalPrice > 0)
      .every((ticket) =>
        ticket?.paymentMethods?.some(
          (payment) => payment.type === 'INTL_CREDITCARD',
        ),
      ),
    BOLETO: !!tickets
      .filter((ticket) => ticket.quantitySelected > 0 && ticket.finalPrice > 0)
      .every((ticket) =>
        ticket?.paymentMethods?.some((payment) => payment.type === 'BOLETO'),
      ),
  }

  async function handleVerifyPayment(): Promise<boolean> {
    setIsLoadingVerify(true)
    const response = await getPaymentById(Number(paymentId))
    setIsLoadingVerify(false)
    if (response.status === 200 && response.paymentData !== undefined) {
      if (response.paymentData.approved) {
        setShowPaymentSuccessModal(true)
        return true
      } else {
        setErrorMsg(PAYMENT_NOT_APPROVED_ERROR)
        setIsError(true)
        setTimeout(setIsError, 3000, false)
        return false
      }
    } else {
      setErrorMsg(PAYMENT_VALIDATION_ERROR)
      setIsError(true)
      setTimeout(setIsError, 3000, false)
      return false
    }
  }

  useEffect(() => {
    const allFree = tickets
      .filter((ticket) => ticket.quantitySelected > 0)
      .every((t) => t.finalPrice === 0)
    setAllFreeTickets(allFree)

    const hasSelected = tickets.some((t) => t.quantitySelected > 0)
    setHasTicketSelected(hasSelected)
  }, [tickets])

  async function copyToClipboard(): Promise<void> {
    setPixCopied(true)
    setTimeout(setPixCopied, 3000, false)
    await navigator.clipboard.writeText(pixCode)
  }

  const footerButtonText = payTicket
    ? pixCopied
      ? 'Código copiado'
      : 'Copiar código'
    : allFreeTickets
      ? 'Reservar'
      : 'Pagar'

  const footerButtonEnabled =
    ((selectedPayment !== '' && !pixCopied) || allFreeTickets) && !isLoading

  const handleFooterButtonClick: () => void = () => {
    if (payTicket) {
      setPixCopied(true)
      void copyToClipboard()
    } else {
      void handleClick()
    }
  }

  return (
    <div className="pb-60">
      <motion.div
        className="w-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
      >
        <div className="flex w-full flex-col gap-2 p-4">
          {!payTicket &&
            tickets.map((ticket) => {
              const title = isTruthy(ticket.batchDescription)
                ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
                : `${ticket.ticketSpecDescription}`

              if (ticket.quantitySelected === 0) return null
              return (
                <TicketSummary
                  key={ticket.ticketSpecId}
                  title={title}
                  quantity={ticket.quantitySelected}
                  thirdInfo={
                    ticket.price === 0
                      ? `Grátis`
                      : `Total: ${numberToReais(
                          ticket.finalPrice * ticket.quantitySelected,
                          2,
                        )}`
                  }
                  variant="light"
                  counterVariant="dark"
                />
              )
            })}
        </div>
      </motion.div>

      <EventBase.Footer
        title="Valor total"
        value={hasTicketSelected ? total : 0}
        buttonText={footerButtonText}
        buttonEnabled={footerButtonEnabled}
        buttonOnClick={handleFooterButtonClick}
        secondaryDisabled={payTicket}
        isLoading={isLoading}
        eventFinished={isPastEvent}
        error={<SnackBar showSnackBar={isError} message={errorMsg} />}
      >
        {!allFreeTickets ? (
          <AnimatePresence mode="wait">
            {!payTicket && (
              <motion.div
                key="payment-selector"
                initial={{ y: 200 }}
                animate={{ y: 0 }}
                exit={{ y: 200, height: 0 }}
                transition={{ duration: 0.2 }}
                className="-z-20 w-full bg-dark-black px-4 pb-1 pt-4"
              >
                <PaymentSelector
                  selectedPayment={selectedPayment}
                  setSelectedPayment={setSelectedPayment}
                  acceptedPaymentMethods={acceptedPaymentMethods}
                  fees={getFees()}
                />
              </motion.div>
            )}
            {payTicket && (
              <motion.div
                key="pix"
                initial={{ y: 450 }}
                animate={{ y: 0 }}
                exit={{ y: 450 }}
                transition={{ duration: 0.2 }}
                className="-z-20 w-full bg-dark-black px-4 pb-1 pt-4"
              >
                <PixPayment
                  code={pixCode}
                  codeCopied={pixCopied}
                  isLoadingVerify={isLoadingVerify}
                  handleVerifyPayment={handleVerifyPayment}
                  copyToClipboard={copyToClipboard}
                />
              </motion.div>
            )}
          </AnimatePresence>
        ) : (
          <></>
        )}
      </EventBase.Footer>
      {showPaymentSuccessModal && (
        <CheckoutSuccessModal
          title={total === 0 ? 'Reserva realizada' : 'Compra realizada'}
          onClick={() => {
            setIsModalClosing(true)
            setTimeout(navigate, 300, `/memories/${username}`)
          }}
          isParentClosing={isModalClosing}
        />
      )}
    </div>
  )
}

export default EventCheckout
