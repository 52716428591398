import { type ReactElement } from 'react'

import Modal from '@/components/Global/Modal/Modal'
import DownloadApp from '@/components/Global/DownloadApp/DownloadApp'

interface DownloadAppModalProps {
  closeModal: () => void
  text: string
}

function DownloadAppModal({
  closeModal,
  text,
}: DownloadAppModalProps): ReactElement {
  return (
    <Modal
      closeModal={() => {
        closeModal()
      }}
    >
      <div className="flex flex-col gap-2 p-4">
        <span className="text-3xl font-bold">Baixe o nosso App</span>
        <span className="leading-4">{text}</span>
        <div className="mt-6 flex w-full justify-evenly">
          <DownloadApp
            store="AppStore"
            destiny="COSMOS"
            variant="dark"
            size="small"
          />
          <DownloadApp
            store="PlayStore"
            destiny="COSMOS"
            variant="dark"
            size="small"
          />
        </div>
      </div>
    </Modal>
  )
}

export default DownloadAppModal
