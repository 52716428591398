import { create } from 'zustand'

import { type IWalletTicketGroup } from '@/types/hooks/api/useWallet'

interface walletStoreProps {
  walletEvents: IWalletTicketGroup[] | undefined
  setWalletEvents: (walletEvents: IWalletTicketGroup[]) => void
  isLoadingWalletFetch: boolean
  refresh: (() => Promise<void>) | undefined
  setRefresh: (refresh: () => Promise<void>) => void
  setIsLoadingWalletFetch: (isLoading: boolean) => void
  currentWalletEvent: IWalletTicketGroup | undefined
  setCurrentEvent: (walletEvent: IWalletTicketGroup) => void
}

const walletStore = create<walletStoreProps>()((set) => ({
  walletEvents: undefined,
  setWalletEvents: (walletEvents) => {
    set(() => ({
      walletEvents,
    }))
  },
  isLoadingWalletFetch: false,
  refresh: undefined,
  setRefresh: (refresh) => {
    set(() => ({
      refresh,
    }))
  },
  setIsLoadingWalletFetch: (isLoading) => {
    set(() => ({
      isLoadingWalletFetch: isLoading,
    }))
  },
  currentWalletEvent: undefined,
  setCurrentEvent: (walletEvent) => {
    set(() => ({
      currentWalletEvent: walletEvent,
    }))
  },
}))

export default walletStore
