import { type ReactElement } from 'react'
import { tv } from 'tailwind-variants'

import logoAppleBlack from '@/assets/images/logoAppleBlack.svg'
import logoAppleWhite from '@/assets/images/logoAppleWhite.svg'
import logoPlayStore from '@/assets/images/logoPlayStore.svg'

interface DownloadAppProps {
  store: 'PlayStore' | 'AppStore'
  destiny: 'VALIDATOR' | 'COSMOS'
  variant: 'light' | 'dark'
  size: 'small' | 'large'
}

function DownloadApp({
  store,
  destiny,
  variant = 'dark',
  size = 'small',
}: DownloadAppProps): ReactElement {
  const containerVariant = tv({
    base: 'flex p-4 rounded-lg size-fit items-center',
    variants: {
      background: {
        dark: 'bg-black text-white',
        light: 'bg-white text-black',
      },
      size: {
        large: 'gap-4',
        small: 'gap-2',
      },
    },
  })

  const imageVariant = tv({
    base: 'aspect-square',
    variants: {
      size: {
        large: 'size-12',
        small: 'size-8',
      },
    },
  })

  const downloadSpanVariant = tv({
    base: 'leading-3',
    variants: {
      size: {
        small: 'text-xs leading-3',
        large: 'text-sm leading-3',
      },
    },
  })

  const storeSpanVariant = tv({
    base: 'text-xs font-bold',
    variants: {
      size: {
        small: 'text-xl leading-4',
        large: 'text-2xl leading-5',
      },
    },
  })

  const logo =
    store === 'PlayStore'
      ? logoPlayStore
      : variant === 'dark'
        ? logoAppleWhite
        : logoAppleBlack

  let storeUrl = ''
  if (destiny === 'VALIDATOR') {
    if (store === 'PlayStore')
      storeUrl = import.meta.env.VITE_PLAY_STORE_VALIDATOR as string
    if (store === 'AppStore')
      storeUrl = import.meta.env.VITE_APP_STORE_VALIDATOR as string
  }

  if (destiny === 'COSMOS') {
    if (store === 'PlayStore')
      storeUrl = import.meta.env.VITE_PLAY_STORE_COSMOS as string
    if (store === 'AppStore')
      storeUrl = import.meta.env.VITE_APP_STORE_COSMOS as string
  }

  return (
    <a
      className={containerVariant({ background: variant, size })}
      href={storeUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={logo} alt="Store logo" className={imageVariant({ size })} />
      <div className="flex flex-col items-start justify-center">
        <span className={downloadSpanVariant({ size })}>Baixar na</span>
        <span className={storeSpanVariant({ size })}>{store}</span>
      </div>
    </a>
  )
}

export default DownloadApp
