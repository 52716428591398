import { useState, type ReactElement, useEffect } from 'react'

import Modal from '@/components/Global/Modal/Modal'
import { Slider } from '../Slider/Slider'
import { ItemStatus } from '../ItemStatus/ItemStatus'

import {
  useGetRefund,
  useGetRefundRequirements,
} from '@/hooks/api/useEventPayment'

interface ConfirmCancellationModalProps {
  paymentId: number
  closeModal: () => void
  isParentClosing: boolean
  refreshData: () => void
}

interface IRequirements {
  noTicketValidatedTransferedOrSold: boolean
  eventMoreThan24Hours: boolean
  purchaseLessThan7Days: boolean
}

export function ConfirmCancellationModal({
  paymentId,
  closeModal,
  isParentClosing,
  refreshData,
}: ConfirmCancellationModalProps): ReactElement {
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackBarVariant, setSnackBarVariant] = useState<
    'information' | 'error'
  >('error')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [requirements, setRequirements] = useState<IRequirements>({
    noTicketValidatedTransferedOrSold: false,
    eventMoreThan24Hours: false,
    purchaseLessThan7Days: false,
  })

  const { refund, isLoading: isLoadingRefund } = useGetRefund()
  const { refundRequirements, isLoading: isLoadingRefundRequirements } =
    useGetRefundRequirements(paymentId)

  async function handleRefund(): Promise<void> {
    setSnackBarVariant('information')
    setShowSnackBar(true)
    setSnackBarMessage(
      'Esse processo pode demorar alguns instantes, por favor não recarregue nem feche essa página.',
    )

    const response = await refund(paymentId ?? 0)
    if (response.status === 200) {
      refreshData()
      closeModal()
      return
    }

    setShowSnackBar(false)
    setTimeout(setSnackBarVariant, 400, 'error')
    setTimeout(setShowSnackBar, 400, true)
    setTimeout(
      setSnackBarMessage,
      400,
      'Não foi possível cancelar a compra. Tente novamente.',
    )
    setTimeout(setShowSnackBar, 5400, false)
  }

  useEffect(() => {
    if (refundRequirements !== undefined) {
      const tempNoTicketValidatedTransferedOrSold =
        !refundRequirements.cancelled &&
        !refundRequirements.validated &&
        !refundRequirements.transfered &&
        !refundRequirements.hasSoldOrder

      const tempEventMoreThan24Hours = refundRequirements['24hourWindowEvent']
      const tempPurchaseLessThan7Days = refundRequirements['7dayWindowPurchase']

      setRequirements({
        noTicketValidatedTransferedOrSold:
          tempNoTicketValidatedTransferedOrSold,
        eventMoreThan24Hours: !tempEventMoreThan24Hours,
        purchaseLessThan7Days: tempPurchaseLessThan7Days,
      })
    }
  }, [refundRequirements])

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      showSnackBar={showSnackBar}
      snackBarMessage={snackBarMessage}
      snackBarVariant={snackBarVariant}
    >
      <div className="flex flex-col p-4 pt-6">
        <span className="self-center text-xl font-black">
          Cancelamento de compra
        </span>
        <div className="flex w-full flex-col gap-4 px-4 py-6">
          <span className="text-sm leading-4">
            Para cancelar uma compra, é preciso que <strong>todos</strong> os
            ingressos que foram comprados juntos atendam aos seguintes
            requisitos:
          </span>
          <ol className="flex flex-col gap-1">
            <li className="flex items-center gap-2 text-sm leading-4">
              <ItemStatus
                isLoading={isLoadingRefundRequirements}
                status={requirements.noTicketValidatedTransferedOrSold}
              />{' '}
              Nenhum dos ingressos tenham sido validados, transferidos ou
              vendidos
            </li>
            <li className="flex items-center gap-2 text-sm leading-4">
              <ItemStatus
                isLoading={isLoadingRefundRequirements}
                status={requirements.eventMoreThan24Hours}
              />{' '}
              O evento esteja a mais de 24 horas do momento em que deseja fazer
              o cancelamento
            </li>
            <li className="flex items-center gap-2 text-sm leading-4">
              <ItemStatus
                isLoading={isLoadingRefundRequirements}
                status={requirements.purchaseLessThan7Days}
              />{' '}
              A compra tenha sido realizada menos de 7 dias atrás
            </li>
          </ol>
        </div>
        <Slider
          handleRefund={handleRefund}
          isLoading={isLoadingRefund}
          disabled={
            !requirements.eventMoreThan24Hours ||
            !requirements.purchaseLessThan7Days ||
            !requirements.noTicketValidatedTransferedOrSold
          }
        />
      </div>
    </Modal>
  )
}
