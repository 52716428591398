import { useState, type ReactElement } from 'react'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  type Crop,
} from 'react-image-crop'

import Button from '@/components/Global/Button/Button'

import { BsArrowLeftShort } from 'react-icons/bs'
import { FaFolder } from 'react-icons/fa'

interface CropImageProps {
  resetImageData: () => void
  openFileExplorer: () => void
  setUploadedImageUrl: (url: string) => void
  setUploadedImage: (image: File) => void
  setCropImage: (cropImageState: boolean) => void
  uploadedImageUrl: string
}

function CropImage({
  resetImageData,
  openFileExplorer,
  setUploadedImageUrl,
  setUploadedImage,
  setCropImage,
  uploadedImageUrl,
}: CropImageProps): ReactElement {
  const [crop, setCrop] = useState<Crop>()

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ): Crop {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 80,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>): void {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, 1))
  }

  function handleSubmit(): void {
    // Crop the image and save it somewhere
    if (crop !== undefined) {
      const cropped = crop.width !== undefined && crop.height !== undefined
      if (cropped) {
        const image = new Image()
        image.src = uploadedImageUrl
        image.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = (crop.width / 100) * image.width
          canvas.height = (crop.height / 100) * image.height
          const ctx = canvas.getContext('2d')

          ctx?.drawImage(
            image,
            (crop.x / 100) * image.width,
            (crop.y / 100) * image.height,
            (crop.width / 100) * image.width,
            (crop.height / 100) * image.height,
            0,
            0,
            (crop.width / 100) * image.width,
            (crop.height / 100) * image.height,
          )

          canvas.toBlob((blob) => {
            const file = new File([blob!], 'croppedImage.png')
            setUploadedImage(file)
          })

          const croppedImage = canvas.toDataURL('image/png')
          setUploadedImageUrl(croppedImage)
          setCropImage(false)
        }
      }
    }
  }

  return (
    <>
      <div className="mb-2 flex w-full gap-2">
        <div
          className="flex size-8 items-center justify-center rounded-full bg-dark-black hover:cursor-pointer"
          onClick={resetImageData}
        >
          <BsArrowLeftShort color="white" size={28} />
        </div>
        <div
          className="flex size-8 items-center justify-center rounded-full bg-dark-black hover:cursor-pointer"
          onClick={openFileExplorer}
        >
          <FaFolder color="white" size={16} />
        </div>
      </div>
      <ReactCrop
        crop={crop}
        onChange={(_, percentCrop) => {
          setCrop(percentCrop)
        }}
        aspect={1}
        circularCrop
        className="max-h-[300px] max-w-[300px] rounded-lg"
        minHeight={200}
        minWidth={200}
      >
        <img
          alt="Image to crop"
          src={uploadedImageUrl}
          className="rounded-lg"
          onLoad={onImageLoad}
        />
      </ReactCrop>
      <div className="mt-4 h-10 w-32">
        <Button
          text="Salvar"
          enabled
          onClick={() => {
            handleSubmit()
          }}
        />
      </div>
    </>
  )
}

export default CropImage
