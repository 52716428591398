import { useEffect, useState, type ReactElement } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import EntityCard from '@/components/Claim/EntityCard/EntityCard'
import TicketSummary from '@/components/Global/TicketSummary/TicketSummary'
import CheckoutSuccessModal from '@/components/CheckoutSuccessModal/CheckoutSuccessModal'
import Loading from '@/components/Global/Loading/Loading'
import EventImage from '@/components/Event/EventImage/EventImage'
import SnackBar from '@/components/Global/SnackBar/SnackBar'

import navbarStore from '@/store/navbar'
import userStore from '@/store/user'

import { useClaimTickets } from '@/hooks/api/useClaim'

import { isTruthy } from '@/helpers/validation'
import { datesToFormat } from '@/helpers/formatDate'
import EventBrief from '@/components/Event/EventBrief/EventBrief'
import Footer from '@/components/Global/Footer/Footer'

import { CLAIM_ERROR } from '@/errors'

function Claim(): ReactElement {
  const [successOnAccept, setSuccessOnAccept] = useState(false)
  const [closeModal, setCloseModal] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { hideNavbar } = navbarStore()
  const { username } = userStore()

  const { claimId } = useParams()
  const navigate = useNavigate()

  const {
    claimTickets,
    isLoadingFetchClaim,
    data: claimData,
    isLoadingPostClaim,
  } = useClaimTickets(claimId ?? '')

  async function handleAcceptTickets(): Promise<void> {
    const response = await claimTickets()
    if (response.status === 200) {
      setSuccessOnAccept(true)
    } else {
      setIsError(true)
      setTimeout(setIsError, 3000, false)
      setErrorMessage(CLAIM_ERROR)
    }
  }

  useEffect(() => {
    hideNavbar()
  }, [])

  const hasMultipleTickets =
    claimData?.tickets !== undefined &&
    (claimData?.tickets?.length > 1 || claimData?.tickets[0]?.quantity > 1)

  if (isLoadingFetchClaim) return <Loading hidden={!isLoadingFetchClaim} />
  return (
    <div className="min-h-full bg-background-main">
      <div className="flex flex-col gap-4 p-4 ">
        <div className="flex flex-col gap-1">
          <EventImage
            imageKey={claimData?.event.imageKey ?? ''}
            imgClassName="rounded-lg desktop:rounded-t-[50px]"
          />
          <EventBrief
            title={claimData?.event.title ?? ''}
            titleImage={claimData?.event.titleImageKey ?? ''}
            date={datesToFormat(
              claimData!.event.startDate,
              claimData!.event.endDate,
            )}
            location={claimData?.event.location ?? 'Local a definir'}
          />
        </div>

        <div className="flex flex-col gap-2">
          {claimData?.tickets?.map((ticket) => {
            return (
              <TicketSummary
                key={ticket.ticketSpecId}
                title={
                  isTruthy(ticket.ticketSpecDescription)
                    ? `${ticket.batchDescription} - ${
                        ticket.ticketSpecDescription ?? ''
                      }`
                    : `${ticket.batchDescription}`
                }
                quantity={ticket.quantity}
                counterVariant="dark"
              />
            )
          })}
        </div>
      </div>
      <Footer
        buttonText={
          hasMultipleTickets ? 'Receber ingressos' : 'Receber ingresso'
        }
        buttonEnabled={!isLoadingPostClaim}
        isLoading={isLoadingPostClaim}
        buttonOnClick={() => {
          void handleAcceptTickets()
        }}
      >
        <EntityCard
          title="Enviado por:"
          imageKey={claimData?.organizer.imageKey ?? ''}
          entityName={claimData?.organizer.name ?? ''}
        />
      </Footer>
      {successOnAccept && (
        <CheckoutSuccessModal
          title="Ingresso recebido"
          onClick={() => {
            setCloseModal(true)
            setTimeout(navigate, 300, `/memories/${username}`)
          }}
          isParentClosing={closeModal}
        />
      )}
      <SnackBar showSnackBar={isError} message={errorMessage} />
    </div>
  )
}

export default Claim
