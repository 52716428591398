import { type ReactElement, useState, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { motion, AnimatePresence } from 'framer-motion'

import { BsInfinity } from 'react-icons/bs'

interface TicketCounterProps {
  value: number | undefined
  setValue?: (value: number) => void
  direction: number | undefined
  variant?: string
  isInfinite?: boolean
  canEditInput?: boolean
  size?: string
  textSize?: string
}

type Variants = Record<string, { image: string; text: string }>

const variants = {
  enter: (direction: number) => {
    return {
      y: direction > 0 ? -50 : 50,
    }
  },
  center: {
    y: 0,
  },
  exit: (direction: number) => {
    return {
      y: direction < 0 ? -50 : 50,
    }
  },
}

function TicketCounter({
  value = 0,
  setValue,
  direction,
  isInfinite = false,
  variant = 'light',
  canEditInput = false,
  size = 'size-12',
  textSize = 'text-xl',
}: TicketCounterProps): ReactElement {
  const [isOnFocus, setIsOnFocus] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const styleVariants: Variants = {
    zero: {
      image: ' fill-[#A2A2A2]',
      text: ' text-dark-dark-gray',
    },
    light: {
      image: ' fill-dark-white',
      text: ' text-text-dark',
    },
    dark: {
      image: ' fill-dark-dark-gray',
      text: ' text-white',
    },
    highlighted: {
      image: ' fill-primary-main',
      text: ' text-dark-black',
    },
  }

  const divStyle = twMerge(
    'transition-opacity duration-200 ease-in-out relative flex items-center justify-center overflow-hidden flex-col',
    size,
  )

  const textStyle =
    twMerge(
      'font-bold transition-color duration-200 ease-in-out text-black font-black w-12 text-center outline-none',
      textSize,
    ) + (value === 0 ? styleVariants.zero.text : styleVariants[variant].text)

  const inputStyle =
    'text-xl font-bold transition-all duration-100 ease-in-out text-black w-12 text-center outline-none focus:bg-transparent bg-transparent' +
    styleVariants[variant].text

  return (
    <div
      className={divStyle}
      onClick={() => {
        if (setValue !== undefined && canEditInput) {
          setIsOnFocus(true)
          setTimeout(() => {
            inputRef.current?.focus()
          }, 50)
        }
      }}
    >
      <div>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 50 50"
          className={
            'transition-color duration-100 ease-in-out' +
            (value === 0 && !isOnFocus
              ? styleVariants.zero.image
              : styleVariants[variant].image)
          }
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9815 0H4C1.79086 0 0 1.79086 0 4V46C0 48.2091 1.79086 50 4 50H18.9815V48.8425C18.9815 45.6464 21.5724 43.0554 24.7685 43.0554C27.9646 43.0554 30.5556 45.6464 30.5556 48.8425V50H46C48.2091 50 50 48.2091 50 46V4C50 1.79086 48.2091 0 46 0H30.5556V0.694394C30.5556 3.89049 27.9647 6.48144 24.7686 6.48144C21.5725 6.48144 18.9815 3.89049 18.9815 0.6944V0Z"
          />
        </svg>
      </div>
      {isInfinite ? (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <BsInfinity
            style={{ strokeWidth: '1px', color: '#ffffff' }}
            className="w-6"
          />
        </div>
      ) : (
        <>
          {isOnFocus && setValue !== undefined ? (
            <div className="absolute flex size-full items-center justify-center">
              <input
                className={inputStyle}
                value={value === 0 ? '' : value}
                type="number"
                onChange={(e) => {
                  const onlyNumbers = e.target.value.replace(/\D/g, '')
                  if (onlyNumbers === '') {
                    setValue(0)
                  } else {
                    setValue(parseInt(onlyNumbers))
                  }
                }}
                onBlur={(e) => {
                  setIsOnFocus(false)
                  if (e.target.value === '') {
                    setValue(0)
                  }
                }}
                ref={inputRef}
              />
            </div>
          ) : (
            <AnimatePresence initial={false} custom={direction}>
              <motion.div
                key={value}
                className="absolute flex size-full -translate-x-1/2 -translate-y-1/2 items-center justify-center"
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  y: { duration: 0.1 },
                  ease: 'easeInOut',
                }}
              >
                <span className={textStyle}>{value}</span>
              </motion.div>
            </AnimatePresence>
          )}
        </>
      )}
    </div>
  )
}

export default TicketCounter
