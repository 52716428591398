import { useState, type ReactElement } from 'react'

import Modal from '@/components/Global/Modal/Modal'
import Button from '@/components/Global/Button/Button'

import transferStore from '@/store/transfer'

export function WarningModal(): ReactElement {
  const [modalIsClosing, setModalIsClosing] = useState(false)
  const { transferTickets, setTransferState } = transferStore()

  const numberOfTicketsToTransferAnnounced = transferTickets.filter(
    (ticket) =>
      ticket.orderCode !== '' &&
      ticket.orderCode !== undefined &&
      ticket.isSelected,
  ).length

  function handleClick(newState: 'confirmTransfer' | 'selectTicket'): void {
    setModalIsClosing(true)
    setTimeout(setTransferState, 300, newState)
  }

  return (
    <Modal disableTouchToClose isParentClosing={modalIsClosing}>
      <div className="flex w-full flex-col items-center gap-10 p-4">
        <div className="mt-2 flex items-center justify-center gap-2">
          <div className="flex size-8 items-center justify-center rounded-full bg-tonal-red">
            <span className="text-2xl text-white">!</span>
          </div>
          <h1 className="text-3xl leading-6">Cuidado</h1>
        </div>
        {numberOfTicketsToTransferAnnounced > 1 ? (
          <span className="text-center leading-5">
            Alguns dos ingressos que você quer transferir estão{' '}
            <strong>à venda</strong>. Com esta transferência as vendas serão{' '}
            <strong>canceladas</strong>. Continuar mesmo assim?
          </span>
        ) : (
          <span className="text-center">
            Um dos ingressos que deseja transferir está <strong>à venda</strong>
            . Com esta transferência a venda será <strong>cancelada</strong>.
            Continuar mesmo assim?
          </span>
        )}
        <div className="flex w-full flex-col gap-2">
          <div className="h-12">
            <Button
              enabled
              text="Continuar"
              onClick={() => {
                handleClick('confirmTransfer')
              }}
            />
          </div>
          <div className="h-12">
            <Button
              enabled
              text="Cancelar"
              className="bg-dark-light-gray"
              onClick={() => {
                handleClick('selectTicket')
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
