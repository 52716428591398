import { useState, type ReactElement } from 'react'
import { Link } from 'react-router-dom'

import Button from '@/components/Global/Button/Button'
import { FollowRequestButton } from '@/components/Notifications/FollowRequestButton/FollowRequestButton'

import {
  usePostCreateFollowRequest,
  usePutCancelFollowRequest,
} from '@/hooks/api/Social'
import { usePutDenyFollowRequest } from '@/hooks/api/Social/usePutDenyFollowRequest'
import { usePutAcceptFollowRequest } from '@/hooks/api/Social/usePutAcceptFollowRequest'

import { snackbarStore } from '@/store/snackbar'
import notificationStore from '@/store/notification'

import {
  ERROR_TO_ACCEPT_FOLLOW_REQUEST,
  ERROR_TO_CANCEL_FOLLOW,
  ERROR_TO_CREATE_FOLLOW,
  ERROR_TO_DENY_FOLLOW_REQUEST,
} from '@/errors'

import {
  type INotification,
  type INotificationFollowRequest,
} from '@/types/hooks/api/useNotifications'

interface ActiveFollowRequestProps {
  notification: INotification
  hideNotification: (id: number) => void
}

export function ActiveFollowRequest({
  notification,
  hideNotification,
}: ActiveFollowRequestProps): ReactElement {
  const { showError } = snackbarStore()
  const [followRequest, setFollowRequest] =
    useState<INotificationFollowRequest>(notification.payload.followRequest)
  const [status, setStatus] = useState<'PENDING' | 'ACCEPTED' | 'REJECTED'>(
    'PENDING',
  )

  const { putDenyFollowRequest, isLoading: denyIsLoading } =
    usePutDenyFollowRequest()
  const { putAcceptFollowRequest, isLoading: acceptIsLoading } =
    usePutAcceptFollowRequest()
  const { postCreateFollowRequest, isLoading: createIsLoading } =
    usePostCreateFollowRequest()
  const { putCancelFollowRequest, isLoading: cancelIsLoading } =
    usePutCancelFollowRequest()
  const { setOnUnfollow, setShowUnfollowConfirmationModal, setUser } =
    notificationStore()

  async function handleAcceptFollowRequest(): Promise<void> {
    if (status !== 'PENDING') return
    const response = await putAcceptFollowRequest(notification.payload.id)
    if (response.status === 200) {
      setStatus('ACCEPTED')
    } else {
      showError(ERROR_TO_ACCEPT_FOLLOW_REQUEST)
    }
  }

  async function handleDenyFollowRequest(): Promise<void> {
    if (status !== 'PENDING') return
    const response = await putDenyFollowRequest(notification.payload.id)
    if (response.status === 200) {
      setStatus('REJECTED')
      hideNotification(notification.payload.id)
    } else {
      showError(ERROR_TO_DENY_FOLLOW_REQUEST)
    }
  }

  async function handleFollowRequestAction(): Promise<void> {
    if (followRequest.status === 'FOLLOWING') {
      setOnUnfollow(() => {
        setFollowRequest({
          status: 'NOT_FOLLOWING',
          id: null,
        })
      })
      setUser({
        id: notification.payload.userId,
        username: notification.payload.username,
      })
      setShowUnfollowConfirmationModal(true)
    }

    if (followRequest.status === 'NOT_FOLLOWING') {
      const response = await postCreateFollowRequest(
        notification.payload.userId,
      )
      if (response.status === 200) {
        setFollowRequest({
          status: 'PENDING',
          id:
            response.followRequestId === undefined
              ? null
              : response.followRequestId,
        })
      } else {
        showError(ERROR_TO_CREATE_FOLLOW)
      }
    }

    if (followRequest.status === 'PENDING') {
      const response = await putCancelFollowRequest(followRequest.id as number)
      if (response.status === 200) {
        setFollowRequest({ status: 'NOT_FOLLOWING', id: null })
      } else {
        showError(ERROR_TO_CANCEL_FOLLOW)
      }
    }
  }

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        <Link
          className="w-full max-w-12 rounded-full"
          to={`/memories/${notification.payload.username}`}
        >
          <img
            src={`${import.meta.env.VITE_S3 as string}/${
              notification.payload.imageKey
            }`}
            alt="Foto de perfil"
            className="block size-12 rounded-full"
          />
        </Link>
        <div className="flex flex-col gap-1.5">
          <p className="text-sm leading-tight text-white">
            {status === 'PENDING' && (
              <>
                <Link
                  to={`/memories/${notification.payload.username}`}
                  className="font-bold"
                >
                  @{notification.payload.username}
                </Link>{' '}
                pediu pra seguir você
              </>
            )}
            {status === 'ACCEPTED' && (
              <>
                <span className="font-bold">
                  @{notification.payload.username}
                </span>{' '}
                começou a seguir você
              </>
            )}
          </p>
        </div>
      </div>
      <div className="flex w-full max-w-[106px] items-center justify-between gap-2">
        {status === 'PENDING' && (
          <>
            <Button
              enabled={!(denyIsLoading || acceptIsLoading)}
              isLoading={denyIsLoading || acceptIsLoading}
              className="h-6 w-full rounded-md px-4 py-1.5 text-xs font-bold leading-3"
              onClick={handleAcceptFollowRequest}
              customSpinnerHeight="w-[18px]"
              customSpinnerWidth="h-[18px]"
              text="Aceitar"
            />
            <button
              className="rotate-45 select-none text-4xl text-text-secondary"
              onClick={handleDenyFollowRequest}
            >
              +
            </button>
          </>
        )}
        {status === 'ACCEPTED' && (
          <FollowRequestButton
            isLoading={createIsLoading || cancelIsLoading}
            onClick={handleFollowRequestAction}
            followRequest={followRequest}
          />
        )}
      </div>
    </div>
  )
}
