import {
  type ReactElement,
  useRef,
  useState,
  useEffect,
  isValidElement,
} from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { tv } from 'tailwind-variants'

import { AiFillExclamationCircle } from 'react-icons/ai'
import { IoCheckmarkCircle } from 'react-icons/io5'

interface SnackBarProps {
  showSnackBar?: boolean
  message?: string | ReactElement
  offSet?: number
  variant?: 'error' | 'information' | 'success'
}

function SnackBar({
  showSnackBar,
  message,
  offSet = 10,
  variant = 'error',
}: SnackBarProps): ReactElement {
  const [divOffset, setDivOffset] = useState(0)
  const divHeightRef = useRef<HTMLDivElement>(null)

  const divVariant = tv({
    base: 'absolute left-[5%] top-0 -z-20 flex w-[90%] items-center rounded p-4 text-white drop-shadow-md',
    variants: {
      type: {
        error: 'bg-tonal-red',
        information: 'bg-tonal-green',
        success: 'bg-tonal-green',
      },
    },
  })

  useEffect(() => {
    if (divHeightRef.current != null) {
      setDivOffset(-divHeightRef.current.offsetHeight - offSet)
    }
  }, [showSnackBar, offSet])

  return (
    <AnimatePresence>
      {showSnackBar !== undefined && showSnackBar && (
        <motion.div
          key="snackbar"
          className={divVariant({ type: variant })}
          initial={{ y: 0 }}
          animate={{ y: divOffset }}
          exit={{ y: 0 }}
          transition={{ duration: 0.5, ease: 'backInOut' }}
          ref={divHeightRef}
        >
          {variant === 'error' ? (
            <AiFillExclamationCircle color="white" size={32} />
          ) : (
            <IoCheckmarkCircle color="white" size={32} />
          )}
          {isValidElement(message) ? (
            message
          ) : (
            <p className="ml-2 w-[90%] text-sm ">{message}</p>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default SnackBar
