import { type ReactElement } from 'react'

interface UserCardProps {
  title: string
  imageKey: string
  username: string
  name: string
}

function UserCard({
  title,
  imageKey,
  username,
  name,
}: UserCardProps): ReactElement {
  return (
    <div className="w-full">
      <h1 className="mb-1 text-white">{title}</h1>
      <div className="flex w-full flex-col gap-2 rounded-lg bg-dark-black p-4">
        <div className="flex gap-4">
          <img
            src={`${import.meta.env.VITE_S3 as string}/${imageKey}`}
            alt="Seller picture"
            className="size-12 rounded-md"
          />
          <div className="flex flex-col justify-center">
            <span className="text-md font-bold text-white">@{username}</span>
            <span className="text-md font-regular text-white">{name}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserCard
