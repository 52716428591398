import { type ReactElement, useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { useParams } from 'react-router-dom'

import { ProfileCard } from '@/components/Memories/ProfileCard/ProfileCard'
import Stickers from '@/components/Memories/Stickers/Stickers'
import AddSticker from '@/components/Memories/AddSticker/AddSticker'
import Loading from '@/components/Global/Loading/Loading'
import SnackBar from '@/components/Global/SnackBar/SnackBar'
import NewStickersModal from '@/components/Memories/NewStickersModal/NewStickersModal'
import UserNotFound from '@/components/Memories/UserNotFound/UserNotFound'
import ProfilePictureModal from '@/components/LoginSignup/ProfilePictureModal/ProfilePictureModal'
import SupportModal from '@/components/Memories/SupportModal/SupportModal'
import OptionsModal from '@/components/Memories/OptionsModal/OptionsModal'
import DownloadAppModal from '@/components/Global/DownloadAppModal/DownloadAppModal'

import { useGetProfileData } from '@/hooks/api/useProfile'

import navbarStore from '@/store/navbar'
import userStore from '@/store/user'

import { type IUserSticker } from '@/types/global/sticker'

import { BsGearFill } from 'react-icons/bs'
import { PiStickerDuotone } from 'react-icons/pi'

import { isTruthy } from '@/helpers/validation'

function Memories(): ReactElement {
  const [editStickers, setEditStickers] = useState<boolean>(false)
  const [maxCoordY, setMaxCoordY] = useState<number>(0)
  const [showUngluedStickers, setShowUngluedStickers] = useState<boolean>(true)
  const [newStickerToGlue, setNewStickerToGlue] = useState<IUserSticker>()
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [userNotFound, setUserNotFound] = useState(false)
  const [showProfilePictureModal, setShowProfilePictureModal] = useState(false)
  const [showSupportModal, setShowSupportModal] = useState(false)
  const [showDownloadAppModal, setShowDownloadAppModal] = useState(false)
  const [showOptionsModal, setShowOptionsModal] = useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [allImagesLoaded, setAllImagesLoaded] = useState<boolean>(false)
  const [gluedStickers, setGluedStickers] = useState<IUserSticker[]>([])
  const [notGluedStickers, setNotGluedStickers] = useState<IUserSticker[]>([])

  const { username } = useParams()

  const {
    userProfileData,
    isLoading: isLoadingProfileData,
    refresh,
  } = useGetProfileData(username ?? '')

  const { showNavbar } = navbarStore()
  const { username: usernameStore, imageKey } = userStore()

  const variantsProfileCard = {
    shown: {
      y: 0,
    },
    hidden: {
      y: -250,
    },
  }

  const variantsOverlay = {
    shown: {
      opacity: 0,
    },
    hidden: {
      opacity: 1,
    },
  }

  const isAuthUser = usernameStore === userProfileData?.username

  useEffect(() => {
    if (userProfileData === undefined) {
      setUserNotFound(true)
      return
    }
    setUserNotFound(false)
    if (userProfileData?.stickers !== undefined) {
      const tempGluedStickers = userProfileData?.stickers.filter(
        (sticker) => sticker.glued,
      )

      // Set max value for memories div height
      if (tempGluedStickers === undefined) return

      const tempMaxHeight =
        Math.max(...tempGluedStickers.map((sticker) => sticker.yPos), 500) + 250
      setMaxCoordY(tempMaxHeight)

      setGluedStickers(tempGluedStickers ?? [])
      setNotGluedStickers(
        userProfileData?.stickers?.filter((sticker) => !sticker.glued!) ?? [],
      )
    }
  }, [userProfileData])

  useEffect(() => {
    showNavbar()
  }, [])

  useEffect(() => {
    setShowProfilePictureModal(false)

    if (isTruthy(imageKey) && userProfileData?.imageKey !== imageKey)
      void refresh()
  }, [imageKey])

  if (isLoadingProfileData) return <Loading hidden={!isLoadingProfileData} />
  if (userNotFound) return <UserNotFound />
  return (
    <div
      className={
        'relative size-full min-h-full overflow-x-hidden overflow-y-scroll bg-background-main'
      }
    >
      {!allImagesLoaded && (
        <div>
          <Loading hidden={false} />
        </div>
      )}
      <div className="absolute top-0 z-30 flex w-full items-center justify-between p-4">
        <motion.div
          initial={{ y: -250 }}
          animate={editStickers ? 'hidden' : 'shown'}
          variants={variantsProfileCard}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          className="flex w-full items-center justify-between"
        >
          <div className="flex w-full flex-col gap-2">
            <div className="flex justify-between">
              <div className="w-min rounded-md bg-dark-black px-2 py-0.5">
                <span className="text-xl font-bold text-white">
                  @{userProfileData?.username}
                </span>
              </div>
              {isAuthUser && (
                <div
                  className={
                    'relative z-30 ml-2 text-2xl hover:cursor-pointer' +
                    (notGluedStickers.length > 0
                      ? ' text-text-main'
                      : ' text-text-secondary')
                  }
                  onClick={() => {
                    if (notGluedStickers.length > 0)
                      setShowUngluedStickers(true)
                  }}
                >
                  {notGluedStickers.length > 0 && (
                    <span className=" absolute -left-2 -top-1 flex size-5 items-center justify-center rounded-full bg-tonal-red text-sm font-bold">
                      {notGluedStickers.length}
                    </span>
                  )}
                  <PiStickerDuotone size={32} />
                </div>
              )}
            </div>
            <ProfileCard
              userData={userProfileData}
              isAuthUser={isAuthUser}
              setShowProfilePictureModal={setShowProfilePictureModal}
            />
          </div>
        </motion.div>
      </div>
      <Stickers
        maxCoordY={maxCoordY}
        editStickers={editStickers}
        gluedStickers={gluedStickers}
        setAllImagesLoaded={setAllImagesLoaded}
      />
      {isAuthUser && !editStickers && (
        <BsGearFill
          className="fixed bottom-16 right-4 size-8 text-dark-dark-gray hover:cursor-pointer"
          onClick={() => {
            setShowOptionsModal(true)
          }}
        />
      )}
      {isAuthUser && editStickers && newStickerToGlue !== undefined && (
        <motion.div
          key="add-sticker"
          initial={{ opacity: 0 }}
          animate={editStickers ? 'hidden' : 'shown'}
          variants={variantsOverlay}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          className="absolute left-0 top-0 z-40 w-full"
        >
          <AddSticker
            maxCoordY={maxCoordY}
            setMaxCoordY={setMaxCoordY}
            newStickerToGlue={newStickerToGlue}
            addGluedSticker={(newGluedSticker: IUserSticker) => {
              setGluedStickers((prev) => [...prev, newGluedSticker])
            }}
            removeNotGluedSticker={(newGluedStickerId: number) => {
              setNotGluedStickers((prev) =>
                prev.filter((sticker) => sticker.id !== newGluedStickerId),
              )
            }}
            exitEditMode={() => {
              setEditStickers(false)
              setNewStickerToGlue(undefined)
            }}
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
            refreshProfileData={() => {
              void refresh()
            }}
          />
        </motion.div>
      )}
      {isAuthUser && showUngluedStickers && notGluedStickers.length > 0 && (
        <NewStickersModal
          notGluedStickers={notGluedStickers}
          closeModal={() => {
            setShowUngluedStickers(false)
          }}
          setNewStickerToGlue={setNewStickerToGlue}
          onSelectSticker={() => {
            setEditStickers(true)
          }}
        />
      )}
      <div className="fixed bottom-11 z-10 w-full">
        <SnackBar showSnackBar={isError} message={errorMessage} />
      </div>
      {showProfilePictureModal && (
        <ProfilePictureModal
          closeModal={() => {
            setTimeout(setShowProfilePictureModal, 200, false)
          }}
        />
      )}
      {showSupportModal && (
        <SupportModal
          closeModal={() => {
            setShowSupportModal(false)
          }}
        />
      )}
      {showOptionsModal && (
        <OptionsModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowOptionsModal, 400, false)
          }}
          openSupportModal={() => {
            setTimeout(setShowSupportModal, 400, true)
          }}
          isParentClosing={isParentClosing}
        />
      )}
      {showDownloadAppModal && (
        <DownloadAppModal
          closeModal={() => {
            setShowDownloadAppModal(false)
          }}
          text="Para interagir com esse usuário e acompanhar seus amigos, baixe nosso
          aplicativo!"
        />
      )}
    </div>
  )
}

export default Memories
