import { type ReactElement, forwardRef, type ForwardedRef } from 'react'
import { twMerge } from 'tailwind-merge'

import Spinner from '@/components/Global/Spinner/Spinner'

import { isTruthy } from '@/helpers/validation'
import { AnimatedNumber } from '../AnimatedNumber/AnimatedNumber'

interface FooterProps {
  title?: string
  value?: number | string | undefined
  subtitle?: string
  buttonText: string
  buttonEnabled: boolean
  buttonOnClick: () => void
  isLoading?: boolean
  children?: ReactElement
  error?: ReactElement
  information?: ReactElement
  secondaryDisabled?: boolean
  ref?: ForwardedRef<HTMLDivElement>
  hideButton?: boolean
  eventFinished?: boolean
}

const Footer = forwardRef(
  (
    {
      title,
      value,
      subtitle,
      buttonText,
      buttonEnabled,
      buttonOnClick,
      isLoading = false,
      children,
      error,
      information,
      secondaryDisabled = false,
      hideButton = false,
      eventFinished = false,
    }: FooterProps,
    ref: ForwardedRef<HTMLDivElement>, // Declare the type for ref
  ): ReactElement => {
    const variations = {
      enabled: 'bg-primary-main text-black',
      disabled: secondaryDisabled
        ? 'bg-primary-dark text-white'
        : 'bg-dark-light-gray text-dark-dark-gray',
    }

    return (
      <div className="fixed bottom-0 z-40 w-full" ref={ref}>
        {error}
        {information}
        {children}
        <div className="relative z-20 bg-dark-black p-4 transition-height duration-200 ease-linear">
          <div className="flex items-center justify-between bg-dark-black">
            {!eventFinished && (
              <div className="flex flex-col">
                {isTruthy(title) && (
                  <span className="text-sm leading-5 text-text-secondary">
                    {title}
                  </span>
                )}
                <div className="flex items-end">
                  <div>
                    {typeof value === 'number' && (
                      <AnimatedNumber
                        val={value ?? 0}
                        isMoney
                        className="text-2xl font-extrabold leading-6 text-white"
                      />
                    )}
                    {typeof value === 'string' && (
                      <span className="text-2xl font-extrabold leading-6 text-white">
                        {value}
                      </span>
                    )}
                  </div>
                  {isTruthy(subtitle) && (
                    <span className="ml-2 text-sm leading-3 text-text-secondary">
                      {subtitle}
                    </span>
                  )}
                </div>
              </div>
            )}
            {eventFinished ? (
              <div className="w-full px-1.5">
                <div className="relative flex h-12 w-full items-center justify-center rounded-full border border-[#595959] text-base font-bold text-[#595959] transition-colors duration-200 ease-in-out">
                  Evento encerrado
                </div>
              </div>
            ) : (
              !hideButton && (
                <button
                  className={twMerge(
                    'relative h-12 rounded-full text-lg font-bold transition-colors duration-200 ease-in-out ',
                    variations[buttonEnabled ? 'enabled' : 'disabled'],
                    title === undefined &&
                      value === undefined &&
                      subtitle === undefined
                      ? 'w-full'
                      : 'w-40',
                  )}
                  disabled={!buttonEnabled}
                  onClick={buttonOnClick}
                >
                  <div
                    className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity duration-[400ms] ease-in-out ${
                      isLoading ? 'opacity-100' : 'opacity-0'
                    }`}
                  >
                    <Spinner
                      borderWidth="border-4"
                      borderColor="border-[#232323]/50"
                      bottomBorderColor="border-b-[#232323]"
                    />
                  </div>

                  <div
                    className={`transition-opacity duration-200 ease-in-out ${
                      isLoading ? 'opacity-0' : 'opacity-100'
                    }`}
                  >
                    {buttonText}
                  </div>
                </button>
              )
            )}
          </div>
        </div>
      </div>
    )
  },
)

Footer.displayName = 'Footer'

export default Footer
