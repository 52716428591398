import { type ReactElement, useState, useEffect, useRef } from 'react'

import { SlArrowDown } from 'react-icons/sl'

interface QuestionAnswerProps {
  question: string
  answer: string
}

interface EventFaqProps {
  questionsAnswers: QuestionAnswerProps[] | undefined
}

function EventFaq({ questionsAnswers }: EventFaqProps): ReactElement {
  const [isShort, setIsShort] = useState(true)

  const contentRef = useRef<HTMLInputElement>(null)

  const setMaxHeight = (): void => {
    if (contentRef.current != null) {
      const contentElement = contentRef.current
      const contentHeight = contentElement.scrollHeight
      contentElement.style.maxHeight = isShort ? '80px' : `${contentHeight}px`
    }
  }

  useEffect(() => {
    setMaxHeight()
  }, [isShort])

  return (
    <div
      className={`flex w-full flex-col items-start rounded-lg bg-dark-black p-4`}
    >
      <span className="mb-1 w-full text-sm font-bold text-text-terciary">
        FAQ
      </span>
      <div
        ref={contentRef}
        className="relative flex w-full flex-col gap-3 overflow-hidden text-xs text-text-secondary transition-[max-height] duration-500 ease-in-out"
      >
        {questionsAnswers?.map((qa, index) => {
          return (
            <div key={index} className="flex flex-col gap-1">
              <span className="w-full break-words text-sm leading-4 text-white">
                {qa.question}
              </span>
              <span className="w-full break-words">{qa.answer}</span>
            </div>
          )
        })}
        {contentRef?.current?.clientHeight !== undefined &&
          contentRef?.current?.clientHeight >= 80 && (
            <div
              className={
                'absolute bottom-0 z-10 h-10 w-full bg-gradient-to-b from-transparent to-dark-black transition-opacity duration-500 ease-linear ' +
                (isShort ? 'opacity-100' : ' opacity-0')
              }
            />
          )}
      </div>
      {contentRef?.current?.clientHeight !== undefined &&
        contentRef?.current?.clientHeight >= 80 && (
          <div
            className="mt-2 flex items-center"
            onClick={() => {
              setIsShort(!isShort)
            }}
          >
            <span className="text-sm font-bold text-white transition-opacity duration-300 ease-out">
              {isShort ? 'Ver mais' : 'Ver menos'}
            </span>
            <SlArrowDown
              color="white"
              size={12}
              className={
                'ml-2 transition-transform duration-500 ease-in-out ' +
                (isShort ? '' : '-rotate-180')
              }
            />
          </div>
        )}
    </div>
  )
}

export default EventFaq
